<div *ngIf="reportName==''">
    <form [formGroup]="orderForm" *ngIf="loaded" (ngSubmit)="onSubmit()" novalidate>
      <div class="row g-0 mt-2">
        <div class="col-lg-6 pt-2 px-0">
          <label class="text-white w-100 background text-center">
            BILL TO
          </label>
        </div>
        <div class="col-lg-4 pt-2 px-0">
            <label class="text-white w-100 background text-center">
              SHIP TO
            </label>
         </div>
        <div class="col-lg-6 pt-2 px-0">
          <label class="text-white w-100 background text-center">
            SALES ORDER INFORMATION
          </label>
        </div>
        <div class="col-lg-8 pt-2 px-0">
          <label class="text-white w-100 background text-center">
            SHIPPING DATES/TYPES
          </label>
        </div>
      </div>
    
      <div class="row g-0">
        <div class="col-lg-6 pt-0 px-2">
          {{ customer?.company }}
        </div>
        <div class="col-lg-4 pt-0 px-2">
            {{ shipTo?.company }}
        </div>
        <div class="col-lg-2 pt-0 px-2">
          Sales Order Date
        </div>
        <div class="col-lg-4 pt-0 px-2">
          {{ displayDate(order.salesOrderDate, '')  }}
        </div>
        <div class="col-lg-4 pt-0 px-2">
              Shipment Date
        </div>
        <div class="col-lg-4 pt-0 px-2">
            {{ displayDate(order.shipmentDate, '')  }}
        </div>
      </div>
    
      <div class="row g-0">
        <div class="col-lg-6 pt-0 px-2">
          {{ customer?.address }}
        </div>
        <div class="col-lg-4 pt-0 px-2">
            {{ shipTo?.address }}
        </div>
        <div class="col-lg-2 pt-0 px-2">
          Sales Order No.
        </div>
        <div class="col-lg-4 pt-0 px-2">
          {{ order.salesOrderNo }}
        </div>
        <div class="col-lg-4 pt-0 px-2">
          Delivered Date/Appt
        </div>
        <div class="col-lg-4 pt-0 px-2">
            {{ displayDate(order.deliveredDateAppt, 'T')  }}
        </div>
      </div>
    
      <div class="row g-0">
        <div class="col-lg-6 pt-0 px-2">
          {{ getAddress2('customer') }}
        </div>
        <div class="col-lg-4 pt-0 px-2">
            {{ getAddress2('shipto') }}
         </div>
        <div class="col-lg-2 pt-0 px-2">
          Purchase Order
        </div>
        <div class="col-lg-4 pt-0 px-2">
          {{ order.purchaseOrder }}
        </div>
        <div class="col-lg-4 pt-0 px-2">
          App Confirmed
        </div>
        <div class="col-lg-4 pt-0 px-2">
            {{ order?.appConfirmed }}
        </div>
      </div>
    
      <div class="row g-0">
        <div class="col-lg-20 pt-0 px-2">
        </div>
    
    
        <div class="col-lg-2 pt-0 px-2">
          Payment Terms
        </div>
        <div class="col-lg-4 pt-0 px-2">
          {{ term?.paymentTerms }}
        </div>
        <div class="col-lg-4 pt-0 px-2">
          Delivery Type
        </div>
        <div class="col-lg-4 pt-0 px-2">
           {{ freightType?.freightType }}
        </div>
      </div>
    
      <div class="row g-0">
        <div class="col-lg-20 pt-0 px-2">
        </div>
    
    
        <div class="col-lg-2 pt-0 px-2">
          Broker
        </div>
        <div class="col-lg-4 pt-0 px-2">
          {{ broker?.company }}
        </div>
        <div class="col-lg-4 pt-0 px-2">
            Order Status
          </div>
        <div class="col-lg-4 pt-0 px-2">
            {{ order?.orderStatus }}
          </div>
      </div>
    
    
      <div class="row g-0">
        <div class="col-lg-6 pt-0 px-0">
          <label class="text-white w-100 background">
            VENDOR PICK UP
          </label>
        </div>
        <div class="col-lg-8 pt-0 px-0">
          <label class="text-white w-100 background">
            SHIPPING LANE
          </label>
        </div>
    
        <div class="col-lg-6 pt-0 px-0">
          <label class="text-white w-100 background">
            TRANSPORT COMPANY
          </label>
        </div>
        <div class="col-lg-4 pt-0 px-0">
          <label class="text-white text-center w-100 background">
            WAREHOUSE
          </label>
        </div>
      </div>
    
      <div class="row g-0">
        <div class="col-lg-6 pt-0 px-2">
          {{ pickup?.company }}
        </div>
        <div class="col-lg-8 pt-0 px-2">
          {{ location1?.location }}
        </div>
        <div class="col-lg-6 pt-0 px-2">
            {{ transport1 }}
        </div>
        <div class="col-lg-4 pt-0 px-2">
          {{ warehouse?.company }}
        </div>
      </div>

      <div class="row g-0 mt-2">
        <div class="col-lg-6 pt-0 px-2">
          {{ pickup?.address }}
        </div>
        <div class="col-lg-8 pt-0 px-2">
          {{ location2?.location }}
        </div>
        <div class="col-lg-6 pt-0 px-2">
            {{ transport2 }}
        </div>
        <div class="col-lg-4 pt-0 px-2">
          {{ warehouse?.address }}
        </div>
      </div>
      
  
      <div class="row g-0 mt-2">
        <div class="col-lg-6 pt-0 px-2">
          {{ getAddress2('pickup') }}
        </div>
        <div class="col-lg-8 pt-0 px-2">
          {{ location3?.location }}
        </div>
        <div class="col-lg-6 pt-0 px-2">
            {{ transport3 }}
        </div>
        <div class="col-lg-4 pt-0 px-2">
          {{ getAddress2('warehouse') }}
        </div>
      </div>
  
  
      <div class="row g-0">
        <div class="col-lg-20 pt-3 px-2">
          <span class="fw-bold">Vendor: </span>
          {{ getVendor() }}
        </div>
        <div class="col-lg-24 pt-3 px-2">
          <span class="fw-bold">Notes: </span>
          {{ order.notes }}
        </div>
      </div>
    
      <div class="row g-0">
        <div class="col-lg-2 pt-0 px-0">
        </div>
        <div class="col-lg-4 pt-0 px-0">
          <label class="text-white w-100 background text-center">
            BUDGET EXCHANGE
          </label>
        </div>
    
        <div class="col-lg-4 pt-0 px-0">
          <label class="text-white w-100 background text-center">
            ACTUAL EXCHANGE
          </label>
        </div>
        <div class="col-lg-4 pt-0 px-0">
          <label class="background text-white text-center w-100">
            CONFIRM
          </label>
        </div>
        <div class="col-lg-3 pt-0 px-0">
          <label class="text-white w-100 background text-center">
            EXCHANGE DIFFERENCE
          </label>
        </div>
        <div class="col-lg-4 pt-0 px-0" *ngIf="!stockOrder">
          <label class="text-white w-100 background text-center">
            STOCK PAYMENT PERIOD
          </label>
        </div>
        <div class="col-lg-3 pt-0 px-0">
          <label class="text-white w-100 background text-center">
            REVENUE
          </label>
        </div>
      </div>

      <div class="row g-0">
        <div class="col-lg-2 pt-2 px-0">
        </div>
        <div class="col-lg-4 pt-2 px-2">
            <input type="number" formControlName="exchangeBudget" class="form-control">
        </div>
        <div class="col-lg-4 pt-2 px-2">
            <input type="number" step=".000001" formControlName="exchangeActual" class="form-control">
        </div>
        <div class="col-lg-4 pt-2 px-2">
            <select id="app-confirmed" formControlName="exchangeConfirmed" class="form-control">
                <option value="YES">YES</option>
                <option value="NO">NO</option>
            </select>
        </div>
        <div class="col-lg-3 pt-2 px-2 text-center">
          {{ exchangeProduct | number : '1.4'}}
          <!-- <input type="number" formControlName="exchangeProduct" class="form-control"> -->
        </div>
        <div class="col-lg-2 pt-2 px-2" *ngIf="!stockOrder">
          <select class="form-control" formControlName="year">
            <option [value]=""></option>
            <option *ngFor="let y of years; index as i" [value]="y">{{ y }}</option>
          </select>
        </div>
        <div class="col-lg-2 pt-2 px-2" *ngIf="!stockOrder">
          <select class="form-control" formControlName="period">
            <option [value]=""></option>
            <option *ngFor="let p of periods" [value]="p">{{ p }}</option>
          </select>
        </div>
        <div class="col-lg-3 pt-2 px-2">
          <div class="input-group">
          <span class="input-group-text fw-bold">$</span>
          <input type="text" formControlName="revenue" class="form-control text-end fw-bold" (change)="calculate()">
        </div>
        </div>

        <!-- <div class="input-group col-lg-3 pt-2 px-2">
          <div class="input-group-prepend">
            <span class="input-group-text fw-bold">$</span>
          </div>
          <input type="text" formControlName="revenue" class="form-control text-end fw-bold" (change)="calculate()">
        </div> -->
        
      </div>
      
      <div class="row g-0 text-white mt-3 background">
        <label class="text-white pt-1 px-2">
            CND FUNDS
          </label>
      </div>


        <table class="table">
            <thead class="text-white mt-3">
                <th scope="col">ORDER CODE</th>
                <th scope="col">BRAND/CATEGORY</th>
                <th scope="col">ITEM DESCRIPTION</th>
                <th scope="col">SIZE</th>
                <th scope="col" class="text-white text-end">CASES</th>
                <th scope="col" class="text-white text-end">SHIPPED</th>
                <th scope="col" class="text-white text-end">INVOICED</th>
                <th scope="col" class="text-white text-end">CASE COST</th>
                <th scope="col" class="text-white text-end">EXT CASE COST</th>
                <th scope="col" class="text-white text-end">BUDGET EX</th>
                <th scope="col" class="text-white text-end">ACTUAL EX</th>
            </thead>
            <tbody>
                <tr *ngFor="let item of items; let i = index;">
                    <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.orderCode }}</td>
                    <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.brandCategory }}</td>
                    <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.itemDescription }}</td>
                    <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.size }}</td>
                    <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ item.cases }}</td>
                    <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ item.shipped }}</td>
                    <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ item.invoiced }}</td>
                    <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ item.caseCost | currency  }}</td>
                    <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ item.extCaseCost | currency  }}</td>
                    <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ item.exchangeBudget | number : '1.2-4' }}</td>
                    <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ item.exchangeActual | number : '1.7' }}</td>
                </tr>
                <tr>
                  <td colspan="3" class="border border-dark border-start-0 border-end-0"></td>
                  <td class="border border-dark border-start-0 border-end-0">TOTALS</td>
                  <td class="border border-dark border-start-0 border-end-0 text-end">
                    {{ totalCases | number : '1.0-0' }}
                  </td>
                  <td class="border border-dark border-start-0 border-end-0 text-end">
                    {{ totalShipped | number : '1.0-0' }}
                  </td>
                  <td class="border border-dark border-start-0 border-end-0 text-end">
                    {{ totalInvoiced | number : '1.0-0' }}
                  </td>
                  <td class="border border-dark border-start-0 border-end-0"></td>
                  <td class="border border-dark border-start-0 border-end-0 text-end">
                    {{ totalExtCost | currency }}
                  </td>
                  <td colspan="2" class="border border-dark border-start-0 border-end-0"></td>
                </tr>  
            </tbody>   
        </table>


        <table class="table">
          <thead class="text-white mt-3">
              <th scope="col">ORDER CODE</th>
              <th scope="col">BRAND/CATEGORY</th>
              <th scope="col">ITEM DESCRIPTION</th>
              <th scope="col">SIZE</th>
              <th scope="col" class="text-white text-end">MARKETING %</th>
              <th scope="col" class="text-white text-end">MARKETING</th>
              <th scope="col" class="text-white text-end">BILLING %</th>
              <th scope="col" class="text-white text-end">BILLING</th>
              <th scope="col" class="text-white text-end">TERMS %</th>
              <th scope="col" class="text-white text-end">TERMS</th>
          </thead>
          <tbody>
              <tr *ngFor="let item of items; let i = index;">
                  <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.orderCode }}</td>
                  <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.brandCategory }}</td>
                  <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.itemDescription }}</td>
                  <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.size }}</td>
                  <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ item.marketing1 | number : '1.2-2' }}%</td>
                  <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ item.marketing | currency }}</td>
                  <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ item.billing1 | number : '1.2-2' }}%</td>
                  <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ item.billing | currency  }}</td>
                  <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ item.terms1 | number : '1.2-2' }}%</td>
                  <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ item.terms | currency  }}</td>
              </tr>
              <tr>
                <td colspan="3" class="border border-dark border-start-0 border-end-0"></td>
                <td class="border border-dark border-start-0 border-end-0">TOTALS</td>
                <td class="border border-dark border-start-0 border-end-0"></td>
                <td class="border border-dark border-start-0 border-end-0 text-end">
                  {{ totalMarketing | currency }}
                </td>
                <td class="border border-dark border-start-0 border-end-0"></td>
                <td class="border border-dark border-start-0 border-end-0 text-end">
                  {{ totalBilling | currency }}
                </td>
                <td class="border border-dark border-start-0 border-end-0"></td>
                <td class="border border-dark border-start-0 border-end-0 text-end">
                  {{ totalTerms | currency }}
                </td>
              </tr>  
          </tbody>   
      </table>

      <table class="table">
        <thead class="text-white mt-3">
            <th scope="col">ORDER CODE</th>
            <th scope="col">BRAND/CATEGORY</th>
            <th scope="col">ITEM DESCRIPTION</th>
            <th scope="col">SIZE</th>
            <th scope="col" class="text-white text-end">COMMISSION %</th>
            <th scope="col" class="text-white text-end">COMMISSION</th>
            <th scope="col" class="text-white text-end">KALCOR %</th>
            <th scope="col" class="text-white text-end">KALCOR</th>
        </thead>
        <tbody>
            <tr *ngFor="let item of items; let i = index;">
                <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.orderCode }}</td>
                <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.brandCategory }}</td>
                <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.itemDescription }}</td>
                <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.size }}</td>
                <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ item.commission1 + item.commission2 | number : '1.2-2' }}%</td>
                <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ item.commission11 + item.commission21 | currency }}</td>
                <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ item.kalcor1 | number : '1.2-2' }}%</td>
                <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ item.kalcor | currency  }}</td>
            </tr>
            <tr>
              <td colspan="3" class="border border-dark border-start-0 border-end-0"></td>
              <td class="border border-dark border-start-0 border-end-0">TOTALS</td>
              <td class="border border-dark border-start-0 border-end-0"></td>
              <td class="border border-dark border-start-0 border-end-0 text-end">
                {{ totalCommission | currency }}
              </td>
              <td class="border border-dark border-start-0 border-end-0"></td>
              <td class="border border-dark border-start-0 border-end-0 text-end">
                {{ totalKalcor | currency }}
              </td>
            </tr>  
        </tbody>   
    </table>

    <table class="table">
      <thead class="text-white mt-3 bg-primary">
          <th scope="col">ORDER CODE</th>
          <th scope="col">BRAND/CATEGORY</th>
          <th scope="col">ITEM DESCRIPTION</th>
          <th scope="col">SIZE</th>
          <th scope="col" class="text-white text-end">CND FRT Cost/Case</th>
          <th scope="col" class="text-white text-end">Storage Cost/Case</th>
      </thead>
      <tbody>
          <tr *ngFor="let item of items; let i = index;">
              <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.orderCode }}</td>
              <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.brandCategory }}</td>
              <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.itemDescription }}</td>
              <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.size }}</td>
              <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ cndFrtCostCase[i] | currency }}</td>
              <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ storageCostCase[i] | currency }}</td>
          </tr>
          <tr>
            <td colspan="3" class="border border-dark border-start-0 border-end-0"></td>
            <td class="border border-dark border-start-0 border-end-0">TOTALS</td>
            <td class="border border-dark border-start-0 border-end-0 text-end">
              {{ totalCndFrtCostCase | currency }}
            </td>
            <td class="border border-dark border-start-0 border-end-0 text-end">
              {{ totalStorageCostCase | currency }}
            </td>
          </tr>  
      </tbody>   
  </table>
  <div class="row g-0 text-white mt-3 background">
    <label class="text-white px-2">
        USD FUNDS
      </label>
  </div>

  <table class="table">
    <thead class="text-white mt-3 bg-primary">
        <th scope="col">ORDER CODE</th>
        <th scope="col">BRAND/CATEGORY</th>
        <th scope="col">ITEM DESCRIPTION</th>
        <th scope="col">SIZE</th>
        <th scope="col" class="text-white text-end">CASES</th>
        <th scope="col" class="text-white text-end">SHIPPED</th>
        <th scope="col" class="text-white text-end">INVOICED</th>
        <th scope="col" class="text-white text-end">VENDOR</th>
        <th scope="col" class="text-white text-end">EXT CASE COST</th>
    </thead>
    <tbody>
        <tr *ngFor="let item of itemsUSD; let i = index;">
            <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.orderCode }}</td>
            <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.brandCategory }}</td>
            <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.itemDescription }}</td>
            <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.size }}</td>
            <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ item.cases }}</td>
            <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ item.shipped }}</td>
            <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ item.invoiced }}</td>
            <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">
              {{ item.purchaseCaseCost - item.royalty - item.clearingCs - item.freightCs - item.duty | currency  }}
            </td>
            <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ item.extCaseCost | currency  }}</td>
        </tr>
        <tr>
          <td colspan="3" class="border border-dark border-start-0 border-end-0"></td>
          <td class="border border-dark border-start-0 border-end-0">TOTALS</td>
          <td class="border border-dark border-start-0 border-end-0 text-end">
            {{ totalCases | number : '1.0-0' }}
          </td>
          <td class="border border-dark border-start-0 border-end-0 text-end">
            {{ totalShipped | number : '1.0-0' }}
          </td>
          <td class="border border-dark border-start-0 border-end-0 text-end">
            {{ totalInvoiced | number : '1.0-0' }}
          </td>
          <td class="border border-dark border-start-0 border-end-0"></td>
          <td class="border border-dark border-start-0 border-end-0 text-end">
            {{ totalVendor  | currency }}
          </td>
        </tr>  
    </tbody>   
</table>

<table class="table">
  <thead class="text-white mt-3 bg-primary">
      <th scope="col">ORDER CODE</th>
      <th scope="col">BRAND/CATEGORY</th>
      <th scope="col">ITEM DESCRIPTION</th>
      <th scope="col">SIZE</th>
      <th scope="col" class="text-white text-end">ROYALTY</th>
      <th scope="col" class="text-white text-end">CLEARING</th>
      <th scope="col" class="text-white text-end">DUTY</th>
      <th scope="col" class="text-white text-end">FREIGHT USD</th>
  </thead>
  <tbody>
      <tr *ngFor="let item of itemsUSD; let i = index;">
          <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.orderCode }}</td>
          <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.brandCategory }}</td>
          <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.itemDescription }}</td>
          <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ item.size }}</td>
          <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ usdRoyalty[i] | currency  }}</td>
          <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ usdClearing[i] | currency  }}</td>
          <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ usdDuty[i] | currency  }}</td>
          <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">{{ usdFrtCost[i] | currency  }}</td>
      </tr>
      <tr>
        <td colspan="3" class="border border-dark border-start-0 border-end-0"></td>
        <td class="border border-dark border-start-0 border-end-0">TOTALS</td>
        <td class="border border-dark border-start-0 border-end-0 text-end">
          {{ totalRoyalty | currency }}
        </td>
        <td class="border border-dark border-start-0 border-end-0 text-end">
          {{ totalClearing | currency }}
        </td>
        <td class="border border-dark border-start-0 border-end-0 text-end">
          {{ totalDuty | currency }}
        </td>
        <td class="border border-dark border-start-0 border-end-0 text-end">
          {{ totalFreightCostExt | currency }}
        </td>
      </tr>  
  </tbody>   
</table>


<table class="table">
  <thead class="text-white mt-0 bg-primary">
      <th scope="col">FUNDS</th>
      <th scope="col">ITEM</th>
      <th scope="col" class="text-white text-end">BUDGET AMOUNT</th>
      <th scope="col" class="text-white text-end">DIFFERENCE</th>
      <th scope="col" class="text-white text-end">ACTUAL AMOUNT</th>
      <th scope="col" class="text-white text-center">COMPANY</th>
      <th scope="col" class="text-white">INVOICE NO.</th>
      <th scope="col" class="text-white text-end">CONFIRM</th>
  </thead>
  <tbody>
    <ng-container  *ngFor="let item of budgetActual; let i = index;">
    
    <tr>
      <!-- Funds -->
      <td *ngIf="!item.freightType" class="border border-top-0 border-start-0 border-end-0">
        {{ item.funds }}
      </td>
      <td *ngIf="item.freightType" class="border border-top-0 border-bottom-0 border-start-0 border-end-0">
        {{ item.funds }}
      </td>
      
      <!-- item -->
      <td *ngIf="!item.freightType" class="border border-top-0 border-start-0 border-end-0 w-100">
        {{ item.item }}
      </td>
      <td *ngIf="item.freightType"  class="border border-top-0 border-bottom-0 border-start-0 border-end-0 w-100">
        {{ item.item }}
      </td>

      <!-- budget amount -->
      <td *ngIf="item.freightType" class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">
      </td>
      <td *ngIf="!item.freightType && item.item=='Exchange'" class="border border-top-0 border-start-0 border-end-0 text-end">
        {{ item.budgetAmount | number :'1.2-4' }}
      </td>
      <td *ngIf="!item.freightType && item.item!='Exchange'" class="border border-top-0 border-start-0 border-end-0 text-end">
        {{ item.budgetAmount | currency }}
      </td>
  
      <!-- difference amount -->
      <td *ngIf="item.item=='Exchange' || item.item == 'Net CND'" class="border border-top-0 border-start-0 border-end-0 text-end">
      </td>
      <td *ngIf="item.freightType" class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end">
      </td>
      <td *ngIf="!item.freightType && item.item!='Exchange' && item.item != 'Net CND'" class="border border-top-0 border-start-0 border-end-0 text-end">
        {{ item.difference | currency }}
      </td>

      <!-- actual amount -->
      <td *ngIf="item.item=='Net CND'" class="border border-top-0 text-end fw-bold">
      </td>
      <td *ngIf="item.edit=='N' && !item.freightType && item.item!='Exchange' && item.item!='Net CND' || item.item=='Vendor'" class="border border-top-0 text-end fw-bold">
        {{ item.actualAmount | currency  }}
      </td>
      <td *ngIf="item.item=='Exchange'" class="border border-top-0 text-end fw-bold">
        {{ item.actualAmount  | number :'1.7'  }}
      </td>
      <td *ngIf="item.edit=='Y' && item.item != 'Vendor'" class="border border-top-0 text-end fw-bold">
        <div class="input-group ">
          <div class="input-group-prepend">
            <span class="input-group-text fw-bold">$</span>
          </div>
          <!-- 4/13/23 - removed autocalculate for default button function to work smoother -->
          <input type="text" [(ngModel)]="item.actualAmount" [ngModelOptions]="{standalone: true}" class="form-control fw-bold text-end">
        </div>
        <div>
          <td *ngIf="item.item=='Freight' && item.funds=='CND'" class="border border-top-0 border-bottom-0 border-start-0 border-end-0">
            <button type="button" class="btn btn-secondary pointer" *ngIf="item.item=='Freight' && item.funds=='CND'" (click)="getFreightDefault()">Get Default</button>
          </td>
        </div>
      </td>

      <!-- company -->
      <!-- <td colspan="4" *ngIf="item.edit=='N' && !item.freightType" class="border border-top-0 border-start-0 border-end-0 text-end">
      </td> -->
      <td *ngIf="item.edit=='N' && !item.freightType" class="border border-top-0 border-start-0 border-end-0">
        {{ item.company }}
      </td>
      <td *ngIf="item.edit=='Y'" class="border border-top-0 border-start-0 border-end-0 w-25">
        <select *ngIf="item.item=='Freight'" type="text" [(ngModel)]="item.company" [ngModelOptions]="{standalone: true}">
          <option [value]=""></option>
          <option *ngFor="let t1 of transport" [value]="t1.company">{{ t1.company }}</option>
        </select>
        <select *ngIf="item.item=='Vendor'" type="text" [(ngModel)]="item.company" [ngModelOptions]="{standalone: true}">
          <option [value]=""></option>
          <option *ngFor="let v of vendors" [value]="v.company">{{ v.company }}</option>
        </select>
        <input *ngIf="item.item!='Freight' && item.item != 'Vendor'" type="text" [(ngModel)]="item.company" [ngModelOptions]="{standalone: true}">
      </td>

      <!-- reference -->
      <td *ngIf="item.edit=='N' && !item.freightType" class="border border-top-0 border-start-0 border-end-0">
        {{ item.reference }}
      </td>
      <td *ngIf="item.edit=='Y'" class="border border-top-0 border-start-0 border-end-0 w-25">
        <input type="text" [(ngModel)]="item.reference" [ngModelOptions]="{standalone: true}" >
      </td>

      <!-- confirm -->
      <td *ngIf="item.edit=='N' && !item.freightType" class="border border-top-0 border-start-0 border-end-0">
        {{ item.confirm }}
      </td>
      <td *ngIf="item.edit=='Y'" class="border border-top-0 border-start-0 border-end-0">
        <select [(ngModel)]="item.confirm" [ngModelOptions]="{standalone: true}">
          <option [value]=""></option>
          <option value="YES">YES</option>
          <option value="NO">NO</option>
        </select>
      </td>

      <!-- freight -->
      <td *ngIf="item.freightType" class="border text-end fw-bold bg-orange" style="white-space: nowrap">
        Type of Freight
      </td>
      <td *ngIf="item.freightType" class="border border-start-0 border-end-0 text-end bg-orange">
        Cost
      </td>
      <td *ngIf="item.freightType" class="border border-start-0 border-end-0 text-end bg-orange">
        FSC %
      </td>
      <td *ngIf="item.freightType" class="border border-start-0 border-end-0 text-end bg-orange">
        Cost
      </td>
    </tr>
    <tr *ngIf="item.freightType">
      <td colspan="4" class="border border-top-0 border-start-0 border-end-0 text-end"></td>
      <td class="border border-top-0 text-end fw-bold bg-orange" style="white-space: nowrap">
        {{ item.freightType }} Freight
      </td>
      <td *ngIf="item.freightType" class="border border-top-0 border-bottom-0 border-start-0 border-end-0 text-end bg-orange">
        {{ item.freightCost | currency}}
      </td>
      <td *ngIf="item.freightType" class="border border-top-0 border-start-0 border-end-0 text-end bg-orange">
        {{ item.freightCostSales | number : '1.2-2' }}
      </td>
      <td *ngIf="item.freightType" class="border border-top-0 border-start-0 border-end-0 text-end bg-orange">
        {{ item.freightTotalCost | currency }}
      </td>
    </tr>
  </ng-container>
  </tbody>   
</table>
  
<div class="row">
  <div class="col-2 pe-0">
    <button type="submit" class="btn btn-primary mt-3 w-100">Close Order</button>
  </div>
  <div class="col-2 pe-0 ms-2">
      <button type="button" class="btn btn-primary mt-3 w-100" (click)="save()">Save</button>
  </div>
  <div *ngIf="order.orderStatus==='Closed'" class="col-2 pe-0 ms-2">
    <button type="button" class="btn btn-primary mt-3 w-100" (click)="printIt()">Print</button>
  </div>
</div>
<ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}
</ngb-alert>
  </form>
</div> 
<div id="my-profit-loss-edit" *ngIf="reportName=='Sales Order Profit & Loss'">
  <app-profit-loss
  [title] = "title"
  [order] = "orderPDF"
  [items] = "items"
  [itemsUSD] = "itemsUSD"
  [totalCases] = "totalCases"
  [totalShipped] = "totalShipped"
  [totalInvoiced] = "totalInvoiced"
  [totalExtCost] = "totalExtCost"
  [totalMarketing] = "totalMarketing"
  [totalBilling] = "totalBilling"
  [totalTerms] = "totalTerms"
  [totalCommission] = "totalCommission"
  [totalKalcor] = "totalKalcor"
  [cndFrtCostCase] = "cndFrtCostCase"
  [storageCostCase] = "storageCostCase"
  [totalCndFrtCostCase] = "totalCndFrtCostCase"
  [totalStorageCostCase] = "totalStorageCostCase"
  [totalPurchaseCaseCostExt] = "totalPurchaseCaseCostExt"
  [totalFreightCostExt] = "totalFreightCostExt"
  [totalClearing] = "totalClearing"
  [totalDuty] = "totalDuty"
  [totalRoyalty] = "totalRoyalty"
  [totalVendor] = "totalVendor"
  [budgetActual] = "budgetActual"
  [usdVendorCost] = "usdVendorCost"
  [usdFrtCost] = "usdFrtCost"
  [usdClearing] = "usdClearing"
  [usdDuty] = "usdDuty"
  [usdRoyalty] = "usdRoyalty"
  [indexMarketing] = "indexMarketing"
  [indexBilling] = "indexBilling"
  [indexTerms] = "indexTerms"
  [indexCommission] = "indexCommission"
  [indexKalcor] = "indexKalcor"
  [indexFreightCnd] = "indexFreightCnd"
  [indexStorage] = "indexStorage"
  [indexVendor] = "indexVendor"
  [indexRoyalty] = "indexRoyalty"
  [indexClearing] = "indexClearing"
  [indexDuty] = "indexDuty"
  [indexFreightUSD] = "indexFreightUSD"
  [indexFobDiff] = "indexFobDiff">
  </app-profit-loss>
</div>
