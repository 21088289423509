<h5>{{ title }} </h5>
<form [formGroup]="productForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="form-group">

    <div class="col-lg-24">
      <label>
        Active Listing:
        <select formControlName="activeListings" class="form-control">
          <option *ngFor="let l of activeList">{{ l }}</option> 
        </select>
      </label>
    </div>

  <div class="col-lg-24">
    <label>
      Cost Type:
      <select formControlName="costType" class="form-control">
        <option value="Distributor C-Store – Chain - FTL">Distributor C-Store – Chain - FTL</option>
        <option value="Distributor C-Store – Chain - LTL">Distributor C-Store – Chain - LTL</option>
        <option value="Distributor C-Store – Independent - LTL">Distributor C-Store – Independent - LTL</option>
        <option value="Distributor C-Store – Independent -FTL">Distributor C-Store – Independent -FTL</option>
        <option value="Distributor Retail Chain - EDLP - FTL">Distributor Retail Chain - EDLP - FTL</option>
        <option value="Distributor Retail Chain - EDLP - LTL">Distributor Retail Chain - EDLP - LTL</option>
        <option value="Distributor Retail Chain - High Low - FTL">Distributor Retail Chain - High Low - FTL</option>
        <option value="Distributor Retail Chain - High Low - LTL">Distributor Retail Chain - High Low - LTL</option>
        <option value="Ship Direct - Bill Direct - EDLP - FTL">Ship Direct - Bill Direct - EDLP - FTL</option>
        <option value="Ship Direct - Bill Direct - EDLP - LTL">Ship Direct - Bill Direct - EDLP - LTL</option>
        <option value="Ship Direct - Bill Direct - High Low - FTL">Ship Direct - Bill Direct - High Low - FTL</option>
        <option value="Ship Direct - Bill Direct - High Low - LTL">Ship Direct - Bill Direct - High Low - LTL</option>
        <option value="Ship Direct - Bill Through Distributor – EDLP - FTL">Ship Direct - Bill Through Distributor – EDLP - FTL</option>
        <option value="Ship Direct - Bill Through Distributor – EDLP - LTL">Ship Direct - Bill Through Distributor – EDLP - LTL</option>
        <option value="Ship Direct - Bill Through Distributor - High Low - FTL">Ship Direct - Bill Through Distributor - High Low - FTL</option>
        <option value="Ship Direct - Bill Through Distributor - High Low - LTL">Ship Direct - Bill Through Distributor - High Low - LTL</option>
        <option value="Warehouse Stock Shipment – FTL">Warehouse Stock Shipment – FTL</option>
        <option value="Warehouse Stock Shipment - LTL">Warehouse Stock Shipment - LTL</option>
      </select>
  </label>
  </div>

    <div class="col">
      <label>
        Product Order Code (*):
        <select formControlName="productOrderCodeId" class="form-control">
          <option *ngFor="let p of gtn" [value]="p.id">{{ p.vendorOrderCode }}</option>
        </select>
      </label>

      <label class="ms-3">
        UPC:
        <input type="text" formControlName="upcLocal" class="form-control" >
      </label>

      <label class="ms-3">
        GTN-14:
        <input type="text" formControlName="gtnLocal" class="form-control" >
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Product Type:
        <select formControlName="productType" class="form-control">
          <option value="Dry">Dry</option>
          <option value="Frozen">Frozen</option>
          <option value="Fresh"> Fresh</option>
        </select>
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Order Type:
        <select formControlName="orderType" class="form-control">
          <option value="Retail">Retail</option>
          <option value="Stock">Stock</option>
          <option value="Food Service"> Food Service</option>
        </select>
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Brand:
        <input type="text" formControlName="brandLocal" class="form-control" >
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Category:
        <select formControlName="categoryId" class="form-control">
          <option *ngFor="let c of categories" [value]="c.id">{{ c.category }}</option>
        </select>
      </label>
    </div>

    <div class="col-lg-24">
      <label>
        Item Desciption:
        <input type="text" formControlName="itemDescription" class="form-control" size="60">
      </label>
    </div>

    <div class="col">
      <label>
        Pack:
        <input type="text" formControlName="pack" class="form-control">
      </label>

      <label class="ms-3">
        Oz:
        <input type="text" formControlName="oz" class="form-control"  >
      </label>

      <label class="ms-3">
        Size:
        <input type="text" formControlName="size" class="form-control">
      </label>

      <label class="ms-3">
        Gm:
        <input type="text" formControlName="gm" class="form-control">
      </label>
    </div>

    <button type="submit" class="btn btn-primary mt-3 w-25">Save</button>
    <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="onDelete()">Delete</button>
    <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="duplicateProduct()">Duplicate Item</button>
    <br>
    <button type="button" class="btn btn-secondary mt-3 w-25" (click)="editCaseSetup()">Edit Case Setup</button>
    <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="editPalletSetup()">Pallet Setup</button>
    <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="editFreightCostSetup()">Freight Cost Setup</button>
    <br>
    <button type="button" class="btn btn-secondary mt-3 w-25" (click)="editCostingBucketSetup()">Costing Bucket Setup</button>
    <!-- <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="editListingSetup()">Listing Setup</button> -->
  </div>
  <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
</form>
