<h5>Exhibit-A Report </h5>
<div class="col-lg-44">
<label class="ms-3">
  Vendor:
  <select [(ngModel)]="vendorSelected" (change)="resetFilters()">
    <option [value]="0"></option>
    <option *ngFor="let o of vendorOptions" [value]="o.vendorId">{{ o.vendor }}</option>
  </select>
</label>
<label class="ms-3">
  Broker 1:
  <select [(ngModel)]="broker1Selected" (change)="resetFilters()">
    <option [value]=""></option>
    <option *ngFor="let o of broker1Options" [value]="o.id">{{ o.company }}</option>
  </select>
</label>
<label class="ms-3">
  Broker 2:
  <select [(ngModel)]="broker2Selected" (change)="resetFilters()">
    <option [value]=""></option>
    <option *ngFor="let o of broker2Options" [value]="o.id">{{ o.company }}</option>
  </select>
</label>
<label class="ms-3">
  Customer:
  <select [(ngModel)]="customerSelected" (change)="resetFilters()">
    <option [value]="0"></option>
    <option *ngFor="let o of customerOptions" [value]="o.customerId">{{ o.customer }}</option>
  </select>
</label>
<label class="ms-3">
  Ship To:
  <select [(ngModel)]="shipToSelected" (change)="resetFilters()">
    <option [value]="0"></option>
    <option *ngFor="let o of shipToOptions" [value]="o.id">{{ o.company }}</option>
  </select>
</label>
   <button type="button" class="btn btn-primary mt-0 ms-3" (click)="getDefaultDataScreen()">Reset</button>
   <!-- <button type="button" class="btn btn-primary mt-0 ms-5" (click)="exportToExcel()">Export to Excel</button> -->
   <button type="button" class="btn btn-primary mt-0 ms-5" (click)="filterProducts()">Export to Excel</button>

</div>
<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">Vendor</th>
    <th scope="col">Brand</th>
    <th scope="col">Customer</th>
    <th scope="col">Ship To</th>
    <th scope="col">Comm 1%</th>
    <th scope="col">Comm 2%</th>
    <th scope="col">Sales Manager</th>
    <th scope="col">Broker 1</th>
    <th scope="col">Broker 2</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let p of products; index as i">
    <td>{{ p.vendor }}</td>
    <td>{{ p.brand }}</td>
    <td>{{ p.customer }}</td>
    <td>{{ getShipTo(p.shipToIds) }}</td>
    <td>{{ getCommission1(i) }}</td>
    <td>{{ getCommission2(i) }}</td>
    <td>{{ p.salesManager }}</td>
    <td>{{ getBroker1(i) }}</td>
    <td>{{ getBroker2(i) }}</td>
  </tr>
  </tbody>
</table>
<div *ngIf="products!==undefined">
  <ngb-pagination
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="collectionSize"
  [maxSize]="5"
  (pageChange)="loadPage($event)">
 </ngb-pagination>
</div>

<div class="btn-group" role="group" aria-label="Basic radio toggle button group">
      
  <input class="btn-check" type="radio" name="radio1" id="choice1" [(ngModel)]="pageSize"  (change)="loadPage($event)" autocomplete="off" checked [value]="25">
  <label class="btn btn-outline-primary" for="choice1">
    25
  </label>

  <input class="btn-check" type="radio" name="radio1" id="choice2" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="50" autocomplete="off">
  <label class="btn btn-outline-primary" for="choice2">
    50
  </label>

  <input class="btn-check" type="radio" name="radio1" id="choice3" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="100" autocomplete="off">
  <label class="btn btn-outline-primary" for="choice3">
    100
  </label>

</div>
  