import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { Bucket } from 'src/app/models/bucket.model';
import { Vendor } from 'src/app/models/vendor-model';
import { VendorService } from 'src/app/services/vendor.service';
import { WeekKalcor } from 'src/app/models/week-kalcor.model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { WeekKalcorService } from 'src/app/services/week-kalcor.service';
//import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Big } from 'big.js';
import { IndexInfo } from 'typescript';
import * as internal from 'events';
import { BillingAgent } from 'src/app/models/billing-agent.model';
import { BillingAgentService } from 'src/app/services/billing-agent.service';

@Component({
  selector: 'app-period-report',
  templateUrl: './period-report.component.html',
  styleUrls: ['./period-report.component.scss']
})
export class PeriodReportComponent implements OnInit {
  title = 'Period/Week Report';
  vendorSelected: [];
  vendorSelectedIndex: number;
  vendorIds: any;
  vendors: Vendor[];
  orderTypeOptions = ['Stock', 'Retail', 'Food Service', 'Both Retail/Food Service'];
  orderStatusSelected = '';
  orderTypeSelected = '';
  orderNumberSelected = 0;
  billingAgentSelected = 0;
  billingAgents: BillingAgent[];
  year: number;
  periods = [];
  periodStartDate: any;
  periodEndDate: any;
  periodOptions: any;
  periodSelected: any;
  month: any;
  buckets: Bucket[];
  salesData = [];
  originalSales = [];
  originalExchange = [];
  originalCndFreight = [];
  originalClearing = [];
  originalUsdFreight = [];
  kalcorData = [];
  marketingData = [];
  commissionData = [];
  exchangeData = [];
  cndFreightData = [];
  clearingData = [];
  usdFreightData = [];
  totalBilling = [];
  totalPeriod = [];
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  alertType = "success";
  salesAmountTotal = 0;
  salesOrderTotal = 0;
  salesInvoicedTotal = 0;
  salesClosedTotal = 0;
  salesTrueTotal = 0; 
  kalcorAmountTotal = 0;
  kalcorOrderTotal = 0;
  kalcorInvoicedTotal = 0;
  kalcorClosedTotal = 0; 
  commissionAmountTotal = 0;
  commissionOrderTotal = 0;
  commissionInvoicedTotal = 0;
  commissionClosedTotal = 0; 
  marketingAmountTotal = 0;
  marketingOrderTotal = 0;
  marketingInvoicedTotal = 0;
  marketingClosedTotal = 0;
  exchangeAmountTotal = 0;
  exchangeOrderTotal = 0;
  exchangeInvoicedTotal = 0;
  exchangeClosedTotal = 0;
  cndFreightAmountTotal = 0;
  cndFreightOrderTotal = 0;
  cndFreightInvoicedTotal = 0;
  cndFreightClosedTotal = 0;
  clearingAmountTotal = 0;
  clearingOrderTotal = 0;
  clearingInvoicedTotal = 0;
  clearingClosedTotal = 0;
  usdFreightAmountTotal = 0;
  usdFreightOrderTotal = 0;
  usdFreightInvoicedTotal = 0;
  usdFreightClosedTotal = 0;
  salesNumber: string;
  counter: number;
  newOne = true;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService,
    private vendorService: VendorService,
    private weekKalcorService: WeekKalcorService,
    private billingAgentService: BillingAgentService
  ) { }

  ngOnInit(): void {
    this.alertType = "success";
    this.vendorSelected = [];
    this.periods = [];
    this.periodOptions = [];
    this.newOne = true;
    this.vendorIds = 0;
    this.periodSelected = 0;
    this.billingAgentSelected = 0;
    this.vendorService.getCompany().subscribe(ven => {
      this.vendors = ven;
      this.billingAgentService.getAll().subscribe(b => {
        this.billingAgents = b;
      })    
    })
    const d = new Date();
    let y = d.getFullYear();
    this.year = y;
    this.getDate();
    
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  
  getDate() {
    this.periodOptions = [];
    this.weekKalcorService.getYear(this.year).subscribe(data => {
      let p = 1;
      let startDate = data[0].weekStartDate; 
      let endDate = data[0].weekEndDate;
      
      data.forEach(e => {
        if (e.period == p) {
          endDate = e.weekEndDate;
        } else {
          const periodOpt = {
            period: p,
            weekStart: startDate,
            weekEnd: endDate
          }; 
          this.periodOptions.push(periodOpt);
          startDate = e.weekStartDate;
          p = e.period;         
        }
      })
      const totalOptions = this.periodOptions.length;
      const lastIndex = totalOptions -1;
      if (this.periodOptions[lastIndex].period != p) {
        if (p != 0) {
          const periodOpt = {
            period: p,
            weekStart: startDate,
            weekEnd: endDate
          }; 
          this.periodOptions.push(periodOpt);
        }       
      }
      const yearStart = this.periodOptions[0].weekStart;
      const yearEnd = this.periodOptions[this.periodOptions.length -1].weekEnd;
        const periodOpt = {
          period: 'All',
          weekStart: yearStart,
          weekEnd: yearEnd
        };
        this.periodOptions.push(periodOpt);
    })  
  }
  yearChange() {
    if (this.year * 1 > 999) {
      this.getDate();
    } 
      return;
  }
  periodChange(e) {
    if (e == 'All') {
      this.periodStartDate = this.periodOptions[this.periodOptions.length -1].weekStart;
      this.periodEndDate = this.periodOptions[this.periodOptions.length -1].weekEnd;
    } else {
    e--;
    if (e < 0) {
      return;
    }
    this.periodStartDate = this.periodOptions[e].weekStart;
    this.periodEndDate = this.periodOptions[e].weekEnd;
    }
    // e--;
    // if (e < 0) {
    //   return;
    // }
    // this.periodStartDate = this.periodOptions[e].weekStart;
    // this.periodEndDate = this.periodOptions[e].weekEnd;
  }
  validate() {
    if (this.year < 2018) {
      this.success.next('Please enter valid year');
      this.alertType = "danger";
      return;
    }
    if (this.vendorIds == 0) {
      this.success.next('Please select a vendor');
      this.alertType = "danger";
      return;
    }
    if (this.periodSelected == 0) {
      this.success.next('Please select a period');
      this.alertType = "danger";
      return;
    }
    if (this.orderTypeSelected == "") {
      this.success.next('Please select an Order Type');
      this.alertType = "danger";
      return;
    }
    // if (this.billingAgentSelected == 0) {
    //   this.success.next('Please select a Billing Agent');
    //   this.alertType = "danger";
    //   return;
    // }
    this.initializeRun();
  }
  initializeRun() {
    this.vendorSelectedIndex = -1;
    this.counter = 0;
    this.newOne = false;
    this.alertType = "info";
    this.run();
  }
  run() {
    this.vendorSelectedIndex++;  
    if (this.vendorSelectedIndex == 0) {
      this.salesData = [];
      this.salesAmountTotal = 0;
      this.salesOrderTotal = 0;
      this.salesInvoicedTotal = 0;
      this.salesClosedTotal = 0; 
      this.salesTrueTotal = 0;
      this.kalcorAmountTotal = 0;
      this.kalcorOrderTotal = 0;
      this.kalcorInvoicedTotal = 0;
      this.kalcorClosedTotal = 0; 
      this.commissionAmountTotal = 0;
      this.commissionOrderTotal = 0;
      this.commissionInvoicedTotal = 0;
      this.commissionClosedTotal = 0; 
      this.marketingAmountTotal = 0;
      this.marketingOrderTotal = 0;
      this.marketingInvoicedTotal = 0;
      this.marketingClosedTotal = 0;
      this.exchangeAmountTotal = 0;
      this.exchangeOrderTotal = 0;
      this.exchangeInvoicedTotal = 0;
      this.exchangeClosedTotal = 0; 
      this.cndFreightAmountTotal = 0;
      this.cndFreightOrderTotal = 0;
      this.cndFreightInvoicedTotal = 0;
      this.cndFreightClosedTotal = 0;
      this.clearingAmountTotal = 0;
      this.clearingOrderTotal = 0;
      this.clearingInvoicedTotal = 0;
      this.clearingClosedTotal = 0;
      this.usdFreightAmountTotal = 0;
      this.usdFreightOrderTotal = 0;
      this.usdFreightInvoicedTotal = 0;
      this.usdFreightClosedTotal = 0;
      this.kalcorData = [];
      this.commissionData = [];
      this.marketingData = [];
      this.totalBilling = [];
      this.totalPeriod = [];
      this.exchangeData = [];
      this.cndFreightData = [];
      this.clearingData = [];
      this.usdFreightData = [];
    }
    if (this.vendorSelectedIndex >= this.vendorIds.length ) {
      this.insertTotals();
      this.insertExchangeTotals();
      this.alertType = "success";
      return;     
    }     
    const vendorId = this.vendorIds[this.vendorSelectedIndex];
    this.orderService.getBucketPeriod(this.periodStartDate, this.periodEndDate, vendorId, this.orderTypeSelected, this.billingAgentSelected).subscribe(data => {
      this.originalSales = data.order;
      this.originalExchange = data.exchangeData;
      this.originalCndFreight = data.freightData;
      this.orderService.getBucketPeriodClearing(this.periodStartDate, this.periodEndDate, vendorId, this.orderTypeSelected, this.billingAgentSelected).subscribe(data => {
        this.originalClearing = data;
        this.orderService.getBucketPeriodUSDFreight(this.periodStartDate, this.periodEndDate, vendorId, this.orderTypeSelected, this.billingAgentSelected).subscribe(data => {
          this.originalUsdFreight = data;
          this.calculate();
          this.run();
        })
      })
    })
  }
  calculate() {
    this.originalSales.forEach(e => { 
      this.getTotals(e);
      const index = this.salesData.findIndex(s => s.vendor == e.vendor);
      if (index == -1) {
        this.initializeSalesData(e);     
        this.initializeKalcorData(e);
        this.initializeCommissionData(e);
        this.initializeMarketingData(e);
        //this.initializeCndFreightData(e);    
      } else {
        this.updateSalesData(e, index);
        this.updateKalcorData(e, index);
        this.updateCommissionData(e, index);
        this.updateMarketingData(e, index);
      }
    });
    this.originalExchange.forEach(e => {
      this.getExchangeTotals(e);
      const index = this.exchangeData.findIndex(s => s.exchangeVendor == e.vendor);
      if (index == -1) {
        this.initializeExchangeData(e);
      } else {
        this.updateExchangeData(e, index);
      }
    });
    this.originalCndFreight.forEach(e => {
      this.getCndFreightTotals(e);
      const index = this.cndFreightData.findIndex(s => s.cndFreightVendor == e.vendor);
      if (index == -1) {
        this.initializeCndFreightData(e);
      } else {
        this.updateCndFreightData(e, index);
      }
    })   
    this.originalClearing.forEach(e => {
      this.getClearingTotals(e);
      const index = this.clearingData.findIndex(s => s.clearingVendor == e.vendor);
      if (index == -1) {
        this.initializeClearingData(e);
      } else {
        this.updateClearingData(e, index);
      }
    }) 
    this.originalUsdFreight.forEach(e => {
      this.getUsdFreightTotals(e);
      const index = this.usdFreightData.findIndex(s => s.usdFreightVendor == e.vendor);
      if (index == -1) {
        this.initializeUsdFreightData(e);
      } else {
        this.updateUsdFreightData(e, index);
      }
    }) 
  }
  updateSalesData(e, index) {
    const salesOrder = this.salesData[index].salesOrder += e.orderStatus == 'Sales Order' ? e.sales : 0;
    const salesInvoiced = this.salesData[index].salesInvoiced += e.orderStatus == 'Invoiced' && e.invoiced !== null ? e.invoiced  * e.caseCost : 0;
    const salesClosed = this.salesData[index].salesClosed += e.orderStatus == 'Closed' ? e.invoiced  * e.caseCost : 0;
    
    this.salesData[index].salesAmount = salesOrder + salesInvoiced + salesClosed;
}
  updateKalcorData(e, index) {
    const kalcorOrder = this.kalcorData[index].kalcorOrder += e.orderStatus == 'Sales Order' ? this.getPercentage(e.kalcor1, 0, e.sales) : 0;
    const kalcorInvoiced = this.kalcorData[index].kalcorInvoiced += e.orderStatus == 'Invoiced' && e.invoiced !== null ? this.getPercentage(e.kalcor1, 0, e.invoiced * e.caseCost) : 0;
    const kalcorClosed = this.kalcorData[index].kalcorClosed += e.orderStatus == 'Closed' ? this.getPercentage(e.kalcor1, 0, e.invoiced * e.caseCost) : 0;

    this.kalcorData[index].kalcorAmount = kalcorOrder + kalcorInvoiced + kalcorClosed;  
  }
  updateCommissionData(e, index) {
    //3.15.23 revised to combine commission 1 and 2    
    const commissionOrder = this.commissionData[index].commissionOrder += e.orderStatus == 'Sales Order' ? this.getPercentage(e.commPct1 , e.commPct2, e.sales) : 0;
    const commissionInvoiced = this.commissionData[index].commissionInvoiced += e.orderStatus == 'Invoiced' && e.invoiced !== null ? this.getPercentage(e.commPct1 , e.commPct2, e.invoiced  * e.caseCost) : 0;
    const commissionClosed = this.commissionData[index].commissionClosed += e.orderStatus == 'Closed' ? this.getPercentage(e.commPct1 , e.commPct2, e.invoiced  * e.caseCost) : 0;

    this.commissionData[index].commissionAmount = commissionOrder + commissionInvoiced + commissionClosed;
  }
  updateMarketingData(e, index) {
    const marketingOrder = this.marketingData[index].marketingOrder += e.orderStatus == 'Sales Order' ? this.getPercentage(e.marketing1, 0, e.sales) : 0;
    const marketingInvoiced = this.marketingData[index].marketingInvoiced += e.orderStatus == 'Invoiced' && e.invoiced !== null ? this.getPercentage(e.marketing1, 0, e.invoiced * e.caseCost) : 0;
    const marketingClosed = this.marketingData[index].marketingClosed += e.orderStatus == 'Closed' ? this.getPercentage(e.marketing1, 0, e.invoiced  * e.caseCost) : 0;

    this.marketingData[index].marketingAmount = marketingOrder + marketingInvoiced + marketingClosed;
  }
   updateCndFreightData(e, index) {
    this.cndFreightData[index].cndFreightAmount += e.orderStatus == 'Closed' ? e.difference : 0;
    //this.cndFreightData[index].cndFreightOrder += e.orderStatus == 'Sales Order' ? e.difference : 0;
    //this.cndFreightData[index].cndFreightInvoiced += e.orderStatus == 'Invoiced' ? e.difference : 0;
    this.cndFreightData[index].cndFreightClosed += e.orderStatus == 'Closed' ? e.difference : 0;
   }
   updateClearingData(e, index) {
    this.clearingData[index].clearingAmount += e.orderStatus == 'Closed' ? e.difference : 0;
    //this.clearingData[index].clearingOrder += e.orderStatus == 'Sales Order' ? e.difference : 0;
    //this.clearingData[index].clearingInvoiced += e.orderStatus == 'Invoiced' ? e.difference : 0;
    this.clearingData[index].clearingClosed += e.orderStatus == 'Closed' ? e.difference : 0;
   }
   updateUsdFreightData(e, index) {
    this.usdFreightData[index].usdFreightAmount += e.orderStatus == 'Closed' ? e.difference : 0;
    //this.usdFreightData[index].usdFreightOrder += e.orderStatus == 'Sales Order' ? e.difference : 0;
    //this.usdFreightData[index].usdFreightInvoiced += e.orderStatus == 'Invoiced' ? e.difference : 0;
    this.usdFreightData[index].usdFreightClosed += e.orderStatus == 'Closed' ? e.difference : 0;
   }
   //below cndFreight comes from dataOrders. Removed for PL option.
  // updateCndFreightData(e, index) {
  //   const cndFreightOrder = e.orderStatus == 'Sales Order' ? this.getFreightTotal(e) : 0;
  //   const cndFreightInvoiced = e.orderStatus == 'Invoiced' && e.invoiced !== null ? this.getFreightTotal(e) : 0;
  //   const cndFreightClosed = e.orderStatus == 'Closed' ? this.getFreightTotal(e) : 0;
  //   const cndFreightAmount = cndFreightOrder + cndFreightInvoiced + cndFreightClosed;

  //   this.cndFreightData[index].cndFreightOrder += cndFreightOrder;
  //   this.cndFreightData[index].cndFreightInvoiced += cndFreightInvoiced;
  //   this.cndFreightData[index].cndFreightClosed += cndFreightClosed;

  //   this.cndFreightData[index].cndFreightAmount = cndFreightOrder + cndFreightInvoiced + cndFreightClosed;
  // }
  updateExchangeData(e, index) {
      this.exchangeData[index].exchangeAmount += e.orderStatus !== 'Sales Order' ? e.actualDiff : 0;
      this.exchangeData[index].exchangeInvoiced += e.orderStatus == 'Invoiced' ? e.actualDiff : 0;
      this.exchangeData[index].exchangeClosed += e.orderStatus == 'Closed' ? e.actualDiff : 0;
  }
  initializeSalesData(e) {
    const salesOrder = e.orderStatus == 'Sales Order' ? e.sales : 0;
    const salesInvoiced = e.orderStatus == 'Invoiced' && e.invoiced !== null ? e.invoiced * e.caseCost : 0;
    const salesClosed = e.orderStatus == 'Closed' ? e.invoiced  * e.caseCost : 0;
    const salesAmount = salesOrder + salesInvoiced + salesClosed;
    
    const sales = {
      salesAmount: salesAmount,
      salesOrder: salesOrder,
      salesInvoiced: salesInvoiced,
      salesClosed: salesClosed,
      vendor: e.vendor           
    }
    this.salesData.push(sales); 
  }
  initializeKalcorData(e) {
    const kalcorOrder = e.orderStatus == 'Sales Order' ? this.getPercentage(e.kalcor1, 0, e.sales) : 0;
    const kalcorInvoiced = e.orderStatus == 'Invoiced' && e.invoiced !== null ? this.getPercentage(e.kalcor1, 0, e.invoiced  * e.caseCost) : 0;
    const kalcorClosed = e.orderStatus == 'Closed' ? this.getPercentage(e.kalcor1, 0, e.invoiced * e.caseCost) : 0;
    const kalcorAmount = kalcorOrder + kalcorInvoiced + kalcorClosed;

    const kalcor = {
      kalcorAmount: kalcorAmount,
      kalcorOrder: kalcorOrder,
      kalcorInvoiced: kalcorInvoiced,
      kalcorClosed: kalcorClosed,
      kalcorVendor: e.vendor           
    }
    this.kalcorData.push(kalcor);  
  }
  initializeCommissionData(e){
    //3.15.23 revised to combine commission 1 and 2  
    const commissionOrder = e.orderStatus == 'Sales Order' ? this.getPercentage(e.commPct1 , e.commPct2, e.sales) : 0;
    const commissionInvoiced = e.orderStatus == 'Invoiced' && e.invoiced !== null ? this.getPercentage(e.commPct1 , e.commPct2, e.invoiced * e.caseCost) : 0;
    const commissionClosed = e.orderStatus == 'Closed' ? this.getPercentage(e.commPct1 , e.commPct2, e.invoiced * e.caseCost) : 0;
    const commissionAmount = commissionOrder + commissionInvoiced + commissionClosed;

    const commission = {
      commissionAmount: commissionAmount,
      commissionOrder: commissionOrder,
      commissionInvoiced: commissionInvoiced,
      commissionClosed: commissionClosed,
      commissionVendor: e.vendor           
    }
    this.commissionData.push(commission);    
  }
  initializeMarketingData(e){
    const marketingOrder = e.orderStatus == 'Sales Order' ? this.getPercentage(e.marketing1, 0, e.sales) : 0;
    const marketingInvoiced = e.orderStatus == 'Invoiced' && e.invoiced !== null ? this.getPercentage(e.marketing1, 0, e.invoiced * e.caseCost) : 0;
    const marketingClosed = e.orderStatus == 'Closed' ? this.getPercentage(e.marketing1, 0, e.invoiced * e.caseCost) : 0;
    const marketingAmount = marketingOrder + marketingInvoiced + marketingClosed;

    const marketing = {
      marketingAmount: marketingAmount,
      marketingOrder: marketingOrder,
      marketingInvoiced: marketingInvoiced,
      marketingClosed: marketingClosed,
      marketingVendor: e.vendor           
    }
    this.marketingData.push(marketing);
  }
  //3.14.23 changed order and invoiced to zero
  initializeCndFreightData(e){
    const cndFreight = {
      cndFreightAmount: e.orderStatus == 'Closed' ? e.difference : 0,
      cndFreightOrder: 0,
      cndFreightInvoiced: 0,
      cndFreightClosed: e.orderStatus == 'Closed' ? e.difference : 0,
      cndFreightVendor: e.vendor           
    }
    this.cndFreightData.push(cndFreight); 
  }
  initializeClearingData(e){
    const clearing = {
      clearingAmount: e.orderStatus == 'Closed' ? e.difference : 0,
      clearingOrder: 0,
      clearingInvoiced: 0,
      clearingClosed: e.orderStatus == 'Closed' ? e.difference : 0,
      clearingVendor: e.vendor           
    }
    this.clearingData.push(clearing); 
  }
  initializeUsdFreightData(e){
    const usdFreight = {
      usdFreightAmount: e.orderStatus == 'Closed' ? e.difference : 0,
      usdFreightOrder: 0,
      usdFreightInvoiced: 0,
      usdFreightClosed: e.orderStatus == 'Closed' ? e.difference : 0,
      usdFreightVendor: e.vendor           
    }
    this.usdFreightData.push(usdFreight); 
  }
  // initializeCndFreightData(e){
  //   const cndFreightOrder = e.orderStatus == 'Sales Order' ? this.getFreightTotal(e) : 0;
  //   const cndFreightInvoiced = e.orderStatus == 'Invoiced' && e.invoiced !== null ? this.getFreightTotal(e) : 0;
  //   const cndFreightClosed = e.orderStatus == 'Closed' ? this.getFreightTotal(e) : 0;
  //   const cndFreightAmount = cndFreightOrder + cndFreightInvoiced + cndFreightClosed;

  //   const cndFreight = {
  //     cndFreightAmount: cndFreightAmount,
  //     cndFreightOrder: cndFreightOrder,
  //     cndFreightInvoiced: cndFreightInvoiced,
  //     cndFreightClosed: cndFreightClosed,
  //     cndFreightVendor: e.vendor           
  //   }
  //   this.cndFreightData.push(cndFreight);  
  // }
  initializeExchangeData(e){
    const exchange = {
      exchangeAmount: e.orderStatus !== 'Sales Order' ? e.actualDiff : 0,
      exchangeOrder: 0,
      exchangeInvoiced: e.orderStatus == 'Invoiced' ? e.actualDiff : 0,
      exchangeClosed: e.orderStatus == 'Closed' ? e.actualDiff : 0,
      exchangeVendor: e.vendor           
    }
    this.exchangeData.push(exchange);
  }
  // getFreightTotal(c) {
  //   let retValue = 0;
  //   retValue += c.freightType1 === 'CND' ? c.freightTotalCost1 : 0;
  //   retValue += c.freightType2 === 'CND' ? c.freightTotalCost2 : 0;
  //   retValue += c.freightType3 === 'CND' ? c.freightTotalCost3 : 0;
  //   retValue += c.freightType4 === 'CND' ? c.freightTotalCost4 : 0;
  //   retValue += c.freightType5 === 'CND' ? c.freightTotalCost5 : 0;
  //   return retValue;    
  // }
  getTotals(e) {
        this.salesAmountTotal += e.sales;
        this.salesOrderTotal += e.orderStatus == 'Sales Order' ? e.sales: 0;
        this.salesInvoicedTotal += e.orderStatus == 'Invoiced' && e.invoiced !== null ? e.invoiced  * e.caseCost : 0;
        this.salesClosedTotal += e.orderStatus == 'Closed' ? e.invoiced * e.caseCost : 0;
        this.kalcorAmountTotal += this.getPercentage(e.kalcor1, 0, e.sales);
        this.kalcorOrderTotal += e.orderStatus == 'Sales Order' ? this.getPercentage(e.kalcor1, 0, e.sales) : 0;
        this.kalcorInvoicedTotal += e.orderStatus == 'Invoiced' && e.invoiced !== null ? this.getPercentage(e.kalcor1, 0, e.invoiced * e.caseCost) : 0;
        this.kalcorClosedTotal += e.orderStatus == 'Closed' ? this.getPercentage(e.kalcor1, 0, e.invoiced * e.caseCost) : 0;
        this.commissionAmountTotal += this.getPercentage(e.commPct1 , e.commPct2, e.sales);
        this.commissionOrderTotal += e.orderStatus == 'Sales Order' ? this.getPercentage(e.commPct1 , e.commPct2, e.sales) : 0;
        this.commissionInvoicedTotal += e.orderStatus == 'Invoiced' && e.invoiced !== null ? this.getPercentage(e.commPct1 , e.commPct2, e.invoiced  * e.caseCost) : 0;
        this.commissionClosedTotal += e.orderStatus == 'Closed' ? this.getPercentage(e.commPct1 , e.commPct2, e.invoiced * e.caseCost) : 0;
        this.marketingAmountTotal += this.getPercentage(e.marketing1, 0, e.sales);
        this.marketingOrderTotal += e.orderStatus == 'Sales Order' ? this.getPercentage(e.marketing1, 0, e.sales) : 0;
        this.marketingInvoicedTotal += e.orderStatus == 'Invoiced' && e.invoiced !== null ? this.getPercentage(e.marketing1, 0, e.invoiced * e.caseCost) : 0;
        this.marketingClosedTotal += e.orderStatus == 'Closed' ? this.getPercentage(e.marketing1, 0, e.invoiced * e.caseCost) : 0;     
        // this.cndFreightAmountTotal = this.getFreightTotal(e);
        // this.cndFreightOrderTotal = e.orderStatus == 'Sales Order' ? this.getFreightTotal(e) : 0;
        // this.cndFreightInvoicedTotal = e.orderStatus == 'Invoiced' && e.invoiced !== null ? this.getFreightTotal(e) : 0;
        // this.cndFreightClosedTotal = e.orderStatus == 'Closed' ? this.getFreightTotal(e) : 0;

  }
  getExchangeTotals(e) {
      this.exchangeAmountTotal += e.orderStatus !== 'Sales Order' ? e.actualDiff : 0;
      this.exchangeOrderTotal += 0;
      this.exchangeInvoicedTotal += e.orderStatus == 'Invoiced' ? e.actualDiff : 0;
      this.exchangeClosedTotal += e.orderStatus == 'Closed' ? e.actualDiff : 0;  
  }
  getCndFreightTotals(e) {
    this.cndFreightAmountTotal += e.orderStatus == 'Closed' ? e.difference : 0;
    this.cndFreightOrderTotal += 0;
    this.cndFreightInvoicedTotal += 0;
    this.cndFreightClosedTotal += e.orderStatus == 'Closed' ? e.difference : 0;
  }
  getClearingTotals(e) {
    this.clearingAmountTotal += e.orderStatus == 'Closed' ? e.difference : 0;
    this.clearingOrderTotal += 0;
    this.clearingInvoicedTotal += 0;
    this.clearingClosedTotal += e.orderStatus == 'Closed' ? e.difference : 0;
  }
  getUsdFreightTotals(e) {
    this.usdFreightAmountTotal += e.orderStatus == 'Closed' ? e.difference : 0;
    this.usdFreightOrderTotal += 0;
    this.usdFreightInvoicedTotal += 0;
    this.usdFreightClosedTotal += e.orderStatus == 'Closed' ? e.difference : 0;
  }
  insertTotals() {
    const sales = {
      salesAmount: this.salesOrderTotal + this.salesInvoicedTotal + this.salesClosedTotal,
      salesOrder: this.salesOrderTotal,
      salesInvoiced: this.salesInvoicedTotal,
      salesClosed: this.salesClosedTotal,
      vendor: 'TOTALS'
    }
    this.salesData.push(sales);
    const kalcor = {
      kalcorAmount: this.kalcorOrderTotal + this.kalcorInvoicedTotal + this.kalcorClosedTotal,
      kalcorOrder: this.kalcorOrderTotal,
      kalcorInvoiced: this.kalcorInvoicedTotal,
      kalcorClosed: this.kalcorClosedTotal,
      kalcorVendor: 'TOTALS'
    }
    this.kalcorData.push(kalcor);
    const commission = {
      commissionAmount: this.commissionOrderTotal + this.commissionInvoicedTotal + this.commissionClosedTotal,
      commissionOrder: this.commissionOrderTotal,
      commissionInvoiced: this.commissionInvoicedTotal,
      commissionClosed: this.commissionClosedTotal,
      commissionVendor: 'TOTALS'
    }
    this.commissionData.push(commission);
    const marketing = {
      marketingAmount: this.marketingOrderTotal + this.marketingInvoicedTotal + this.marketingClosedTotal,
      marketingOrder: this.marketingOrderTotal,
      marketingInvoiced: this.marketingInvoicedTotal,
      marketingClosed: this.marketingClosedTotal,
      marketingVendor: 'TOTALS'
    }
    this.marketingData.push(marketing);
    const cndFreight = {
      cndFreightAmount: this.cndFreightOrderTotal + this.cndFreightInvoicedTotal + this.cndFreightClosedTotal,
      cndFreightOrder: this.cndFreightOrderTotal,
      cndFreightInvoiced: this.cndFreightInvoicedTotal,
      cndFreightClosed: this.cndFreightClosedTotal,
      cndFreightVendor: 'TOTALS'
    }
    this.cndFreightData.push(cndFreight);
    const clearing = {
      clearingAmount: this.clearingOrderTotal + this.clearingInvoicedTotal + this.clearingClosedTotal,
      clearingOrder: this.clearingOrderTotal,
      clearingInvoiced: this.clearingInvoicedTotal,
      clearingClosed: this.clearingClosedTotal,
      clearingVendor: 'TOTALS'
    }
    this.clearingData.push(clearing);
    const usdFreight = {
      usdFreightAmount: this.usdFreightOrderTotal + this.usdFreightInvoicedTotal + this.usdFreightClosedTotal,
      usdFreightOrder: this.usdFreightOrderTotal,
      usdFreightInvoiced: this.usdFreightInvoicedTotal,
      usdFreightClosed: this.usdFreightClosedTotal,
      usdFreightVendor: 'TOTALS'
    }
    this.usdFreightData.push(usdFreight);
    this.getBillingTotals();
  }
  insertExchangeTotals() {
    const exchange = {
      exchangeAmount: this.exchangeAmountTotal,
      exchangeOrder: this.exchangeOrderTotal,
      exchangeInvoiced: this.exchangeInvoicedTotal,
      exchangeClosed: this.exchangeClosedTotal,
      exchangeVendor: 'TOTALS'
    }
    this.exchangeData.push(exchange);
    this.getPeriodTotals(); 
  }
  getBillingTotals() {
    const billingOrder = this.kalcorOrderTotal + this.commissionOrderTotal + this.marketingOrderTotal + this.cndFreightOrderTotal;
    const billingInvoiced = this.kalcorInvoicedTotal + this.commissionInvoicedTotal + this.marketingInvoicedTotal + this.cndFreightInvoicedTotal;
    const billingClosed = this.kalcorClosedTotal + this.commissionClosedTotal + this.marketingClosedTotal + this.cndFreightClosedTotal;
    const billingAmount = billingOrder + billingInvoiced + billingClosed;

    const billingTotals = {
      billingAmount: billingAmount,
      billingOrder: billingOrder,
      billingInvoiced: billingInvoiced,
      billingClosed: billingClosed,
      billingVendor: 'ALL VENDOR BILLING TOTALS'
    }
    this.totalBilling.push(billingTotals);
  }
  getPeriodTotals() {
    const periodOrder = this.kalcorOrderTotal + this.commissionOrderTotal + this.marketingOrderTotal + this.cndFreightOrderTotal;
    const periodInvoiced = this.kalcorInvoicedTotal + this.commissionInvoicedTotal + this.marketingInvoicedTotal + this.cndFreightInvoicedTotal + this.exchangeInvoicedTotal;
    const periodClosed = this.kalcorClosedTotal + this.commissionClosedTotal + this.marketingClosedTotal + this.cndFreightClosedTotal + this.exchangeClosedTotal;
    const periodAmount = periodOrder + periodInvoiced + periodClosed;

    const periodTotals = {
      periodAmount: periodAmount,
      periodOrder: periodOrder,
      periodInvoiced: periodInvoiced,
      periodClosed: periodClosed,
      periodVendor: 'TOTAL PERIOD BILLING'
    }
    this.totalPeriod.push(periodTotals);
  }
  getPercentage(divider, divider2, saleTotal) {
    if (saleTotal == null) {
      saleTotal = 0;
    }
    const value = divider ? divider : 0;
    const bigNum: any = Big(saleTotal)
      .times(value)
      .times(.01)
      .round(2);
    const total = bigNum * 1;

    const value2 = divider2 ? divider2 : 0;
    const bigNum2: any = Big(saleTotal)
      .times(value2)
      .times(.01)
      .round(2);
    const total2 = bigNum2 * 1;

    return total + total2;
  }
  reset() {
    this.alertType = "success";
    this.newOne = true;
    this.vendorIds = 0;
    this.periodSelected = 0;
    this.billingAgentSelected = 0;
    this.periods = [];
    this.periodStartDate = '';
    this.periodEndDate = '';
    this.orderTypeSelected = '';
    this.salesData = [];
    this.kalcorData = [];
    this.commissionData = [];
    this.marketingData = [];
    this.totalBilling = [];
    this.totalPeriod = [];
    this.exchangeData = [];
    this.cndFreightData = [];
    this.clearingData = [];
    this.usdFreightData = [];
  }
}
