import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { resolve } from 'dns';
import { PalletSetupEditComponent } from '../components/product';
import { AuthGuard } from '../guard/auth.guard';
import { 
    BorderCrossingEditComponent,
    BorderCrossingListComponent,
    BorderCrossingResolver,
    BrokerEditComponent,
    BrokerListComponent,
    BrokerResolver,
    SalesManagerEditComponent,
    SalesManagerListComponent,
    SalesManagerResolver,
    ExchangeRateEditComponent,
    ExchangeRateListComponent,
    ExchangeRateResolver,
    TransportationEditComponent,
    TransportationListComponent,
    TransportationResolver,
    ClearingCompanyEditComponent,
    ClearingCompanyListComponent,
    ClearingCompanyResolver,
    WarehouseDetailComponent,
    WarehouseListComponent,
    WarehouseResolver,
    LocationEditComponent,
    LocationListComponent,
    LocationResolver,
    TermsEditComponent,
    TermsListComponent,
    TermsResolver,
    PalletTypeEditComponent,
    PalletTypeListComponent,
    PalletTypeResolver,
    RegionEditComponent,
    RegionListComponent,
    RegionResolver,
    TypeOfAccountEditComponent,
    TypeOfAccountListComponent,
    TypeOfAccountResolver,
    WeekCustomerEditComponent,
    WeekCustomerListComponent,
    WeekCustomerNewComponent,
    WeekCustomerResolver,
    WeekCustomerEndEditComponent,
    WeekCustomerEndListComponent,
    WeekCustomerEndNewComponent,
    WeekCustomerHeadsResolver,
    WeekKalcorEditComponent,
    WeekKalcorListComponent,
    WeekKalcorResolver,
    CreditReasonsEditComponent,
    CreditReasonsListComponent,
    CreditReasonsResolver


} from './index';
import { BillingAgentEditComponent, BillingAgentListComponent, BillingAgentResolver } from './billing-agent';

const routes: Routes = [
    {
        path: 'other',
        canActivate: [AuthGuard],
        data: { section: 'other' },
        children: [
            {
                path: 'broker-list', component: BrokerListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'broker-edit/:id', component: BrokerEditComponent,
                canActivate: [AuthGuard],
                resolve: { dataBroker: BrokerResolver }
            },
            {
                path: 'sales-manager-list', component: SalesManagerListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'sales-manager-edit/:id', component: SalesManagerEditComponent,
                canActivate: [AuthGuard],
                resolve: { dataSalesManager: SalesManagerResolver }
            },
            {
                path: 'transportation-list', component: TransportationListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'transportation-edit/:id', component: TransportationEditComponent,
                canActivate: [AuthGuard],
                resolve: { dataTransport: TransportationResolver }
            },
            {
                path: 'clearing-co-list', component: ClearingCompanyListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'clearing-co-edit/:id', component: ClearingCompanyEditComponent,
                canActivate: [AuthGuard],
                resolve: { dataClearing: ClearingCompanyResolver }
            },
            {
                path: 'warehouse-list', component: WarehouseListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'warehouse-edit/:id', component: WarehouseDetailComponent,
                canActivate: [AuthGuard],
                resolve: { dataWarehouse: WarehouseResolver }
            },
            {
                path: 'terms-list', component: TermsListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'terms-edit/:id', component: TermsEditComponent,
                canActivate: [AuthGuard],
                resolve: { dataTerms: TermsResolver }
            },
            {
                path: 'location-list', component: LocationListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'location-edit/:id', component: LocationEditComponent,
                canActivate: [AuthGuard],
                resolve: { dataLocation: LocationResolver }
            },
            {
                path: 'border-crossing-list', component: BorderCrossingListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'border-crossing-edit/:id', component: BorderCrossingEditComponent,
                canActivate: [AuthGuard],
                resolve: { dataBorderCrossing: BorderCrossingResolver }
            },
            {
                path: 'pallet-type-list', component: PalletTypeListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'pallet-type-edit/:id', component: PalletTypeEditComponent,
                canActivate: [AuthGuard],
                resolve: { dataPalletType: PalletTypeResolver }
            },
            {
                path: 'exchange-rate-list', component: ExchangeRateListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'exchange-rate-edit/:id', component: ExchangeRateEditComponent,
                canActivate: [AuthGuard],
                resolve: { dataExchangeRate: ExchangeRateResolver }
            },
            {
                path: 'region-list', component: RegionListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'region-edit/:id', component: RegionEditComponent,
                canActivate: [AuthGuard],
                resolve: { dataRegion: RegionResolver }
            },
            {
                path: 'type-of-account-list', component: TypeOfAccountListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'type-of-account-edit/:id', component: TypeOfAccountEditComponent,
                canActivate: [AuthGuard],
                resolve: { dataTypeOfAccount: TypeOfAccountResolver }
            }, 
            {
                path: 'week-customer-list', component: WeekCustomerListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'week-customer-new', component: WeekCustomerNewComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'week-customer-edit/:id/:id2', component: WeekCustomerEditComponent,
                canActivate: [AuthGuard],
                //comment out resolver
                // resolve: { dataWeekCustomer: WeekCustomerResolver }
            },
            {
                path: 'week-customer-end-new', component: WeekCustomerEndNewComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'week-customer-end-list', component: WeekCustomerEndListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'week-customer-end-edit/:id/:id2', component: WeekCustomerEndEditComponent,
                canActivate: [AuthGuard],
                //comment out resolver
                //resolve: { dataWeekCustomerEnd: WeekCustomerHeadsResolver }
            },           
            {
                path: 'week-kalcor-list', component: WeekKalcorListComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'week-kalcor-edit/:id', component: WeekKalcorEditComponent,
                canActivate: [AuthGuard],
                resolve: { dataWeekKalcor: WeekKalcorResolver }
            },
            {
                path: 'credit-reasons-list', component: CreditReasonsListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'credit-reasons-edit/:id', component: CreditReasonsEditComponent,
                canActivate: [AuthGuard],
                resolve: { dataCreditReasons: CreditReasonsResolver }
            },
            {
                path: 'billing-agent-list', component: BillingAgentListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'billing-agent-edit/:id', component: BillingAgentEditComponent,
                canActivate: [AuthGuard],
                resolve: { dataBillingAgent: BillingAgentResolver }
            }    
        ]
    }
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class OtherRoutingModule { }
