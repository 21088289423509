import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product, ActiveListings, ProductInit } from '../../../../models/product.model';
import { FormControl, FormsModule } from '@angular/forms';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { ProductService } from '../../../../services/product.service';
import { CustomerService } from '../../../../services/customer.service';
import { Customer } from '../../../../models/customer.model';
import { ShipToAddressService } from '../../../../services/ship-to-address.service';
import { ShipToAddress } from '../../../../models/shipto.model';
import { CustomerEndService } from '../../../../services/customer-end.service';
import { CustomerEnd } from '../../../../models/customer-end-model';
import { CustomerRetailService } from '../../../../services/customer-retail.service';
import { CustomerRetail } from '../../../../models/customer-retail.model';
import { BrokerService } from '../../../../services/broker.service';
import { Broker } from '../../../../models/broker.model';
import { BrandService } from '../../../../services/brand.service';
import { Brand } from '../../../../models/brand.model';
import { CategoryService } from '../../../../services/category.service';
import { Category } from '../../../../models/category.model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UpcGtnService } from '../../../../services/upc-gtn.service';
import { UpcGtn } from '../../../../models/upc-gtn.model';
import { ShipToBrokerService } from '../../../../services/ship-to-broker.service';
import { ShipToBroker } from '../../../../models/shipto-broker';
import { SalesManager } from 'src/app/models/sales-manager.model';
import { SalesManagerService } from 'src/app/services/sales-manager.service';
import { SalesMessageService } from 'src/app/services/sales-message.service';
import { CustomerHeads } from 'src/app/models/customer-heads.model';
import { CustomerHeadsService } from 'src/app/services/customer-heads.service';
import { BillingAgent } from 'src/app/models/billing-agent.model';
import { BillingAgentService } from 'src/app/services/billing-agent.service';

@Component({
  selector: 'app-listing-setup-edit',
  templateUrl: './listing-setup-edit.component.html',
  styleUrls: ['./listing-setup-edit.component.scss']
})
export class ListingSetupEditComponent implements OnInit {
  product: Product;
  customers: Customer[];
  shipTo: ShipToAddress[];
  shipToOrginal: ShipToAddress[];
  customerEnd = [];
  customerHead = [];
  // customerHeadIds: string;
  customerHeadOriginal = [];
  customerRetail = [];
  customerRetailOriginal = [];
  customerRetailSelected = [];
  customerHeadChanged = false;
  customerHeadSelected = [];
  brokers: Broker[];
  salesmanager: SalesManager[];
  billingAgent: BillingAgent[];
  page = 1;
  pageSize = 10;
  searchText = '';
  productForm: UntypedFormGroup;
  brands: Brand[];
  categories: Category[];
  gtn: UpcGtn[];
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  alertType = "success";
  activeList = [];
  shipToBroker = [];
  shipToBrokerOriginal: ShipToBroker[];
  shipToBrokerChanged = false;
  bannerData = [];
  originalBanner = [];
  counter = 0;
  totalCount = 0;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private fb: UntypedFormBuilder,
    private customerService: CustomerService,
    private shipToService: ShipToAddressService,
    private customerEndService: CustomerEndService,
    private customerRetailService: CustomerRetailService,
    private gtnService: UpcGtnService,
    private brandService: BrandService,
    private categoryService: CategoryService,
    private brokerService: BrokerService,
    private SalesManagerSvc: SalesManagerService,
    private customerHeadSvc: CustomerHeadsService,
    private billingAgentSvc: BillingAgentService,
    private shipToBrokerSvc: ShipToBrokerService) { }

  ngOnInit() {
    this.activeList = ActiveListings;
    this.productForm = this.fb.group({
      activeListings:  [''],
      productOrderCode: [{ value: '', disabled: true }],
      brand: [{ value: '', disabled: true }],
      category: [{ value: '', disabled: true }],
      itemDescription: [{ value: '', disabled: true }],
      size: [{ value: '', disabled: true }],
      costType: [''],
      customerId: [''],
      // headOfficeId: [''],
      // retailId: [''],
      retailIds: [''],
      brokerId: [''],
      broker2Id: [''],
      salesMgrId: [''],
      shipToIds: [''],
      headOfficeIds: [''],
      billingAgentId: ['']
    });
    this.brandService.getAll().subscribe(brand => {
      this.brands = brand;
      this.billingAgentSvc.getAll().subscribe(bill => {
        this.billingAgent = bill;
        this.SalesManagerSvc.getAll().subscribe(salesmanager => {
          this.salesmanager = salesmanager;
          this.categoryService.getAll().subscribe(category => {
            this.categories = category;
            this.gtnService.getAll().subscribe(gtns => {
              this.gtn = gtns;
              // this.customerService.getAll().subscribe(customer => {
                this.customerService.getFilteredList('Active', '', 30, 1).subscribe(customer => {
                this.customers = customer.item2;
                this.shipToService.getAll().subscribe(shipTo => {
                  this.shipTo = shipTo;
                  this.shipToOrginal = shipTo;
                  this.customerEndService.getAll().subscribe(customerEnd => {
                    this.customerHeadOriginal = customerEnd;
                    this.customerRetailService.getAll().subscribe(retail => {
                      this.customerRetailOriginal = retail;
                      this.brokerService.getAll().subscribe(broker => {
                        this.brokers = broker.filter(data => data.activeItem == 'Active');
                        this.route.data.subscribe(data => {
                          if (data.dataProductSetup !== undefined) {
                            this.product = data.dataProductSetup;
                            this.product.activeListings = data.dataProductSetup.activeListings.replace("–", "-");
                            this.shipToBrokerSvc.getList(this.product.id).subscribe(ship => {
                              this.shipToBrokerOriginal = ship;
                              this.fillFormData();
                              this.onChanges();
                              this.onChangesShipTo();
                              this.onChangesCustomerHead();
                              this.setupShipTo(this.product.customerId);
                              this.setupCustomerHeads(this.product.customerId);
                              this.setupBanners(this.product.headOfficeIds);
                              this.setupShipToBroker(this.product.shipToIds);
                            });
                          }
                        });
                      })
                    })
                  })
                })
              })
            });
          });
        });
      });
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  fillFormData() {

    const productOrderCodeValue = this.getValue(this.product.productOrderCodeId, 'productOrderCodeId');
    const brandValue = this.getValue(this.product.productOrderCodeId, 'brandId');
    const categoryValue = this.getValue(this.product.categoryId, 'categoryId');
    this.splitNumbers(this.product.shipToIds);
    this.productForm.setValue({
      activeListings: this.product.activeListings,
      productOrderCode: productOrderCodeValue,
      brand: brandValue,
      salesMgrId: this.product.salesMgrId,
      billingAgentId: this.product.billingAgentId,
      category: categoryValue,
      itemDescription: this.product.itemDescription,
      size: this.product.size,
      costType: this.product.costType,
      customerId: this.product.customerId,
      headOfficeIds: this.splitNumbers(this.product.headOfficeIds),
      // retailId: this.product.retailId,
      brokerId: this.product.brokerId,
      broker2Id: this.product.broker2Id,
      shipToIds: this.splitNumbers(this.product.shipToIds),
      retailIds: this.splitNumbers(this.product.retailIds)
    });
    if (this.product.commission1 && this.product.commission1 != 0) {
      this.productForm.controls.brokerId.enable();
    }
    if (this.product.commission2 && this.product.commission2 != 0) {
      this.productForm.get('broker2Id').enable();
    }
  }


  onChanges(): void {

    this.productForm.get('customerId').valueChanges.subscribe(val => {
      this.setupShipTo(val);
      this.setupCustomerHeads(val);
    });

  }
  onChangesShipTo(): void {

    this.productForm.get('shipToIds').valueChanges.subscribe(val => {
      this.shipToBrokerChanged = true;
      this.setupShipToBroker(val);
    });
  }
  onChangesCustomerHead(): void {
    this.productForm.get('headOfficeIds').valueChanges.subscribe(val => {
      this.customerHeadChanged = true;
      this.setupBanners(val);
    })
  }
  splitNumbers(ids) {
    let ret = [];
    if (ids) {
      let retCount = 0;
      const tempSplit = ids.split(',');
      if (tempSplit.length > 0) {
        tempSplit.forEach( id => {
          if (id) {
            ret.push(id * 1);
          }
        })
      }
    }

    return ret;
  }
  setupCustomerHeads(cust) {
    this.customerEnd = [];
    if (cust) {
      this.customerHeadSvc.getCustomers(cust).subscribe(data => {
        if (data) {
          this.customerHeadSelected = data;
          this.customerHeadOriginal.forEach(e => {
            const index = this.customerHeadSelected.findIndex(h => h.headId == e.id);
            if (index > -1) {
              const end = {
                head: e.id,
                company: e.company,
              }
              this.customerEnd.push(end);
            }
          })
        }        
      })
    }
  }
  setupBanners(head) {
    let initializeThis = false;
    let headIds;
    if (typeof(head) == 'string') {
      headIds = this.splitNumbers(head);
      initializeThis = true;
    } else {
      headIds = head;
    }
    
    if (initializeThis = true) {
      this.customerRetail = [];
      headIds.forEach(e => {
        if (e) {
          this.customerHead = e;
          this.customerEndService.getFilteredListing("Active", e, 1, 999).subscribe(data => {
            this.customerRetailSelected = data.item;
            this.customerRetailOriginal.forEach(e => {
              const index = this.customerRetailSelected.findIndex(b => b.retailId == e.id);
              if (index > -1) {
                const banner = {
                  retailCompany: e.company,
                  retail: e.id,
                }
                this.customerRetail.push(banner);
              } 
            })
          })
        }     
      });
    }
    
  }
  
  setupShipTo(custid) {
    this.shipTo = [];
    if (custid) {
      this.shipToOrginal.forEach( x => {
        if (x.customerId == custid) {
          this.shipTo.push(x);
        }
      })
      this.shipToBroker = [];
    }
  }
  setupShipToBroker(ids) {

    let initializeThis = false;
    let shipTos;
    if (typeof(ids) == 'string') {
      shipTos = this.splitNumbers(ids);
      initializeThis = true;
    } else {
      shipTos = ids;
    } 

    if (initializeThis) {
      this.shipToBroker = [];
      shipTos.forEach( ship => {
        if (ship) {
          const shipToBrokerOriginalIndex = this.shipToBrokerOriginal.findIndex(o => o.shipToId == ship);
          let broker1 = null;
          let broker2 = null;
          if (shipToBrokerOriginalIndex > -1) {
            broker1 = this.shipToBrokerOriginal[shipToBrokerOriginalIndex].brokerId;
            broker2 = this.shipToBrokerOriginal[shipToBrokerOriginalIndex].broker2Id;
          }

          const shipToIndex = this.shipTo.findIndex(o => o.id == ship);
          if (shipToIndex > -1) {
            const item = { 
              shipToId: this.shipTo[shipToIndex].id,
              name: this.shipTo[shipToIndex].company,
              brokerId: broker1,
              broker2Id: broker2
            };
            this.shipToBroker.push(item);
          }
        }
      })
    } else {
      let hold = [];
      this.shipToBroker.forEach(data => {
        hold.push(data);
      })
      this.shipToBroker = [];
      if (shipTos === null) {

      }
      else {
        shipTos.forEach( ship => {
          if (ship) {
            const holdIndex = hold.findIndex(o => o.shipToId == ship);
            if (holdIndex > -1) {
              this.shipToBroker.push(hold[holdIndex]);
            } else {
              const shipToIndex = this.shipTo.findIndex(o => o.id == ship);
              if (shipToIndex > -1) {
                const item = { 
                  shipToId: this.shipTo[shipToIndex].id,
                  name: this.shipTo[shipToIndex].company,
                  brokerId: null,
                  broker2Id: null
                };
                this.shipToBroker.push(item);
              }
    
            }
          }
        })
      }
      
    }
  }  
  getValue(id: number, name: string) {
    let ret = '';
    if (id) {
      let index = -1;
      switch (name) {
        case 'productOrderCodeId':
          index = this.gtn.findIndex(item => item.id === id);
          if (index > -1) {
            ret = this.gtn[index].vendorOrderCode;
          }
          break;
          case 'brandId':
            index = this.gtn.findIndex(item => item.id === id);
            if (index > -1) {
              const brandId = this.gtn[index].brandId;
              if (brandId > -1 ) {
                const brandIndex = this.brands.findIndex(item => item.id === brandId);
                if (brandIndex > -1) {
                  ret = this.brands[brandIndex].brand;
                }  
              }
            }
            break;
        case 'categoryId':
          index = this.categories.findIndex(item => item.id === id);
          if (index > -1) {
            ret = this.categories[index].category;
          }
          break;
        }
    }
    return ret;
  }
  updateProduct() {
    for (const item in this.productForm.controls) {
      if (this.productForm.controls.hasOwnProperty(item)) {
        if (this.product[item] !== undefined) {
          if (item == 'shipToIds' || item == 'headOfficeIds' || item == 'retailIds') {
            this.product[item] = this.productForm.controls[item].value.join();
          } else {
            this.product[item] = this.productForm.controls[item].value;
          }
        }
      }
    }
  }
  onDelete() {
    if (this.product.id) {
      this.productService.remove(this.product.id).subscribe(data => {
        this.router.navigate(['/productListings/listing-setup-list']);
      });
    }
  }
  onSubmit() {
    this.updateProduct();

    this.productService.update(this.product).subscribe(data => {
          this.updateShiptoBroker();
        }, error => {
          this.success.next('Unable to update listing.');
          this.alertType = "danger";
    });
  }

  updateShiptoBroker() {

    const shipToBrokerUpdate = [];
    this.totalCount = 0;
    // this decides what to do with original items
    this.shipToBrokerOriginal.forEach(ship => {
      const item = { 
        shipToBrokerId: ship.shipToBrokerId,
        listingId: this.product.id,
        shipToId: ship.shipToId,
        brokerId: ship.brokerId,
        broker2Id: ship.broker2Id,
        commission: ship.commission,
        commission1: ship.commission1,
        commission2: ship.commission2,
        process: ''
      };
      const shipToIndex = this.shipToBroker.findIndex(o => o.shipToId == ship.shipToId);
      if (shipToIndex == -1) {
        item.process = 'D';
        this.totalCount++;
      } else {
        if (this.shipToBroker[shipToIndex].brokerId == ship.brokerId &&
            this.shipToBroker[shipToIndex].broker2Id == ship.broker2Id) {
        } else {
          item.process = 'U';
          item.brokerId = this.shipToBroker[shipToIndex].brokerId;
          item.broker2Id = this.shipToBroker[shipToIndex].broker2Id;
          this.totalCount++;
        }
      }
      shipToBrokerUpdate.push(item);
    })
    // this inserts items not found in original
    this.shipToBroker.forEach(ship => {
      const shipToIndex = this.shipToBrokerOriginal.findIndex(o => o.shipToId == ship.shipToId);
      if (shipToIndex == -1) {
        const item = { 
          shipToBrokerId: 0,
          listingId: this.product.id,
          shipToId: ship.shipToId,
          brokerId: ship.brokerId === null ? 0 : ship.brokerId,
          broker2Id: ship.broker2Id === null ? 0 : ship.broker2Id,
          commission: 0,
          commission1: 0,
          commission2: 0,
          process: 'C'
        }
        this.totalCount++;
        shipToBrokerUpdate.push(item);
      }
    })

    if (this.totalCount == 0) {
      this.success.next('Successful Update');
      return;
    }

    // update database tables and rebuilds orginal
    this.counter = 0;
    this.shipToBrokerOriginal = [];
    shipToBrokerUpdate.forEach(ship => {
      let item = new ShipToBroker();
      item.shipToBrokerId = ship.shipToBrokerId;
      item.listingId = this.product.id;
      item.shipToId = ship.shipToId;
      item.brokerId = ship.brokerId;
      item.broker2Id = ship.broker2Id;
      item.commission = 0;
      item.commission1 = 0;
      item.commission2 = 0;

      switch (ship.process) {
        case 'D':
          //8.6.24 revised 
          // this.shipToBrokerSvc.remove(ship.id).subscribe(data => {
            this.shipToBrokerSvc.remove(ship.shipToBrokerId).subscribe(data => {
            this.monitorUpdate('success');
          }, error => {
            this.monitorUpdate('danger');
          })
          break;  
        case 'U':
          this.shipToBrokerOriginal.push(item);
          this.shipToBrokerSvc.updateBroker(item).subscribe(data => {
            this.monitorUpdate('success');
          }, error => {
              this.monitorUpdate('danger');
            })
          break;  
        case 'C':
          this.shipToBrokerOriginal.push(item);
          this.shipToBrokerSvc.create(item).subscribe(data => {
             const index = this.shipToBrokerOriginal.length - 1;
             this.shipToBrokerOriginal[index].shipToBrokerId = data.shipToBrokerId;
             this.monitorUpdate('success');
            }, error => {
              this.monitorUpdate('danger');
            })
          break;  
      }
    })
  }

  monitorUpdate(flag: string) {
    this.counter++;
    this.alertType = this.alertType == 'danger' ? 'danger' : flag;
    if (this.counter >= this.totalCount) {
      const message = this.alertType == 'danger' ? 'Unable to update' : 'Successful Update';
      this.success.next(message);
      this.shipToBrokerSvc.getList(this.product.id).subscribe(ship => {
        this.shipToBrokerOriginal = ship;
        this.setupShipToBroker(this.product.shipToIds);
      });
    }
  }
  editSetup() {
    this.router.navigate(['/products/setup-edit',  this.product.id]);
  }
  
  editCaseSetup() {
    this.router.navigate(['/products/cases-setup-edit',  this.product.id]);
  }
  
  editPalletSetup() {
    this.router.navigate(['/products/pallet-setup-edit',  this.product.id]);
  } 
  
  editFreightCostSetup() {
    this.router.navigate(['/products/freight-cost-setup-edit',  this.product.id]);
  }  
  editCostingBucketSetup() {
    this.router.navigate(['/products/costing-bucket-setup-edit',  this.product.id]);
  }    
}
