import { OrderItems } from '../models/orderItems.model';
export class Order {
    id: number;
    orderStatus: string;
    billingAgentId: number;
    salesMgrId: number;
    salesOrderNo: string;
    shipmentDate: Date;
    vendorPickupId: number;
    billToId: number;
    shipToId: number;
    brandId: number;
    brokerId: number;
    broker2Id: number;
    cndBorderId: number;
    clearingCompanyId: number;
    deliveredDateAppt: Date;
    freightTypeId: number;
    appConfirmed: string;
    driverName: string;
    driverCell: string;
    etaAtBorder: Date;
    exchangeActual: number;
    exchangeBudget: number;
    invoiceNumber: string;
    lockedDate: Date;
    lockedYesNo: string;
    yearMonthId: number;
    notes: string;
    orderType: string;
    termsId: number;
    terms: string;
    purchaseOrder: string;
    salesOrderDate: Date;
    transportCo1: number;
    transportCo2: number;
    transportCo3: number;
    transportCo4: number;
    transportCo5: number;
    vendorId: number;
    whsid: number;
    shippingLane1: number;
    shippingLane2: number;
    shippingLane3: number;
    shippingLane4: number;
    shippingLane5: number;
    freightType1: string;
    freightType2: string;
    freightType3: string;
    freightType4: string;
    freightType5: string;
    freightCost1: number;
    freightCost2: number;
    freightCost3: number;
    freightCost4: number;
    freightCost5: number;
    freightCostSales1: number;
    freightCostSales2: number;
    freightCostSales3: number;
    freightCostSales4: number;
    freightCostSales5: number;
    freightTotalCost1: number;
    freightTotalCost2: number;
    freightTotalCost3: number;
    freightTotalCost4: number;
    freightTotalCost5: number;
    shipDate1: Date;
    shipDate2: Date;
    shipDate3: Date;
    shipDate4: Date;
    shipDate5: Date;
    deliveryDate1: Date;
    deliveryDate2: Date;
    deliveryDate3: Date;
    deliveryDate4: Date;
    deliveryDate5: Date;
    shippingLaneEnd1: number;
    shippingLaneEnd2: number;
    shippingLaneEnd3: number;
    shippingLaneEnd4: number;
    shippingLaneEnd5: number; 
    palletGrossLbs: number;
    palletCount: number;
}


export const orderInit: Order = {
    id: 0,
    orderStatus: null,
    salesOrderNo: null,
    shipmentDate: null,
    vendorPickupId: null,
    billToId: null,
    shipToId: null,
    brandId: null,
    brokerId: null,
    broker2Id: null,
    cndBorderId: null,
    clearingCompanyId: null,
    deliveredDateAppt: null,
    freightTypeId: null,
    appConfirmed: null,
    driverName: null,
    driverCell: null,
    etaAtBorder: null,
    exchangeActual: null,
    exchangeBudget: null,
    invoiceNumber: null,
    lockedDate: null,
    lockedYesNo: null,
    yearMonthId: null,
    notes: null,
    orderType: null,
    termsId: null,
    terms: null,
    purchaseOrder: null,
    billingAgentId: null,
    salesMgrId: null,
    salesOrderDate: null,
    transportCo1: null,
    transportCo2: null,
    transportCo3: null,
    transportCo4: null,
    transportCo5: null,
    vendorId: null,
    whsid: null,
    shippingLane1: null,
    shippingLane2: null,
    shippingLane3: null,
    shippingLane4: null,
    shippingLane5: null,
    freightType1: null,
    freightType2: null,
    freightType3: null,
    freightType4: null,
    freightType5: null,
    freightCost1:  null,
    freightCost2:  null,
    freightCost3:  null,
    freightCost4:  null,
    freightCost5:  null,
    freightCostSales1:  null,
    freightCostSales2:  null,
    freightCostSales3:  null,
    freightCostSales4:  null,
    freightCostSales5:  null,
    freightTotalCost1:  null,
    freightTotalCost2:  null,
    freightTotalCost3:  null,
    freightTotalCost4:  null,
    freightTotalCost5:  null,
    shipDate1: null,
    shipDate2: null,
    shipDate3: null,
    shipDate4: null,
    shipDate5: null,
    deliveryDate1: null,
    deliveryDate2: null,
    deliveryDate3: null,
    deliveryDate4: null,
    deliveryDate5: null,
    shippingLaneEnd1: null,
    shippingLaneEnd2: null,
    shippingLaneEnd3: null,
    shippingLaneEnd4: null,
    shippingLaneEnd5: null,
    palletGrossLbs: null,
    palletCount: null
}
export class orderPaging {
    orders: Order;
    paging: {
        page: number,
        pageSize: number,
        collectionSize: number
    }

}
export class OrderPDF {
    id: number;
    orderStatus: string;
    orderType: string;
    salesOrderNo: string;
    purchaseOrder: string;
    salesOrderDate: Date;
    shipmentDate: Date;
    deliveredDateAppt: Date;
    billTo: string;
    billToId: number;
    billAddress: string;
    billCSZ: string;
    shipTo: string;
    shipToId: number;
    shipToAddress: string;
    shipToCSZ: string;
    broker: string;
    vendor: string;
    vendorId: number;
    vendorInfo: string;
    salesAmount: string;
    appConfirmed: string;
    notes: string;
    pickCompany: string;
    pickAddress: string;
    pickCSZ: string;
    paymentTerms: string;
    freightType: string;
    location1: string;
    location2: string;
    location3: string;
    location4: string;
    location5: string;
    transCompany1: string;
    transCompany2: string;
    transCompany3: string;
    transCompany4: string;
    transCompany5: string;
    whseCompany: string;
    whseAddress: string;
    whseEst: string;
    whseCSZ: string;
    invoiceNumber: string;
    locationEnd1: string;
    locationEnd2: string;
    locationEnd3: string;
    locationEnd4: string;
    locationEnd5: string;
    shipDate1: Date;
    shipDate2: Date;
    shipDate3: Date;
    shipDate4: Date;
    shipDate5: Date;
    deliveryDate1: Date;
    deliveryDate2: Date;
    deliveryDate3: Date;
    deliveryDate4: Date;
    deliveryDate5: Date;
    palletCount: number;
    palletGrossLbs: number;
  }
  export class OrderSP {
    // "displayName": "VENDOR"
    Vendor1: string;
    // "displayName": "ORDER STATUS",
    Order_x0020_Status: string;
    // "displayName": "ORDER TYPE",
    Order_x0020_Type1: string;
    // "displayName": "SALES ORDER NO",
    Sales_x0020_Order_x0020_No: string;
    // "displayName": "PURCHASE ORDER",
    Purchase_x0020_Order: string;
    // "displayName": "SHIPMENT DATE",
    Shipment_x0020_Date: string;
    // "displayName": "CUSTOMER",
    Customer1: string;
    // "displayName": "SHIP TO",
    Ship_x0020_To: string;
    // "displayName": "BROKER",
    Broker: string;
    // "displayName": "ORDER CODE",
    ORDER_x0020_CODE: string;
    // "displayName": "BRAND.",
    BRAND_x002F_CATEGORY: string;
    // "displayName": "ITEM DESCRIPTION",
    ITEM_x0020_DESCRIPTION: string;
    // "displayName": "SIZE",
    SIZE: string;
    // "displayName": "CASES",
    CASES: string;
    // "displayName": "CASE COST",
    CASE_x0020_COST: string;
    // "displayName": "EXT CASE COST",
    EXT_x0020_CASE_x0020_COST: string;
    // "displayName": "MONTH/YEAR",
    MONTH_x002F_YEAR0: string;
    // "displayName": "CATEGORY.",
    CATEGORY_x002e_: string;
    // "displayName": "CUSTOMER CITY",
    CUSTOMER_x0020_CITY: string;
    // "displayName": "CUSTOMER COUNTRY",
    CUSTOMER_x0020_COUNTRY: string;
    // "displayName": "CUSTOMER STATE",
    CUSTOMER_x0020_STATE: string;
    // "displayName": "CUSTOMER ZIP",
    CUSTOMER_x0020_ZIP: string;
    // "displayName": "SHIP TO CITY",
    SHIP_x0020_TO_x0020_CITY: string;
    // "displayName": "SHIP TO COUNTRY",
    SHIP_x0020_TO_x0020_COUNTRY: string;
    // "displayName": "SHIP TO STATE",
    SHIP_x0020_TO_x0020_STATE: string;
    // "displayName": "SHIP TO ZIP",
    SHIP_x0020_TO_x0020_ZIP: string;
    // "displayName": "DOT LOCATION",
    DOT_x0020_LOCATION: string;
    // "displayName": "YEAR",
    YEAR: string;
    LONGITUDE_x002d_C: string;
    LATITUDE_x002d_C: string;
    LONGITUDE_x002d_S: string;
    LATITUDE_x002d_S: string;
    LONGITUDE_x002d_V: string;
    LATITUDE_x002d_V: string;
    LONGITUDE_x002d_P: string;
    LATITUDE_x002d_P: string
  }