import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Category } from '../../../models/category.model';
import { CategoryService } from '../../../services/category.service';
import { Vendor } from '../../../models/vendor-model';
import { VendorService } from '../../../services/vendor.service';
import { Brand } from '../../../models/brand.model';
import { BrandService } from '../../../services/brand.service';
import { SorterService } from '../../../services/sorter.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-category-edit',
  templateUrl: './category-edit.component.html',
  styleUrls: ['./category-edit.component.scss']
})
export class CategoryEditComponent implements OnInit {
  title = 'Category New';
  newOne = true;
  category: Category;
  categoryForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  vendor: Vendor[] = [];
  brand: Brand[] = [];
  currentVendor = '';
  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private categoryService: CategoryService,
              private brandService: BrandService,
              private sortSvc: SorterService,
              private vendorService: VendorService) { }

  ngOnInit() {
    this.categoryForm = this.fb.group({
      activeItem: [''],
      category: [''],
      brandId: [''],
      vendor: [{ value: '', disabled: true }]
    });

    this.brandService.getAll().subscribe(brand => {
      this.brand = this.sortSvc.sorter(brand, 'brand');
        this.vendorService.getAll().subscribe(data => {
          this.vendor = data;
          this.route.data.subscribe(data2 => {
            if (data2.dataCategory !== undefined) {
              this.title = 'Category Edit';
              this.newOne = false;
              this.category = data2.dataCategory;
              this.fillFormData();
              this.onChanges();
            } else {
              this.title = 'Category New';
              this.newOne = true;
              this.category = new Category();
              this.categoryForm.reset();
              this.onChanges();
            }
          });
        });
      });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  onChanges(): void {

    this.categoryForm.get('brandId').valueChanges.subscribe(val => {
      this.getVendor(val * 1);
    });

  }

  fillFormData() {
    this.categoryForm.setValue({
      activeItem: this.category.activeItem,
      category: this.category.category,
      brandId: this.category.brandId,
      vendor: this.getVendor(this.category.brandId)
    });
  }
  getVendor(id) {
    let ret = '';
    if (id) {
      const brandIndex = this.brand.findIndex(item => item.id === id);
      if (brandIndex >  -1) {
        const vendorId = this.brand[brandIndex].vendorId;
        const index = this.vendor.findIndex(item => item.id === vendorId);
        if (index >  -1) {
          ret = this.vendor[index].company;
        }
      }
    }
    this.currentVendor = ret;
    this.categoryForm.controls.vendor.setValue(this.currentVendor);
    return ret;
  }
  onSubmit() {
    this.category.activeItem = this.categoryForm.controls.activeItem.value;
    this.category.category = this.categoryForm.controls.category.value;
    this.category.brandId = this.categoryForm.controls.brandId.value;
    if (this.newOne) {
      this.categoryService.create(this.category).subscribe(data => {
        this.category.id = data.id;
        this.newOne = false;
        this.title = 'Category Edit';
        this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.categoryService.update(this.category).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }

  onDelete() {
    if (this.category.id) {
      this.categoryService.remove(this.category.id).subscribe(data => {
        this.router.navigate(['/items/category-list']);
      });
    }
  }
}
