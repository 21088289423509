import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipToAddress } from '../../../../models/shipto.model';
import { Location } from '../../../../models/location-model';
import { Customer } from '../../../../models/customer.model';
import { ShipToAddressService } from '../../../../services/ship-to-address.service';
import { CustomerService } from '../../../../services/customer.service';
import { LocationService } from '../../../../services/location.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Region } from 'src/app/models/region-model';
import { RegionService } from 'src/app/services/region.service';
import { TypeOfAccount } from 'src/app/models/type-of-account-model';
import { TypeOfAccountService } from 'src/app/services/type-of-account.service';
import { ShipToRegions } from 'src/app/models/shipto-regions.model';
import { ShipToRegionsService } from 'src/app/services/ship-to-regions.service';

@Component({
  selector: 'app-ship-to-create',
  templateUrl: './ship-to-create.component.html',
  styleUrls: ['./ship-to-create.component.scss']
})
export class ShipToCreateComponent implements OnInit {
  shipTo: ShipToAddress ;
  shipToForm: UntypedFormGroup;
  locations: Location;
  customer: Customer;
  typeOfAcct: TypeOfAccount[];
  region: Region[];
  regionId: ShipToRegions[];
  ships: any;
  counter: number;

  constructor(
    private fb: UntypedFormBuilder,
    private shipToSvc: ShipToAddressService,
    private custSvc: CustomerService,
    private locationSvc: LocationService,
    private typeOfAcctSvc: TypeOfAccountService,
    private regionSvc: RegionService,
    private shipToRegionSvc: ShipToRegionsService,
    private router: Router
  ) {  this.shipTo = new ShipToAddress(); }

  ngOnInit() {
    this.shipToForm = this.fb.group({
      activeItem: [''],
      vendor: [''],
      company: [''],
      address: [''],
      city: [''],
      stateProvince: [''],
      countryRegion: [''],
      zipPostalCode: [''],
      fullName: [''],
      jobTitle: [''],
      businessPhone: [''],
      faxNumber: [''],
      mobileNumber: [''],
      eMail: [''],
      webPage: [''],
      comments: [''],
      fullAddress: [''],
      customerOrderType: [''],
      accountRetailId: [''],
      appointments: [''],
      customerId: [''],
      loadingTime: [''],
      locationId: [''],
      notes: [''],
      latitude: [''],
      longitude: [''],
      accountTypeId: [''],
      regionId: ['']      
    });
    this.ships = [];
    this.custSvc.getAll().subscribe(data => {
      this.customer = data;
      this.locationSvc.getAll().subscribe(data2 => {
        this.locations = data2;
        this.typeOfAcctSvc.getAll().subscribe(typeOfAcct => {
          this.typeOfAcct = typeOfAcct;
          this.regionSvc.getAll().subscribe(region => {
            this.region = region;
            // this.shipToInit();
            // this.shipToForm.reset();
            // this.regionId = [];
            // this.fillFormData();
          });
        });
      });
    });

  }
  onSubmit() {
    this.shipTo.activeItem = this.shipToForm.controls.activeItem.value;
    this.shipTo.company = this.shipToForm.controls.company.value;
    this.shipTo.address = this.shipToForm.controls.address.value;
    this.shipTo.city = this.shipToForm.controls.city.value;
    this.shipTo.stateProvince = this.shipToForm.controls.stateProvince.value;
    this.shipTo.countryRegion = this.shipToForm.controls.countryRegion.value;
    this.shipTo.zipPostalCode = this.shipToForm.controls.zipPostalCode.value;
    this.shipTo.fullName = this.shipToForm.controls.fullName.value;
    this.shipTo.jobTitle = this.shipToForm.controls.jobTitle.value;
    this.shipTo.businessPhone = this.shipToForm.controls.businessPhone.value;
    this.shipTo.faxNumber = this.shipToForm.controls.faxNumber.value;
    this.shipTo.mobileNumber = this.shipToForm.controls.mobileNumber.value;
    this.shipTo.eMail = this.shipToForm.controls.eMail.value;
    this.shipTo.webPage = this.shipToForm.controls.webPage.value;
    this.shipTo.comments = this.shipToForm.controls.comments.value;
    this.shipTo.fullAddress = this.shipToForm.controls.fullAddress.value;
    this.shipTo.customerOrderType = this.shipToForm.controls.customerOrderType.value;
    this.shipTo.appointments = this.shipToForm.controls.appointments.value;
    this.shipTo.customerId = this.shipToForm.controls.customerId.value;
    this.shipTo.loadingTime = this.shipToForm.controls.loadingTime.value;
    this.shipTo.locationId = this.shipToForm.controls.locationId.value;
    this.shipTo.notes = this.shipToForm.controls.notes.value;
    this.shipTo.latitude = this.shipToForm.controls.latitude.value;
    this.shipTo.longitude = this.shipToForm.controls.longitude.value;
    this.shipTo.accountTypeId = this.shipToForm.controls.accountTypeId.value;
    // this.shipTo.regionId = this.shipToForm.controls.regionId.value;
    // this.counter = 0;
    this.shipToSvc.create(this.shipTo).subscribe(data => {
      const id = data.id;
      this.router.navigate(['/customers/ship-to-address-edit', id]);
    }, error => {

    });
  }
  // fillFormData() {
  //   this.shipToForm.setValue({
  //     activeItem: this.shipTo.activeItem,
  //     vendor: this.shipTo.vendor,
  //     company: this.shipTo.company,
  //     address: this.shipTo.address,
  //     city: this.shipTo.city,
  //     stateProvince: this.shipTo.stateProvince,
  //     countryRegion: this.shipTo.countryRegion,
  //     zipPostalCode: this.shipTo.zipPostalCode,
  //     fullName: this.shipTo.fullName,
  //     jobTitle: this.shipTo.jobTitle,
  //     businessPhone: this.shipTo.businessPhone,
  //     faxNumber: this.shipTo.faxNumber,
  //     mobileNumber: this.shipTo.mobileNumber,
  //     eMail: this.shipTo.eMail,
  //     webPage: this.shipTo.webPage,
  //     comments: this.shipTo.comments,
  //     fullAddress: this.shipTo.fullAddress,
  //     customerOrderType: this.shipTo.customerOrderType,
  //     appointments: this.shipTo.appointments,
  //     customerId: this.shipTo.customerId,
  //     loadingTime: this.shipTo.loadingTime,
  //     locationId: this.shipTo.locationId,
  //     notes: this.shipTo.notes,
  //     latitude: this.shipTo.latitude,
  //     longitude: this.shipTo.longitude,
  //     accountTypeId: this.shipTo.accountTypeId,
  //     regionId: this.joinNumbers(),
  //   });
  // }
  // joinNumbers() {
  //   let ret = [];
  //   this.regionId.forEach(r => {
  //     if (r.regionId) {
  //       ret.push(r.regionId)
  //     }
  //   })
  //   return ret;
  // }
  // deleteShipToRegions() {
  //   this.counter++;
  //   if(this.counter > this.regionId.length) {
  //     this.counter = 0;
  //     this.updateShipToRegions();
  //   } else {
  //     const index = this.counter -1;
  //     this.shipToRegionSvc.remove(this.regionId[index].shipToRegionId).subscribe(data => {
  //       this.deleteShipToRegions();
  //     }, error => {
  //     this.deleteShipToRegions();
  //     });
  //   }
  // }
  // updateShipToRegions() {
  //   this.counter++;
  //   if (this.counter <= this.shipToForm.controls.regionId.value.length) {
  //     const index = this.counter -1;
  //     let x = new ShipToRegions();
  //     x.shipToRegionId = 0;
  //     x.shipToId = this.shipTo.id;
  //     x.regionId = this.shipToForm.controls.regionId.value[index];
  //     this.shipToRegionSvc.create(x).subscribe(data => {
  //       this.updateShipToRegions();
  //     });
  //   } else {
  //     this.shipToRegionSvc.getRegion(this.shipTo.id).subscribe(data => {
  //       this.regionId = data;
  //     })
  //   }
  // }
  // shipToInit() {
  //   this.shipTo.id = 0;
  //   this.shipTo.vendor = '';
  //   this.shipTo.company = '';
  //   this.shipTo.address = '';
  //   this.shipTo.city = '';
  //   this.shipTo.stateProvince = '';
  //   this.shipTo.countryRegion = '';
  //   this.shipTo.zipPostalCode = '';
  //   this.shipTo.fullName = '';
  //   this.shipTo.jobTitle = '';
  //   this.shipTo.businessPhone = '';
  //   this.shipTo.faxNumber = '';
  //   this.shipTo.mobileNumber = '';
  //   this.shipTo.eMail = '';
  //   this.shipTo.webPage = '';
  //   this.shipTo.comments = '';
  //   this.shipTo.fullAddress = '';
  //   this.shipTo.customerOrderType
  //   this.shipTo.appointments = '';
  //   this.shipTo.customerId = 0;
  //   this.shipTo.loadingTime = '';
  //   this.shipTo.locationId = 0;
  //   this.shipTo.notes = '';
  //   this.shipTo.latitude = 0;
  //   this.shipTo.longitude = 0;
  //   this.shipTo.accountTypeId = 0;    
  // }
}
