<h5>{{ title }} </h5>
<form [formGroup]="productForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="form-group">
    <div class="col">
      <label>
        Active Listing:
        <input type="text" formControlName="activeListings" class="form-control" >
      </label>

      <label class="ms-3">
        Product Order Code:
        <input type="text" formControlName="productOrderCode" class="form-control" >
      </label>
  </div>

    <div class="col">
      <label>
        Brand:
        <input type="text" formControlName="brand" class="form-control" >
      </label>

      <label class="ms-3">
        Category:
        <input type="text" formControlName="category" class="form-control" >
      </label>
   </div>

    <div class="col">
      <label>
        Item Desciption:
        <input type="text" formControlName="itemDescription" class="form-control" size="60">
      </label>

      <label class="ms-3">
        Size:
        <input type="text" formControlName="size" class="form-control">
      </label>
    </div>
    <div class="col">
      <label>
        TI:
        <input type="text" formControlName="ti" class="form-control">
      </label>

      <label class="ms-3">
        HI:
        <input type="text" formControlName="hi" class="form-control">
      </label>

      <label class="ms-3">
        Type of Pallet:
        <select formControlName="palletTypeId" class="form-control">
          <option *ngFor="let p of palletType" [value]="p.palletTypeId">{{p.palletType1}}</option>
         </select>
      </label>
    </div>

    <div class="col">
        <label>
            CS Per Pallet:
          <input type="text" formControlName="csPerPallet" class="form-control" size="60">
        </label>

        <label class="ms-3">
            Pallet Per Truck:
          <input type="text" formControlName="palletPerTruck" class="form-control">
        </label>

        <label class="ms-3">
            Case Per Truck:
          <input type="text" formControlName="csPerTruck" class="form-control">
        </label>
    </div>

    <button type="submit" class="btn btn-primary mt-3 w-25">Save</button>
    <br>
    <button type="button" class="btn btn-secondary mt-3 w-25" (click)="editSetup()">Edit Setup</button>
    <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="editCaseSetup()">Case Setup</button>
    <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="editFreightCostSetup()">Freight Cost Setup</button>
    <br>
    <button type="button" class="btn btn-secondary mt-3 w-25" (click)="editCostingBucketSetup()">Costing Bucket Setup</button>
    <!-- <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="editListingSetup()">Listing Setup</button> -->
  </div>
  <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
</form>
