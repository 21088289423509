import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule} from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { OtherRoutingModule } from './other-routing.module';
import {
  BorderCrossingEditComponent,
  BorderCrossingListComponent,
  BorderCrossingResolver,
  BrokerEditComponent,
  BrokerListComponent,
  BrokerResolver,
  SalesManagerEditComponent,
  SalesManagerListComponent,
  SalesManagerResolver,
  ClearingCompanyEditComponent,
  ClearingCompanyListComponent,
  ClearingCompanyResolver,
  ExchangeRateEditComponent,
  ExchangeRateListComponent,
  ExchangeRateResolver,
  LocationEditComponent,
  LocationListComponent,
  LocationResolver,
  PalletTypeEditComponent,
  PalletTypeListComponent,
  PalletTypeResolver,
  RegionEditComponent,
  RegionListComponent,
  RegionResolver,
  TermsEditComponent,
  TermsListComponent,
  TermsResolver,
  TransportationEditComponent,
  TransportationListComponent,
  TransportationResolver,
  TypeOfAccountEditComponent,
  TypeOfAccountListComponent,
  TypeOfAccountResolver,
  WarehouseDetailComponent,
  WarehouseListComponent,
  WarehouseResolver,
  WeekCustomerListComponent,
  WeekCustomerEditComponent,
  WeekCustomerNewComponent,
  WeekCustomerEndListComponent,
  WeekCustomerEndNewComponent,
  WeekCustomerEndEditComponent,
  WeekCustomerResolver,
  WeekCustomerHeadsResolver,
  WeekKalcorEditComponent,
  WeekKalcorListComponent,
  WeekKalcorResolver,
  CreditReasonsResolver


} from './index';
import { CreditReasonsListComponent } from './credit-reasons/credit-reasons-list/credit-reasons-list.component';
import { CreditReasonsEditComponent } from './credit-reasons/credit-reasons-edit/credit-reasons-edit.component';
import { BillingAgentEditComponent } from './billing-agent/billing-agent-edit/billing-agent-edit.component';
import { BillingAgentListComponent } from './billing-agent/billing-agent-list/billing-agent-list.component';
import { BillingAgentResolver } from './billing-agent';



@NgModule({
  declarations: [
    BorderCrossingEditComponent,
    BorderCrossingListComponent,
    BrokerEditComponent,
    BrokerListComponent,
    SalesManagerEditComponent,
    SalesManagerListComponent,
    ClearingCompanyEditComponent,
    ClearingCompanyListComponent,
    TransportationEditComponent,
    TransportationListComponent,
    WarehouseDetailComponent,
    WarehouseListComponent,
    LocationEditComponent,
    LocationListComponent,
    TermsEditComponent,
    TermsListComponent,
    PalletTypeListComponent,
    PalletTypeEditComponent,
    ExchangeRateEditComponent,
    ExchangeRateListComponent,
    RegionListComponent,
    RegionEditComponent,
    TypeOfAccountListComponent,
    TypeOfAccountEditComponent,
    WeekCustomerListComponent,
    WeekCustomerEditComponent,
    WeekCustomerEndListComponent,
    WeekCustomerEndEditComponent,
    WeekCustomerNewComponent,
    WeekCustomerEndNewComponent,
    WeekKalcorListComponent,
    WeekKalcorEditComponent,
    CreditReasonsListComponent,
    CreditReasonsEditComponent,
    BillingAgentEditComponent,
    BillingAgentListComponent,
    

  ],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    OtherRoutingModule,
    FormsModule
  ],
  providers: [
    BrokerResolver,
    SalesManagerResolver,
    TransportationResolver,
    WarehouseResolver,
    ClearingCompanyResolver,
    TermsResolver,
    LocationResolver,
    BorderCrossingResolver,
    PalletTypeResolver,
    ExchangeRateResolver,
    TypeOfAccountResolver,
    RegionResolver,
    WeekCustomerResolver,
    WeekCustomerHeadsResolver,
    WeekKalcorResolver,
    CreditReasonsResolver,
    BillingAgentResolver

  ]  
})
export class OtherModule { }
