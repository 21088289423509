<h4>Cases Setup List </h4>
<h5>Filter</h5>
<div class="col-lg-44">

  <label>
    Brand:
    <select [(ngModel)]="brand" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let b of brandFilter" [value]="b">{{ b }}</option>
    </select>
  </label>

  <label class="ms-3">
    Category:
    <select [(ngModel)]="category" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let c of categoryFilter" [value]="c">{{ c }}</option>
    </select>
  </label>

  <label class="ms-3">
    Type of Costing:
    <select [(ngModel)]="costing" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let t of costingFilter" [value]="t">{{ t }}</option>
    </select>
  </label>

  <label class="ms-3">
    Cost Type:
    <select [(ngModel)]="costType" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let t of costTypeFilter" [value]="t">{{ t }}</option>
    </select>
  </label>

</div>

<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">Type of Costing</th>
    <th scope="col">Cost Type</th>
    <th scope="col">Product Order Code</th>
    <th scope="col">Brand</th>
    <th scope="col">Category</th>
    <th scope="col">Item Description</th>
    <th scope="col">Size</th>
    </tr>
  </thead>
  <tbody>
  <tr *ngFor="let p of products | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize" (click)="onRowClicked(p)">
    <td>{{ p.activeListings }}</td>
    <td>{{ p.costType }}</td>
    <td>{{ p.orderCode }}</td>
    <td>{{ p.brand }}</td>
    <td>{{ p.category }}</td>
    <td>{{ p.itemDescription }}</td>
    <td>{{ p.size }}</td>
  </tr>
  <div *ngIf="products!==undefined">
  <ngb-pagination
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="products?.length"></ngb-pagination>
  </div>
  </tbody>
</table>
