import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { OrderService } from '../../services/order.service';
import { OrderItemsService } from '../../services/order-items.service';
import { Router, ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { Order } from 'src/app/models/order-model';
import * as jsPDF from 'jspdf';
import * as html2pdf from 'html2pdf.js';
import { Big } from 'big.js';
import { Term } from '../../models/term-model';
import { TermsService } from '../../services/terms.service';
import { Subject, fromEventPattern, Subscription, throwError } from 'rxjs';
import { Sorter2Service } from '../../services/sorter2.service';


@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {
  orders: any;
  orderSelected: any;
  orderItems: any;
  page = 1;
  pageSize = 25;
  collectionSize = 0;
  type: string;
  orderStatusOptions = [];
  orderNumberOptions = [];
  purchaseOrderOptions = [];
  billToOptions = [];
  shipToOptions = [];
  vendorOptions = [];
  orderTypeOptions = ['Stock', 'Retail', 'Food Service', 'Both Retail/Food Service'];
  orderStatusSelected = '';
  orderTypeSelected = '';
  orderNumberSelected = 0;
  purchaseOrderSelected = 0;
  billToSelected = 0;
  shipToSelected = 0;
  vendorSelected = 0;
  totalSalesAmount = 0;
  totalSalesAmountShipped = 0;
  totalSalesAmountInvoiced = 0;
  totalSalesAmountClosed = 0;
  totalSalesAmountSalesOrders = 0;
  filterSelections = [
    {Name: 'Status', Active: false, order: 0, reload: true },
    {Name: 'Number', Active: false, order: 0, reload: true},
    {Name: 'Purchase', Active: false, order: 0, reload: true},
    {Name: 'Bill', Active: false, order: 0, reload: true},
    {Name: 'Ship', Active: false, order: 0, reload: true},
    {Name: 'vend', Active: false, order: 0, reload: true}];
  sortOrder = [];
  sortItemsCount = 0;
  statusIndex = 0;
  numberIndex = 1;
  purchaseindex = 2;
  billIndex = 3;
  shipIndex = 4;
  vendorIndex = 5;
  title = '';
  reportName = '';
  reportNames = [
    {
    Name: 'Sales Order', 
    Id: 'my-sales-order',
    filename: 'Sales Order',
    Orientation: 'landscape',
    TopMargin: .1,
    LRMargin: .6
    },
    {
      Name: 'Packing Slip', 
      Id: 'my-packing-slip',
      filename: 'Packing Slip',
//      Orientation: 'portrait',
      Orientation: 'landscape',
      TopMargin: .6,
      LRMargin: .3
    },
    {
      Name: 'Shipping', 
      Id: 'my-shipping',
      filename: 'Shipping',
      Orientation: 'landscape',
      TopMargin: .1,
      LRMargin: .6
    },
    {
      Name: 'Commercial Invoice', 
      Id: 'my-commercial-invoice',
      filename: 'Commercial Invoice',
      Orientation: 'landscape',
      TopMargin: .1,
      LRMargin: .6
      },    
      {
        Name: 'Vendor Sales Order', 
        Id: 'my-sales-order-vendor',
        filename: 'Vendor Sales Order',
        Orientation: 'landscape',
        TopMargin: .1,
        LRMargin: .6
      },
      {
        Name: 'Invoice', 
        Id: 'my-invoicing',
        filename: 'Invoice',
        Orientation: 'landscape',
        TopMargin: .1,
        LRMargin: .6
      }        
  ];
  currentIndex: number;
  totalCases: number;
  totalPallets: number;
  totalGrossLbs: number;
  totalCaseCost: number;
  totalGrosskg: number;
  term: Term[];
  salesOrderPrintBtn = [];
  slipPrintBtn = [];
  shipPrintBtn = [];
  invoicePrintBtn = [];
  poPrintBtn = [];
  vendorPurchaseCaseCost = true;
  fOBNetVendorCost = false;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;
  model = {
    left: true,
    middle: false,
    right: false
  };
  constructor(private router: Router,
    private orderService: OrderService,
    private orderItemService: OrderItemsService,
    private checkRef: ChangeDetectorRef,
    private termService: TermsService,
    private activatedRoute: ActivatedRoute,
    private sort2Svc: Sorter2Service) { }

  ngOnInit() {
    this.termService.getAll().subscribe(trm => this.term = trm);
    this.type = 'Order';
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['type'] !== undefined) {
        this.type = params['type'];
      }
      this.getDefaultDataScreen('Sales Order');
    });
  }


  onRowClicked(order) {
    if (this.type == 'Order') {
      this.router.navigate(['/sales/order-edit',  order.id]);
    }
    if (this.type == 'vendor') {
      this.router.navigate(['/sales/vendor-sales-order',  order.id]);
    }
  }
  displayDate(dt, t) {
    
    if (dt) {
      if (t === undefined) {
        t = '';
      }
      let x = new Date(dt);
      if (t == 'T') {
        return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
      }
      return x.toLocaleDateString();
    } 
    return dt

  }
  resetFilters() {
    if (this.purchaseOrderSelected || this.orderNumberSelected) {
      const selected = this.purchaseOrderSelected ? this.purchaseOrderSelected : this.orderNumberSelected;
      // this.orderService.getOneList(selected).subscribe(data => {
      this.orderService.getFilteredList(selected, '', '', 0, 0, 0, this.page, this.pageSize).subscribe(data => {
        this.page = 1;
        this.collectionSize = 1;
        this.orders = data.order;
        this.buttonEnabler();
        this.getSales();
      })
      return;
    }

    if (this.orderStatusSelected || this.billToSelected || this.shipToSelected || this.vendorSelected) {
      let billTo = 0;
      let shipTo = 0;
      let vendor = 0;
      if (this.billToSelected) {
        billTo = this.billToSelected;
        this.setUpOrder(this.billIndex);
      }
      
      if (this.shipToSelected) {
        shipTo = this.shipToSelected;
        this.setUpOrder(this.shipIndex);
      } 

      if (this.orderStatusSelected) {
        this.setUpOrder(this.statusIndex);        
      }
      if (this.vendorSelected) {
        vendor = this.vendorSelected;
        this.setUpOrder(this.vendorIndex);
      }
       this.orderService.getFilteredList(0, this.orderStatusSelected, this.orderTypeSelected, billTo, shipTo, vendor, this.page, this.pageSize).subscribe(data => {
          this.orders = data.order;
          this.collectionSize = data.count;
          this.getSales();
          this.buttonEnabler();
          if (this.filterSelections[this.statusIndex].order == 0) {
            this.orderService.getStatusOptions(this.orderStatusSelected, billTo, shipTo, vendor).subscribe(status => this.orderStatusOptions = status);
          }
          this.orderService.getOrderNumberOptions(this.orderStatusSelected, billTo, shipTo, vendor).subscribe(orderNumber => this.orderNumberOptions = orderNumber);
          this.orderService.getPurchaseOrderOptions(this.orderStatusSelected, billTo, shipTo, vendor).subscribe(purchase => this.purchaseOrderOptions = purchase);

          if (this.filterSelections[this.billIndex].order == 0) {
            this.orderService.getBillToOptions(this.orderStatusSelected, billTo, shipTo, vendor).subscribe(bill => this.billToOptions = bill);
          }
          if (this.filterSelections[this.shipIndex].order == 0) {
            this.orderService.getShipToOptions(this.orderStatusSelected, billTo, shipTo, vendor).subscribe(ship => this.shipToOptions = ship);
          }
          if (this.filterSelections[this.vendorIndex].order == 0) {
            this.orderService.getVendorOptions(this.orderStatusSelected, billTo, shipTo, vendor).subscribe(vendor => this.vendorOptions = vendor);
          }
        })
       return;
    }
    
    this.getDefaultDataScreen('');
  }


  
  getDefaultDataScreen(option) {
  this.orderStatusSelected = option;
  this.orderNumberSelected = 0;
  this.purchaseOrderSelected = 0;
  this.billToSelected = 0;
  this.shipToSelected = 0;
  this.vendorSelected = 0;
  this.sortOrder = ['Status'];
      
  this.orderService.getFilteredList(0, this.orderStatusSelected, this.orderTypeSelected, 0, 0, 0, this.page, this.pageSize).subscribe( order => {
      this.orders = order.order;
      this.collectionSize = order.count;
      this.getSales();
      this.buttonEnabler();
  },error => {
    this.successMessage = 'Unable to process request';
    this.alertType = "danger";
  });
  this.orderService.getStatusOptions('', 0, 0, 0).subscribe(status => this.orderStatusOptions = status);
  this.orderService.getOrderNumberOptions(this.orderStatusSelected, 0, 0, 0).subscribe(orderNumber => this.orderNumberOptions = orderNumber);
  this.orderService.getPurchaseOrderOptions(this.orderStatusSelected, 0, 0, 0).subscribe(purchase => this.purchaseOrderOptions = purchase);
  this.orderService.getBillToOptions(this.orderStatusSelected, 0, 0, 0).subscribe(bill => this.billToOptions = bill);
  this.orderService.getShipToOptions(this.orderStatusSelected, 0, 0, 0).subscribe(ship => this.shipToOptions = ship);
  this.orderService.getVendorOptions(this.orderStatusSelected, 0, 0, 0).subscribe(vendor => this.vendorOptions = vendor);
  }

  loadPage($event) {
    this.resetFilters();
  }
  setUpOrder(itemIndex) {
    if (this.filterSelections[itemIndex].order == 0) {
        this.sortItemsCount++;
        this.filterSelections[itemIndex].order = this.sortItemsCount;
    }
  }
  getSales() {
    this.totalSalesAmountShipped = 0;
    this.totalSalesAmountInvoiced = 0;
    this.totalSalesAmountClosed = 0;
    this.totalSalesAmountSalesOrders = 0;
    this.totalSalesAmount = 0;
    this.orders.forEach(element => {
      this.orderService.getOneSum(element.id).subscribe(data => {
        if (data.length > 0) {
         const orderIndex = this.orders.findIndex(o => o.salesOrderNo.trim() == data[0].salesOrderNo);
         if (orderIndex > -1) {
          switch (this.orders[orderIndex].orderStatus) {
            case 'Shipped':
              this.totalSalesAmountShipped += data[0].salesAmountShipped;
              this.orders[orderIndex].salesAmount = data[0].salesAmountShipped;
              break;
            case 'Invoiced':
              this.totalSalesAmountInvoiced += data[0].salesAmountInvoiced;
              this.orders[orderIndex].salesAmount = data[0].salesAmountInvoiced;
              break;
            case 'Closed':
              this.totalSalesAmountClosed += data[0].salesAmountInvoiced;
              this.orders[orderIndex].salesAmount = data[0].salesAmountInvoiced;
              break;
            case 'Sales Order':
              this.totalSalesAmountSalesOrders += data[0].salesAmountOrder;
              this.orders[orderIndex].salesAmount = data[0].salesAmountOrder;
            break;
          }
         }
        }
        switch (this.orderStatusSelected) {
          case 'Shipped':
            this.totalSalesAmount = this.totalSalesAmountShipped;
            break;
          case 'Invoiced':
            this.totalSalesAmount = this.totalSalesAmountInvoiced;
            break;
          case 'Closed':
            this.totalSalesAmount = this.totalSalesAmountClosed;
            break;
            case 'Sales Order':
              this.totalSalesAmount = this.totalSalesAmountSalesOrders;
              break;
          default:
            this.totalSalesAmount = this.totalSalesAmountSalesOrders + this.totalSalesAmountShipped + this.totalSalesAmountInvoiced + this.totalSalesAmountClosed;
            break;
      }
      })
    });
}

isEmpty(str) {
    return (!str || 0 === str.length);
  }
  printProcess(report, ord) {
    this.orderSelected = ord;
    if (this.isEmpty(this.orderSelected.whseEst)) {
      this.orderSelected.whseEst = '';
    } else {
      if (ord.whseEst.indexOf('Est. ') == -1 ) {
        this.orderSelected.whseEst = 'Est. ' + ord.whseEst;
      } 
    }
    this.currentIndex = this.reportNames.findIndex(r => r.Name == report);
    this.orderItemService.getBySalesNumber(this.orderSelected.salesOrderNo).subscribe(data => {
      this.orderItems = this.sort2Svc.sorter(data, 'category', 'orderCode');
      this.orderItems = this.orderItems.filter(item => item.cases);
      
      if (this.reportNames[this.currentIndex].Name == 'Vendor Sales Order') {
        this.orderSelected.paymentTerms = '';
        if (this.orderSelected.vendorTermsId)  {
          const vendTermIndex = this.term.findIndex(trm => trm.id == this.orderSelected.vendorTermsId);
          if (vendTermIndex > -1) {
            this.orderSelected.paymentTerms = this.term[vendTermIndex].paymentTerms;
          }
        }

        if (this.orderItems.length > 0) {
          if (this.orderSelected.orderStatus == 'Shipped' || this.orderSelected.orderStatus == 'Invoiced' || this.orderSelected.orderStatus == 'Closed') {
            this.orderItems = this.orderItems.filter(item => item.shipped);
          } else {
            this.orderItems = this.orderItems.filter(item => item.cases);
          }
          this.orderItems.forEach(element => {
            if (this.vendorPurchaseCaseCost) {
              element.caseCost = element.purchaseCaseCost;
            } else {
              element.caseCost = element.purchaseCaseCost - element.clearingCs - element.freightCs - element.duty - element.royalty;
            }
          });
        }
      }
      if (this.reportNames[this.currentIndex].Name == 'Invoice') {
        this.orderSelected.paymentTerms = 'Due on Receipt';
      }
  

      this.calculateTotals();
      this.title = this.reportNames[this.currentIndex].Name;
      this.reportName = this.reportNames[this.currentIndex].Name;
      this.checkRef.detectChanges();
      var element = document.getElementById(this.reportNames[this.currentIndex].Id);
      var opt = {
        margin:       [this.reportNames[this.currentIndex].TopMargin, this.reportNames[this.currentIndex].LRMargin],
        filename:     this.orderSelected.salesOrderNo.trim() + ' ' + this.reportNames[this.currentIndex].filename + '.pdf',
        image:        { type: 'jpeg', quality: 0.98 },
        html2canvas:  { scale: 2 },
        jsPDF:        { unit: 'in', format: 'letter', orientation: this.reportNames[this.currentIndex].Orientation }
      };
      html2pdf().set(opt).from(element).save();
      this.reportName = '';
    },error => {
      this.successMessage = 'Unable to process request';
      this.alertType = "danger";
    })
  }
  calculateTotals() {
    this.totalCases = 0;
    this.totalPallets = this.orderSelected.palletCount === null ? 0 : this.orderSelected.palletCount;
    this.totalGrossLbs = 0;
    this.totalCaseCost = 0;
    this.totalGrosskg = 0;
      this.orderItems.forEach(element => {

      let cases = element.cases;
      switch(this.reportNames[this.currentIndex].Name) {
        case 'Commercial Invoice':
          cases = element.shipped;
          element.cases = element.shipped;
          break;
        case 'Vendor Sales Order':
          if (this.orderSelected.orderStatus == 'Shipped' || this.orderSelected.orderStatus == 'Invoiced' || this.orderSelected.orderStatus == 'Closed') {
            cases = element.shipped;
            element.cases = element.shipped;
          }
          break;
        case 'Invoice':
          if (this.orderSelected.orderStatus == 'Shipped' || this.orderSelected.orderStatus == 'Invoiced' || this.orderSelected.orderStatus == 'Closed') {
            cases = element.shipped;
            element.cases = element.shipped;
          }
          break;
        case 'Shipping':
          cases = element.shipped;
          break;
      }

      if (cases === null) {
        cases = 0;
      }
      if (cases) {
          cases = cases * 1;
      }
      if (isNaN(cases)) {
        cases = 0;
      }      
      let caseCost = 0;
      let extCaseCost = 0;
      if (element.caseCost) {
        caseCost = element.caseCost * 1;
      }
      extCaseCost = cases * caseCost;
      element.extCaseCost = extCaseCost;
      this.totalCases += cases;
      // if (element.csPerPallet != 0) {
      //   const bigNum: any = Big(cases)
      //   .div(element.csPerPallet)
      //   .round(0, 3);
      //   let pallets = bigNum * 1;
      //   if (pallets == 0 && cases !=0 ) {
      //     pallets = 1;
      //   }
      //   this.totalPallets += pallets;
      // }
      this.totalCaseCost += extCaseCost;
      this.totalGrossLbs += element.csGrossLbs * cases;
      this.totalGrosskg += element.csGrossKg * cases;
    });
    const palletLbs = this.orderSelected.palletGrossLbs === null ? 0 : this.orderSelected.palletGrossLbs;
    const bigNum: any = Big(palletLbs)
      .times(.453592)
      .round(2);
    const palletKg = bigNum * 1;
    this.totalGrossLbs += this.totalPallets * palletLbs;
    this.totalGrosskg += this.totalPallets * palletKg;
  }
  buttonEnabler() {
    this.salesOrderPrintBtn = [];
    this.slipPrintBtn = [];
    this.shipPrintBtn = [];
    this.invoicePrintBtn = [];
    this.poPrintBtn = [];

    this.orders.forEach(element => {
      let salesOrderPrintBtn = false;
      let slipPrintBtn = false;
      let shipPrintBtn = false;
      let invoicePrintBtn = false;
      let poPrintBtn = false;
      if (element.orderStatus) {
        salesOrderPrintBtn = true;
        slipPrintBtn = true;
        poPrintBtn = true;
        if (element.orderStatus != 'Sales Order') {
          shipPrintBtn = true;
        }
        if (element.invoiceNumber) {
          invoicePrintBtn = true;
        }
      }
      this.salesOrderPrintBtn.push(salesOrderPrintBtn);
      this.slipPrintBtn.push(slipPrintBtn);
      this.shipPrintBtn.push(shipPrintBtn);
      this.invoicePrintBtn.push(invoicePrintBtn);
      this.poPrintBtn.push(poPrintBtn);
    });
  }
  showDialog(o) {  
    this.orderSelected = o;
    this.reportName='Confirm';
    this.checkRef.detectChanges();
  }  
  returnDialog(result){
    this.vendorPurchaseCaseCost = result == 1 ? true : false;
    this.fOBNetVendorCost = result == 2 ? true : false;
    this.printProcess("Vendor Sales Order", this.orderSelected); 
  }

}
