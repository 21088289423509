<h5>Listing Setup List </h5>
<div class="col-lg-44">
<label class="ms-3">
  Vendor:
  <select [(ngModel)]="vendorSelected" (change)="resetFilters()">
    <option [value]="0"></option>
    <option *ngFor="let o of vendors" [value]="o.vendorId">{{ o.vendor }}</option>
  </select>
  Type of Costing:
  <select [(ngModel)]="activeListingsSelected" (change)="resetFilters()">
    <option [value]=""></option>
    <option *ngFor="let o of activeListingsOptions" [value]="o">{{ o }}</option>
  </select>
  Order Code:
  <select [(ngModel)]="orderCodeSelected" (change)="resetFilters()">
    <option [value]="0"></option>
    <option *ngFor="let o of orderCodes" [value]="o.productOrderCodeId">{{ o.orderCode }}</option>
  </select>
  Brand:
  <select [(ngModel)]="brandSelected" (change)="resetFilters()">
    <option [value]="0"></option>
    <option *ngFor="let o of brands" [value]="o.brandId">{{ o.brand }}</option>
  </select>
  <button type="button" class="btn btn-primary mt-0 ms-3" (click)="getDefaultDataScreen()">Reset</button>
</label>
</div>
<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">Active Price List</th>
    <th scope="col">Product Order Code</th>
    <th scope="col">Cost Type</th>
    <th scope="col">Item Description</th>
    <th scope="col">Category</th>
    <th scope="col">Size</th>
    </tr>
  </thead>
  <tbody>
  <tr *ngFor="let p of products | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
    <td>{{ p.activeListings }}</td>
    <td><a class="pointer" (click)="onRowClicked(p)">
      <u class="text-primary">{{ p.orderCode }}</u>
      </a>
      </td>
    <td>{{ p.costType }}</td>
    <td>{{ p.itemDescription }}</td>
    <td>{{ p.category }}</td>
    <td>{{ p.size }}</td>
  </tr>
  </tbody>
</table>
<div *ngIf="products!==undefined">
  <ngb-pagination
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="products?.length"></ngb-pagination>
  </div>
  