<h4>Product Setup List</h4>
<h5>Filter</h5>
<div class="col-lg-44">

  <label>
    Brand:
    <select [(ngModel)]="brand" (change)="resetFilters()">
      <option [ngValue]=""></option>
      <option *ngFor="let b of brandFilter" [ngValue]="b.brandId">{{ b.brand }}</option>
    </select>
  </label>

  <label class="ms-3">
    Category:
    <select [(ngModel)]="category" (change)="resetFilters()">
        <option [ngValue]=""></option>
        <option *ngFor="let c of categoryFilter" [ngValue]="c.categoryId">{{ c.category }}</option>
    </select>
  </label>

  <label class="ms-3">
    Type of Costing:
    <select [(ngModel)]="costing" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let t of costingFilter" [value]="t">{{ t }}</option>
    </select>
  </label>

  <label class="ms-3">
    Cost Type:
    <select [(ngModel)]="costType" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let t of costTypeFilter" [value]="t">{{ t }}</option>
    </select>
  </label>
  <button type="button" class="btn btn-primary mt-0 ms-3" (click)="getDefaultDataScreen()">Reset</button>

</div>

<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">Type of Costing</th>
    <th scope="col">Cost Type</th>
    <th scope="col">Product Order Code</th>
    <th scope="col">UPC</th>
    <th scope="col">GTN-14</th>
    <th scope="col">Product Type</th>
    <th scope="col">Order Type</th>
    <th scope="col">Brand</th>
    <th scope="col">Category</th>
    <th scope="col">Item Description</th>
    <th scope="col">Size</th>
    </tr>
  </thead>
  <tbody>
  <tr *ngFor="let p of products">
    <td>{{ p.activeListings }}</td>
    <td>{{ p.costType }}</td>
    <td>
      <a (click)="onRowClicked(p)" class="pointer">
        <u class="text-primary">{{ p.orderCode }}</u>
      </a>
    </td>
    <td>{{ p.upc }}</td>
    <td>{{ p.gtn }}</td>
    <td>{{ p.productType }}</td>
    <td>{{ p.orderType }}</td>
    <td>{{ p.brand }}</td>
    <td>{{ p.category }}</td>
    <td>{{ p.itemDescription }}</td>
    <td>{{ p.size }}</td>
  </tr>
  <div *ngIf="products!==undefined">
      <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="collectionSize" [maxSize]="5"
        (pageChange)="loadPage($event)">
      </ngb-pagination>
  </div>
  <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
      
    <input class="btn-check" type="radio" name="radio1" id="choice1" [(ngModel)]="pageSize"  (change)="loadPage($event)" autocomplete="off" checked [value]="25">
    <label class="btn btn-outline-primary" for="choice1">
      25
    </label>
  
    <input class="btn-check" type="radio" name="radio1" id="choice2" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="50" autocomplete="off">
    <label class="btn btn-outline-primary" for="choice2">
      50
    </label>
  
    <input class="btn-check" type="radio" name="radio1" id="choice3" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="100" autocomplete="off">
    <label class="btn btn-outline-primary" for="choice3">
      100
    </label>
  
  </div>
  </tbody>
</table>
