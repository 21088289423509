<h3>{{ title }}</h3>

<table class="table"  style="width: 50%;">
    <tr class="g-0">
        <th style="width: 10%; border: none;"></th>
        <th style="width: 30%; border: none;"></th>
        <th style="width: 10%; border: none;"></th>
    </tr>
    <tr>
        <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">Year:</td>
        <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0"><input class="form-control" [(ngModel)]="year" (ngModelChange)="yearChange()" size="5"></td>
        <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0"></td>
    </tr>
    <tr>
        <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">Vendor:</td>
        <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0"><select class="form-control" multiple [(ngModel)]="vendorIds" size="10">
            <option *ngFor="let v of vendors" [value]="v.id">{{ v.company }}</option>
          </select>
        </td>
        <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0"></td>
    </tr>
    <tr>
        <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">Period:</td>
        <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0"><select class="form-control" [(ngModel)]="periodSelected" (ngModelChange)="periodChange($event)">
            <option [value]=""></option>
            <option *ngFor="let p of periodOptions">{{ p.period }}</option>
          </select>
        </td>
        <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0"></td>
    </tr>
    <tr>
        <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">Start Date:</td>
        <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ periodStartDate }}</td>
        <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0"></td>
    </tr>
    <tr>
        <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">End Date:</td>
        <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">{{ periodEndDate }}</td>
        <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0"></td>
    </tr>
    <tr>
        <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">Order Type:</td>
        <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0"><select class="form-control" [(ngModel)]="orderTypeSelected">
            <option [value]=""></option>
            <option *ngFor="let o of orderTypeOptions" [value]="o">{{ o }}</option>
          </select>
        </td>
        <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0"></td>
    </tr>
    <tr>
        <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0">Billing Agent:</td>
        <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0"><select class="form-control" [(ngModel)]="billingAgentSelected">
            <option [value]="0">All</option>
            <option *ngFor="let b of billingAgents" [value]="b.id">{{ b.billingAgentName }}</option>
          </select>
        </td>
        <td class="border border-top-0 border-bottom-0 border-start-0 border-end-0"></td>
    </tr>
</table>

<div class="row pb-2">
    <div class="col-lg-14">
        <button *ngIf="newOne" type="button" class="btn btn-primary mt-3 w-5" (click)="validate()">Run</button>
        <button *ngIf="alertType=='info'" type="button" class="btn btn-primary mt-3 w-5" disabled>
            <span class="spinner-border spinner-border-sm"></span>...Loading</button>
        <button type="button" class="btn btn-secondary ms-3 mt-3 w-5" (click)="reset()">Reset</button>
    </div>  
  </div>

<div>
    <ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage == null">{{ successMessage }}</ngb-alert>
</div>
<table  *ngIf="!newOne" class="table">
    <thead>
        <th colspan="6" class="background text-left text-bold text-white fw-bold h5" >
            SALES
        </th>
    </thead>
    <thead class="border border-start-0 border-end-0 text-bold fw-bold border-dark">
        <th scope="col"></th>
        <th scope="col" class="text-left">Vendor</th>
        <th scope="col" class="text-right">Sales Order</th>
        <th scope="col" class="text-right">Invoiced</th>
        <th scope="col" class="text-right">Closed</th>
        <th scope="col" class="text-right">Total</th>
    </thead>
    <tbody>
        <tr *ngFor="let s of salesData; index as i">
            <td class="text-left" [ngClass]="{'fw-bold gray-out': s.vendor === 'TOTALS'}"></td>
            <td class="text-left" [ngClass]="{'fw-bold gray-out': s.vendor === 'TOTALS'}" >{{ s.vendor }}</td>
            <td class="text-right" [ngClass]="{'fw-bold gray-out': s.vendor === 'TOTALS', 'text-danger': s.salesOrder < 0 }">{{ s.salesOrder | currency }}</td>
            <td class="text-right" [ngClass]="{'fw-bold gray-out': s.vendor === 'TOTALS', 'text-danger': s.salesInvoiced < 0 }">{{ s.salesInvoiced | currency }}</td>
            <td class="text-right" [ngClass]="{'fw-bold gray-out': s.vendor === 'TOTALS', 'text-danger': s.salesClosed < 0 }">{{ s.salesClosed | currency }}</td>
            <td class="text-right" [ngClass]="{'fw-bold gray-out': s.vendor === 'TOTALS', 'text-danger': s.salesAmount < 0 }">{{ s.salesAmount | currency }}</td>
        </tr>
        <tr>
            <td *ngIf="orderTypeSelected!='Stock'" colspan="6" class="background text-left text-bold text-white fw-bold h5">
                BILLING
            </td> 
        </tr>
        <td *ngIf="orderTypeSelected!='Stock'" colspan="6" class="border border-start-0 border-end-0 text-left text-bold fw-bold border-dark">
            KALCOR
        </td>
        <tr *ngFor="let k of kalcorData; index as i">
            <td *ngIf="orderTypeSelected!='Stock'" class="text-left" [ngClass]="{'fw-bold gray-out': k.kalcorVendor === 'TOTALS'}"></td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-left" [ngClass]="{'fw-bold gray-out': k.kalcorVendor === 'TOTALS'}">{{ k.kalcorVendor }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': k.kalcorVendor === 'TOTALS', 'text-danger': k.kalcorOrder < 0 }">{{ k.kalcorOrder | currency }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': k.kalcorVendor === 'TOTALS', 'text-danger': k.kalcorInvoiced < 0 }">{{ k.kalcorInvoiced | currency }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': k.kalcorVendor === 'TOTALS', 'text-danger': k.kalcorClosed < 0 }">{{ k.kalcorClosed | currency }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': k.kalcorVendor === 'TOTALS', 'text-danger': k.kalcorAmount < 0 }">{{ k.kalcorAmount | currency }}</td>
        </tr>
        <td *ngIf="orderTypeSelected!='Stock'" colspan="6" class="border border-start-0 border-end-0 text-left text-bold fw-bold border-dark">
            COMMISSION
        </td>
        <tr *ngFor="let c of commissionData; index as i">
            <td *ngIf="orderTypeSelected!='Stock'" class="text-left" [ngClass]="{'fw-bold gray-out': c.commissionVendor === 'TOTALS'}"></td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-left" [ngClass]="{'fw-bold gray-out': c.commissionVendor === 'TOTALS'}">{{ c.commissionVendor }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': c.commissionVendor === 'TOTALS', 'text-danger': c.commissionOrder < 0 }">{{ c.commissionOrder | currency }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': c.commissionVendor === 'TOTALS', 'text-danger': c.commissionInvoiced < 0 }">{{ c.commissionInvoiced | currency }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': c.commissionVendor === 'TOTALS', 'text-danger': c.commissionClosed < 0 }">{{ c.commissionClosed | currency }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': c.commissionVendor === 'TOTALS', 'text-danger': c.commissionAmount < 0 }">{{ c.commissionAmount | currency }}</td>
        </tr>
        <td *ngIf="orderTypeSelected!='Stock'" colspan="6" class="border border-start-0 border-end-0 text-left text-bold fw-bold border-dark">
            MARKETING
        </td>
        <tr *ngFor="let m of marketingData; index as i">
            <td *ngIf="orderTypeSelected!='Stock'" class="text-left" [ngClass]="{'fw-bold gray-out': m.marketingVendor === 'TOTALS'}"></td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-left" [ngClass]="{'fw-bold gray-out': m.marketingVendor === 'TOTALS'}">{{ m.marketingVendor }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': m.marketingVendor === 'TOTALS', 'text-danger': m.marketingOrder < 0 }">{{ m.marketingOrder | currency }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': m.marketingVendor === 'TOTALS', 'text-danger':  m.marketingInvoiced < 0 }">{{ m.marketingInvoiced | currency }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': m.marketingVendor === 'TOTALS', 'text-danger': m.marketingClosed < 0 }">{{ m.marketingClosed | currency }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': m.marketingVendor === 'TOTALS', 'text-danger': m.marketingAmount < 0 }">{{ m.marketingAmount | currency }}</td>
        </tr>
        <td *ngIf="orderTypeSelected!='Stock'" colspan="6" class="border border-start-0 border-end-0 text-left text-bold fw-bold border-dark">
            CND FREIGHT
        </td>
        <tr *ngFor="let c of cndFreightData; index as i">
            <td *ngIf="orderTypeSelected!='Stock'" class="text-left" [ngClass]="{'fw-bold gray-out': c.cndFreightVendor === 'TOTALS'}"></td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-left" [ngClass]="{'fw-bold gray-out': c.cndFreightVendor === 'TOTALS'}">{{ c.cndFreightVendor }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': c.cndFreightVendor === 'TOTALS', 'text-danger': c.cndFreightOrder < 0 }">{{ c.cndFreightOrder | currency }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': c.cndFreightVendor === 'TOTALS', 'text-danger': c.cndFreightInvoiced < 0 }">{{ c.cndFreightInvoiced | currency }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': c.cndFreightVendor === 'TOTALS', 'text-danger': c.cndFreightClosed < 0 }">{{ c.cndFreightClosed | currency }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': c.cndFreightVendor === 'TOTALS', 'text-danger': c.cndFreightAmount < 0, '(c.cndFreightAmount)': c.cndFreightAmount < 0 }">{{ c.cndFreightAmount | currency }}</td>
        </tr>
        <tr>
            <td *ngIf="orderTypeSelected!='Stock'" colspan="6" class="bg-orange text-left text-bold fw-bold h5">
                TOTAL PERIOD BILLING
            </td> 
        </tr>
        <tr *ngFor="let b of totalBilling; index as i">
            <td *ngIf="orderTypeSelected!='Stock'" class="text-left" [ngClass]="{'fw-bold gray-out': b.billingVendor === 'ALL VENDOR BILLING TOTALS'}"></td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-left" [ngClass]="{'fw-bold gray-out': b.billingVendor === 'ALL VENDOR BILLING TOTALS'}">{{ b.billingVendor }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': b.billingVendor === 'ALL VENDOR BILLING TOTALS', 'text-danger': b.billingOrder < 0 }">{{ b.billingOrder | currency }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': b.billingVendor === 'ALL VENDOR BILLING TOTALS', 'text-danger': b.billingInvoiced < 0 }">{{ b.billingInvoiced | currency }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': b.billingVendor === 'ALL VENDOR BILLING TOTALS', 'text-danger': b.billingClosed < 0 }">{{ b.billingClosed | currency }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': b.billingVendor === 'ALL VENDOR BILLING TOTALS', 'text-danger': b.billingAmount < 0 }">{{ b.billingAmount | currency }}</td>
        </tr>
        <tr>
            <td *ngIf="orderTypeSelected =='Stock'" colspan="6" class="background text-left text-bold text-white fw-bold h5">
                CLEARING
            </td> 
        </tr>
        <tr *ngFor="let c of clearingData; index as i">
            <td *ngIf="orderTypeSelected =='Stock'" class="text-left" [ngClass]="{'fw-bold gray-out': c.clearingVendor === 'TOTALS'}"></td>
            <td *ngIf="orderTypeSelected =='Stock'" class="text-left" [ngClass]="{'fw-bold gray-out': c.clearingVendor === 'TOTALS'}">{{ c.clearingVendor }}</td>
            <td *ngIf="orderTypeSelected =='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': c.clearingVendor === 'TOTALS', 'text-danger': c.clearingOrder < 0 }">{{ c.clearingOrder | currency }}</td>
            <td *ngIf="orderTypeSelected =='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': c.clearingVendor === 'TOTALS', 'text-danger': c.clearingInvoiced < 0 }">{{ c.clearingInvoiced | currency }}</td>
            <td *ngIf="orderTypeSelected =='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': c.clearingVendor === 'TOTALS', 'text-danger': c.clearingClosed < 0 }">{{ c.clearingClosed | currency }}</td>
            <td *ngIf="orderTypeSelected =='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': c.clearingVendor === 'TOTALS', 'text-danger': c.clearingAmount < 0 }">{{ c.clearingAmount | currency }}</td>
        </tr>
        <tr>
            <td *ngIf="orderTypeSelected =='Stock'" colspan="6" class="background text-left text-bold text-white fw-bold h5">
                USD FREIGHT
            </td> 
        </tr>
        <tr *ngFor="let u of usdFreightData; index as i">
            <td *ngIf="orderTypeSelected =='Stock'" class="text-left" [ngClass]="{'fw-bold gray-out': u.usdFreightVendor === 'TOTALS'}"></td>
            <td *ngIf="orderTypeSelected =='Stock'" class="text-left" [ngClass]="{'fw-bold gray-out': u.usdFreightVendor === 'TOTALS'}">{{ u.usdFreightVendor }}</td>
            <td *ngIf="orderTypeSelected =='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': u.usdFreightVendor === 'TOTALS', 'text-danger': u.usdFreightOrder < 0 }">{{ u.usdFreightOrder | currency }}</td>
            <td *ngIf="orderTypeSelected =='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': u.usdFreightVendor === 'TOTALS', 'text-danger': u.usdFreightInvoiced < 0 }">{{ u.usdFreightInvoiced | currency }}</td>
            <td *ngIf="orderTypeSelected =='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': u.usdFreightVendor === 'TOTALS', 'text-danger': u.usdFreightClosed < 0 }">{{ u.usdFreightClosed | currency }}</td>
            <td *ngIf="orderTypeSelected =='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': u.usdFreightVendor === 'TOTALS', 'text-danger': u.usdFreightAmount < 0 }">{{ u.usdFreightAmount | currency }}</td>
        </tr>
        <tr>
            <td colspan="6" class="background text-left text-bold text-white fw-bold h5">
                EXCHANGE
            </td> 
        </tr>
        <tr *ngFor="let e of exchangeData; index as i">
            <td class="text-left" [ngClass]="{'fw-bold gray-out': e.exchangeVendor === 'TOTALS'}"></td>
            <td class="text-left" [ngClass]="{'fw-bold gray-out': e.exchangeVendor === 'TOTALS'}">{{ e.exchangeVendor }}</td>
            <td class="text-right" [ngClass]="{'fw-bold gray-out': e.exchangeVendor === 'TOTALS', 'text-danger': e.exchangeOrder < 0 }">{{ e.exchangeOrder | currency }}</td>
            <td class="text-right" [ngClass]="{'fw-bold gray-out': e.exchangeVendor === 'TOTALS', 'text-danger': e.exchangeInvoiced < 0 }">{{ e.exchangeInvoiced | currency }}</td>
            <td class="text-right" [ngClass]="{'fw-bold gray-out': e.exchangeVendor === 'TOTALS', 'text-danger': e.exchangeClosed < 0 }">{{ e.exchangeClosed | currency }}</td>
            <td class="text-right" [ngClass]="{'fw-bold gray-out': e.exchangeVendor === 'TOTALS', 'text-danger': e.exchangeAmount < 0 }">{{ e.exchangeAmount | currency }}</td>
        </tr>
        <tr>
            <td *ngIf="orderTypeSelected!='Stock'" colspan="6" class="bg-orange text-left text-bold fw-bold h5">
                TOTAL PERIOD BILLING WITH EXCHANGE
            </td> 
        </tr>
        <tr *ngFor="let p of totalPeriod; index as i">
            <td *ngIf="orderTypeSelected!='Stock'" class="text-left" [ngClass]="{'fw-bold gray-out': p.periodVendor === 'TOTAL PERIOD BILLING'}"></td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-left" [ngClass]="{'fw-bold gray-out': p.periodVendor === 'TOTAL PERIOD BILLING'}">{{ p.periodVendor }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': p.periodVendor === 'TOTAL PERIOD BILLING', 'text-danger': p.periodOrder < 0 }">{{ p.periodOrder | currency }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': p.periodVendor === 'TOTAL PERIOD BILLING', 'text-danger': p.periodInvoiced < 0 }">{{ p.periodInvoiced | currency }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': p.periodVendor === 'TOTAL PERIOD BILLING', 'text-danger': p.periodClosed < 0 }">{{ p.periodClosed | currency }}</td>
            <td *ngIf="orderTypeSelected!='Stock'" class="text-right" [ngClass]="{'fw-bold gray-out': p.periodVendor === 'TOTAL PERIOD BILLING', 'text-danger': p.periodAmount < 0 }">{{ p.periodAmount | currency }}</td>
        </tr>
    </tbody>
</table>



