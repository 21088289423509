import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UpcGtnService } from '../../../../services/upc-gtn.service';
import { UpcGtn } from '../../../../models/upc-gtn.model';
import { ProductService } from '../../../../services/product.service';
import { Product, ProductInit, ActiveListings } from 'src/app/models/product.model';
import { BrandService } from '../../../../services/brand.service';
import { Brand } from '../../../../models/brand.model';
import { CategoryService } from '../../../../services/category.service';
import { Category } from '../../../../models/category.model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UtilityService } from '../../../../services/utility.service';
import { Vendor } from 'src/app/models/vendor-model';
import { VendorService } from 'src/app/services/vendor.service';
@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent implements OnInit {
  title = 'Product Setup New';
  newOne = true;
  duplicate = false;
  brands: Brand[];
  categories: Category[];
  categoriesOriginal: Category[];
  product: Product;
  gtn: UpcGtn[];
  productForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  activeList = [];
  alertType = "success";
  vendors: Vendor[];
  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private gtnService: UpcGtnService,
              private brandService: BrandService,
              private categoryService: CategoryService,
              private productService: ProductService,
              private utiityService: UtilityService,
              private vendorService: VendorService
  ) { }

  ngOnInit() {
    this.activeList = ActiveListings;
    this.productForm = this.fb.group({
      activeListings: [''],
      costType: [''],
      productOrderCodeId: ['', Validators.required],
      productType: [''],
      orderType: [''],
      categoryId: [''],
      brandId: [''],
      itemDescription: [''],
      pack: [{ value: 0 }],
      oz: [{ value: 0 }],
      size: [''],
      gm: [{ value: 0, disabled: true }],
      upcLocal: [{ value: '', disabled: true }],
      gtnLocal: [{ value: '', disabled: true }],
      brandLocal: [{ value: '', disabled: true }]
    });
    this.categories = [];

    this.brandService.getAll().subscribe(brand => {
      this.brands = brand;
      this.categoryService.getAll().subscribe(category => {
        this.categoriesOriginal = category;
        this.vendorService.getAll().subscribe(ven => {
          this.vendors = ven;

        // category.forEach(e => {
        //   const index = this.brands.findIndex(x => x.id == e.brandId);
        //   if (index > -1) {
        //     let cat = new Category();
        //     cat.id = e.id;
        //     cat.activeItem = e.activeItem;
        //     cat.brandId = e.brandId;
        //     const brandName = this.brands[index].brand === null ? '' : ' - ' + this.brands[index].brand;
        //     cat.category = e.category === null ? '' : e.category.trim() + brandName;
        //     this.categories.push(cat);
        //   }
        // });
        this.gtnService.getAll().subscribe(gtns => {
          this.gtn = gtns;
          this.route.data.subscribe(data => {
            if (data.dataProductSetup !== undefined) {
              this.title = 'Product Setup Edit';
              this.newOne = false;
              this.product = data.dataProductSetup;
              this.product.activeListings = data.dataProductSetup.activeListings.replace("–","-");
              if (this.product.brandId) {
                this.categories = this.categoriesOriginal.filter(c => c.brandId == this.product.brandId);
                this.productForm.controls.categoryId.setValue(null);
              }
              this.fillFormData();
              this.reCalculate('ALL');
              this.onChanges();
            } else {
              this.title = 'Product Setup New';
              this.newOne = true;
              this.product = ProductInit;
              this.productForm.reset();
              this.onChanges();
            }
          });
        });
      })
      });
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }

  onChanges(): void {

    this.productForm.get('oz').valueChanges.subscribe(val => {
      this.reCalculate('oz');
    });

    this.productForm.get('pack').valueChanges.subscribe(val => {
      this.reCalculate('pack');
    });

    this.productForm.get('productOrderCodeId').valueChanges.subscribe(val => {
      this.onChangeOrderCode(val);
    });
  }

  fillFormData() {
    this.productForm.setValue({
      activeListings: this.product.activeListings,
      costType: this.product.costType,
      productOrderCodeId: this.product.productOrderCodeId,
      productType: this.product.productType,
      orderType: this.product.orderType,
      categoryId: this.product.categoryId,
      brandId: this.product.brandId,
      itemDescription: this.product.itemDescription,
      pack: this.product.pack,
      oz: this.product.oz,
      size: this.product.size,
      upcLocal: '',
      gtnLocal: '',
      brandLocal: '',
      gm: this.product.gm
    });
    this.onChangeOrderCode(this.productForm.controls.productOrderCodeId.value);
  }
  onSubmit() {
    if (this.productForm.invalid) {
      this.success.next(`Please select product order code.`);
      this.alertType = "danger";
      return;
    }
    this.alertType = "success";
    this.updateProduct();
    if (this.newOne || this.duplicate) {
      this.product.id = 0;
      this.productService.createUnique(this.product).subscribe(data => {
        this.success.next(`Successfully added.`);
        this.router.navigate(['/products/setup-edit', data.id]);
      }, error => {
        alert("Unable to Save Duplicate Entry.");
      });
    } else {
      this.productService.update(this.product).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }
  onDelete() {
    if (this.product.id) {
      this.productService.remove(this.product.id).subscribe(data => {
        this.router.navigate(['/products/setup-list']);
      });
    }
  }
  onChangeOrderCode(val) {
    let newUpc = '';
    let newGtn = '';
    let newBrand = '';
    let newBrandId = 0;
    const id = parseInt(val, 10);
    if (!isNaN(id)) {
      const index = this.gtn.findIndex(item => item.id === id);
      if (index > -1) {
        newUpc = this.gtn[index].upc;
        newGtn = this.gtn[index].gtn14;
        newBrandId = this.gtn[index].brandId;
        if (newBrandId) {
          const brandIndex = this.brands.findIndex(item => item.id === newBrandId);          if (brandIndex > -1) {
            newBrand = this.brands[brandIndex].brand;
            this.categories = this.categoriesOriginal.filter(c => c.brandId == newBrandId);
          }
        }
      }
    }
    this.productForm.controls.upcLocal.setValue(newUpc);
    this.productForm.controls.gtnLocal.setValue(newGtn);
    this.productForm.controls.brandLocal.setValue(newBrand);
    this.productForm.controls.brandId.setValue(newBrandId);
  }
  reCalculate(key) {
    this.updateProduct();
    const changedItems = this.utiityService.productCalculations(key, this.product);
    for (const item in changedItems) {
      if (changedItems.hasOwnProperty(item)) {
        if (this.productForm.controls.hasOwnProperty(item)) {
          this.productForm.controls[item].setValue(changedItems[item]);
        }
        this.product[item] = changedItems[item];
      }
    }
  }

  updateProduct() {
    for (const item in this.productForm.controls) {
      if (this.productForm.controls.hasOwnProperty(item)) {
       if (this.product[item] !== undefined) {
          this.product[item] = this.productForm.controls[item].value;
       }
      }
    }
  }
  editCaseSetup() {
    this.router.navigate(['/products/cases-setup-edit',  this.product.id]);
  }
  editPalletSetup() {
    this.router.navigate(['/products/pallet-setup-edit',  this.product.id]);
  }
  editFreightCostSetup() {
    this.router.navigate(['/products/freight-cost-setup-edit',  this.product.id]);
  }  
  editCostingBucketSetup() {
    this.router.navigate(['/products/costing-bucket-setup-edit',  this.product.id]);
  }  
  editListingSetup() {  
    this.router.navigate(['/productListings/listing-setup-edit',  this.product.id]);
  }
  duplicateProduct() {
    this.duplicate = true;
    this.productForm.controls.productOrderCodeId.setValue(0);
  }
}
