import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product, ActiveListings, ProductInit } from 'src/app/models/product.model';
import { ProductService } from '../../../../services/product.service';
import { UpcGtn } from '../../../../models/upc-gtn.model';
import { UpcGtnService } from '../../../../services/upc-gtn.service';


@Component({
  selector: 'app-listing-setup-new',
  templateUrl: './listing-setup-new.component.html',
  styleUrls: ['./listing-setup-new.component.scss']
})
export class ListingSetupNewComponent implements OnInit {
  productsSelected: number;
  products = [];
  productsOriginal = [];
  vendorSelected: number;
  vendors = [];
  activeListingsSelected: string;
  activeListingsOptions = [];
  brandSelected: number;
  brands = [];
  orderCodeSelected: number;
  orderCodes = [];
  page = 1;
  pageSize = 50;
  searchText = '';
  activeList = '';
  upcGtn: UpcGtn[];
  constructor(private route: ActivatedRoute,
    private router: Router,
    private upcGtnService: UpcGtnService,
    private productService: ProductService) { }

  ngOnInit() {
    this.productsSelected = 0;
    this.vendorSelected = 0;
    this.activeListingsSelected = '';
    this.brandSelected = 0;
    this.orderCodeSelected = 0;
    this.activeList = ActiveListings.find( item => item.toLowerCase().indexOf('listing') > -1);
    this.upcGtnService.getAll().subscribe( upc => {
      this.upcGtn = upc;
      this.route.data.subscribe(data => {
        if (data.dataProductSetup !== undefined) {
          this.products = data.dataProductSetup.item;
          this.productsOriginal = data.dataProductSetup.item;
          this.vendors = data.dataProductSetup.item2;
          this.activeListingsOptions = data.dataProductSetup.item3;
          this.brands = data.dataProductSetup.item4;
          this.orderCodes = data.dataProductSetup.item5;
        }
      });    
    })
  }
  onSearchChange(inp: string) {
    inp = inp.toLowerCase();
    this.products = this.productsOriginal.filter(item => item.itemDescription ? item.itemDescription.toLowerCase().indexOf(inp) > -1 : false);
}
  onRowClicked(product) {
    product.id = 0;
    product.activeListings = this.activeList;
    this.productService.create(product).subscribe(data => {
      this.router.navigate(['/productListings/listing-setup-edit',  data.id]);  
    });
  }
  getUpc(id) {
    let ret = '';
    if (id) {
      const index = this.upcGtn.findIndex(item => item.id === id);
      if (index >  -1) {
        ret = this.upcGtn[index].vendorOrderCode;
      }
    }
    return ret;
  }
  resetFilters() {
    this.vendors = [];
    this.activeListingsOptions = [];
    this.orderCodes = [];
    this.brands = [];
    this.productService.getPriceList(
      this.vendorSelected,
      this.activeListingsSelected, 
      this.brandSelected,
      this.orderCodeSelected,
      this.page,
      this.pageSize).subscribe( data => {
        this.products = data.item;
        this.vendors = data.item2;
        this.activeListingsOptions = data.item3;
        this.brands = data.item4;
        this.orderCodes = data.item5;
    })
  }
  getDefaultDataScreen() {
    this.productsSelected = 0;
    this.vendorSelected = 0;
    this.activeListingsSelected = '';
    this.brandSelected = 0;
    this.orderCodeSelected = 0;
    this.page = 1;
    this.resetFilters();
  } 
}