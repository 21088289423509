import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guard/auth.guard';
import { 
  BucketComponent, 
  PortalComponent,
  ExchangeComponent,
  MarketingComponent,
  CommissionComponent,
  VendorComponent,
  RoyaltyComponent,
  StorageComponent,
  SalesComponent,
  SalesOverviewComponent,
  SalesDetailComponent,
  LinkComponent,
  ConnectComponent,
  ConnectBudgetComponent,
  DataLinkListResolver,
  PeriodReportComponent,
  VendorDataComponent,
  AzureResolver,
  AzureLinkComponent,
  MarketingDataComponent,
  MarketingResolver,
  MarketingLinkComponent,
  CommissionLinkComponent,
  CommissionDataComponent,
  KalcorDataComponent,
  KalcorLinkComponent,
  ExchangeDataComponent,
  ExchangeLinkComponent,
  FreightCndDataComponent,
  FreightCndLinkComponent,
  VendorReportDataComponent,
  VendorReportLinkComponent,
  AllDataComponent,
  AllLinkComponent,
  ExhibitAComponent
} from './index';
const routes: Routes = [
  {
    path: 'portal',
    canActivate: [AuthGuard],
    data: { section: 'portal' },
    children: [
      {
        path: 'portal', component: PortalComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'bucket', component: BucketComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'exchange', component: ExchangeComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'marketing', component: MarketingComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'commission', component: CommissionComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'vendor', component: VendorComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'royalty', component: RoyaltyComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'storage', component: StorageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'sales', component: SalesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'sales-detail', component: SalesDetailComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'sales-overview', component: SalesOverviewComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'share-link', component: LinkComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'connect-link/:year', component: ConnectComponent,
        canActivate: [AuthGuard],
        resolve: { dataList: DataLinkListResolver }
      },
      {
        path: 'connect-budget/:year', component: ConnectBudgetComponent,
        canActivate: [AuthGuard],
        resolve: { dataList: DataLinkListResolver }
      },
      {
        path: 'period-report', component: PeriodReportComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'vendor-data/:year', component: VendorDataComponent,
        canActivate: [AuthGuard],
        resolve: { dataList: DataLinkListResolver }
        
      },
      {
        path: 'azure-link', component: AzureLinkComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'marketing-link', component: MarketingLinkComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'marketing-data/:year', component: MarketingDataComponent,
        canActivate: [AuthGuard],
        resolve: { dataList: DataLinkListResolver }
      },
      {
        path: 'commission-link', component: CommissionLinkComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'commission-data/:year', component: CommissionDataComponent,
        canActivate: [AuthGuard],
        resolve: { dataList: DataLinkListResolver }
      },
      {
        path: 'kalcor-link', component: KalcorLinkComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'kalcor-data/:year', component: KalcorDataComponent,
        canActivate: [AuthGuard],
        resolve: { dataList: DataLinkListResolver }
      },
      {
        path: 'exchange-link', component: ExchangeLinkComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'exchange-data/:year', component: ExchangeDataComponent,
        canActivate: [AuthGuard],
        resolve: { dataList: DataLinkListResolver }
      },
      {
        path: 'freight-cnd-link', component: FreightCndLinkComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'freight-cnd-data/:year', component: FreightCndDataComponent,
        canActivate: [AuthGuard],
        resolve: { dataList: DataLinkListResolver }
      },
      {
        path: 'vendor-report-link', component: VendorReportLinkComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'vendor-report-data/:year', component: VendorReportDataComponent,
        canActivate: [AuthGuard],
        resolve: { dataList: DataLinkListResolver }
      },
      {
        path: 'all-link', component: AllLinkComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'all-data/:year', component: AllDataComponent,
        canActivate: [AuthGuard],
        resolve: { dataList: DataLinkListResolver }
      },
      {
        path: 'exhibit-a', component: ExhibitAComponent,
        canActivate: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortalRoutingModule { }
