import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule} from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {NgbModule, NgbActiveModal, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthenticationService } from './services/authentication.service';
import { from } from 'rxjs';
import { ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './register/register.component';
import { HomeComponent } from './home/home.component';
import {
  CustomerListComponent,
  CustomerResolver, CustomerDetailsComponent,
  CustomerCreateComponent
} from './accounting/customers/customer/index';
import {
  ShipToAddressListComponent,
  ShipToAddressEditComponent,
  ShipToCreateComponent,
  ShipToAddressResolver
} from './accounting/customers/ship-to-address/index';
import {
  CustomerEndEditComponent,
  CustomerEndListComponent,
  CustomerEndResolver
} from './accounting/customers/customer-end/index';
import {
  CustomerRetailListComponent,
  CustomerRetailEditComponent,
  CustomerRetailResolver
} from './accounting/customers/customer-retail/index';
import {
  VendorListComponent,
  VendorDetailComponent,
  VendorResolver
} from './accounting/vendors/vendor/index';
import {
  PickupLocationListComponent,
  PickupLocationDetailComponent,
  PickupLocationResolver
} from './accounting/vendors/pickup-location/index';
import {
  BrandListComponent,
  BrandDetailComponent,
  BrandResolver
} from './components/brand/index';
import {
  CategoryListComponent,
  CategoryEditComponent,
  CategoryResolver
} from './components/category/index';
import {
  UpcGtnListComponent,
  UpcGtnEditComponent,
  UpcGtnResolver
} from './components/upc-gtn/index';
import {
  ProductListComponent,
  ProductEditComponent,
  CaseSetupListComponent,
  CaseSetupEditComponent,
  PalletSetupListComponent,
  PalletSetupEditComponent,
  FreightCostSetupListComponent,
  FreightCostSetupEditComponent,
  CostingBucketSetupListComponent,
  CostingBucketSetupEditComponent,
  ListingSetupListComponent,
  ListingSetupEditComponent,
  ListingSetupNewComponent,
  ProductResolver
} from './components/product/index';
import {
  SubCategoryListComponent,
  SubCategoryEditComponent,
  SubCategoryResolver
} from './components/sub-category/index';
import { DashboardShowComponent } from './components/dashboard/dashboard-show/dashboard-show.component';
import { OrderModule } from './sales/order.module';
import {
  BrokerPayListComponent,
  BrokerPayEditComponent,
  BrokerPayResolver,
  CreditEditComponent,
  CreditListComponent,
  CreditResolver,
  TransferEditComponent,
  TransferListComponent,
  TransferResolver
} from './accounting/credit/index';
import {
  ProfitListComponent,
  ProfitLossResolver
} from './accounting/profit-loss/index';
import { ProfitEditComponent } from './accounting/profit-loss/profit-loss-edit/profit-edit/profit-edit.component';
import { ProfitLossComponent } from './accounting/pdf/profit-loss/profit-loss.component';
import { PortalModule } from './portal/portal.module';
import { OtherModule } from './other/other.module';
import { AdminModule } from './admin/admin.module';
import { ConfirmDialogModule } from './components/confirm-dialog/confirm-dialog.module';
import { MsalModule, MsalRedirectComponent, MsalGuard, MsalInterceptor } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../environments/environment';
import { ProjectionsNewComponent } from './projections/projections-new/projections-new.component';
import { ReviewWeekComponent } from './projections/review-week/review-week.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { MatExpansionModule } from '@angular/material/expansion';
import { ProjectionsListComponent } from './projections/projections-list/projections-list.component';
import { ProjectionsEditComponent } from './projections/projections-edit/projections-edit.component';
import { ProjectionsResolver } from './projections';




const redirectUri = environment.redirectUri;
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    RegisterComponent,
    HomeComponent,
    CustomerListComponent,
    CustomerDetailsComponent,
    CustomerCreateComponent,
    ShipToAddressListComponent,
    ShipToAddressEditComponent,
    ShipToCreateComponent,
    CustomerEndListComponent,
    CustomerEndEditComponent,
    CustomerRetailListComponent,
    CustomerRetailEditComponent,
    VendorListComponent,
    VendorDetailComponent,
    PickupLocationListComponent,
    PickupLocationDetailComponent,
    BrandListComponent,
    BrandDetailComponent,
    CategoryListComponent,
    CategoryEditComponent,
    UpcGtnListComponent,
    UpcGtnEditComponent,
    ProductListComponent,
    ProductEditComponent,
    CaseSetupListComponent,
    CaseSetupEditComponent,
    SubCategoryListComponent,
    SubCategoryEditComponent,
    PalletSetupListComponent,
    PalletSetupEditComponent,
    FreightCostSetupListComponent,
    FreightCostSetupEditComponent,
    CostingBucketSetupListComponent,
    CostingBucketSetupEditComponent,
    ListingSetupListComponent,
    ListingSetupEditComponent,
    ListingSetupNewComponent,
    DashboardShowComponent,
    CreditListComponent,
    CreditEditComponent,
    ProfitListComponent,
    ProfitEditComponent,
    ProfitLossComponent,
    TransferListComponent,
    TransferEditComponent,
    BrokerPayListComponent,
    BrokerPayEditComponent,
    ProjectionsNewComponent,
    ReviewWeekComponent,
    ProjectionsListComponent,
    ProjectionsEditComponent,
    
    
  ],
  imports: [
    BrowserModule,
    NgbModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    OrderModule,
    PortalModule,
    OtherModule,
    AdminModule,
    ConfirmDialogModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    NgbCollapseModule,
    MatExpansionModule,
    NgbAccordionModule,
    AppRoutingModule,
    MsalModule.forRoot( new PublicClientApplication({
      auth: {
        clientId: '800adf09-5cbf-4402-bd26-26ecf01d1f28', // This is your client ID
        authority: 'https://login.microsoftonline.com/667d71a0-023c-4afd-8165-08530ff5fd13', // This is your tenant ID
        redirectUri: redirectUri
        // redirectUri: 'https://www.kalcorkst.ca'// This is your redirect URI
        // redirectUri: 'http://localhost:4200'// This is your redirect URI
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
      }
    }), {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['Sites.Manage.All']
        }
    }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map([ 
          ['https://graph.microsoft.com/v1.0/sites', ['Sites.Manage.All']]
      ])
    })
  ],
  providers: [
    AuthenticationService,
    BrokerPayResolver,
    CustomerResolver,
    ShipToAddressResolver,
    CustomerEndResolver,
    CustomerRetailResolver,
    VendorResolver,
    PickupLocationResolver,
    BrandResolver,
    CategoryResolver,
    UpcGtnResolver,
    ProductResolver,
    SubCategoryResolver,
    CreditResolver,
    ProfitLossResolver,
    TransferResolver,
    ProjectionsResolver,
    NgbActiveModal,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalGuard
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
