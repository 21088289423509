<h5>{{ title }} </h5>
<form [formGroup]="productForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="form-group">
    <div class="col">
      <label>
        Active Listing:
        <input type="text" formControlName="activeListings" class="form-control" >
      </label>

      <label class="ms-3">
        Product Order Code:
        <input type="text" formControlName="productOrderCode" class="form-control" >
      </label>
  </div>

    <div class="col">
      <label>
        Brand:
        <input type="text" formControlName="brand" class="form-control" >
      </label>

      <label class="ms-3">
        Category:
        <input type="text" formControlName="category" class="form-control" >
      </label>
   </div>

    <div class="col">
      <label>
        Item Desciption:
        <input type="text" formControlName="itemDescription" class="form-control" size="60">
      </label>

      <label class="ms-3">
        Size:
        <input type="text" formControlName="size" class="form-control">
      </label>
    </div>
    <div class="col">
      <label>
        CS Net Lbs:
        <input type="text" formControlName="csNetLbs" class="form-control" size="60">
      </label>

      <label class="ms-3">
        CS Gross Lbs:
        <input type="text" formControlName="csGrossLbs" class="form-control">
      </label>
    </div>

    <div class="col">
        <label>
            CS Net Kg:
          <input type="text" formControlName="csNetKg" class="form-control" size="60">
        </label>

        <label class="ms-3">
            CS Gross KG:
          <input type="text" formControlName="csGrossKg" class="form-control">
        </label>
      </div>

      <div class="col">
        <label>
            CS L Inch:
          <input type="text" formControlName="csLInch" class="form-control" size="60">
        </label>

        <label class="ms-3">
            CS W Inch:
          <input type="text" formControlName="csWInch" class="form-control">
        </label>

        <label class="ms-3">
            CS H Inch:
          <input type="text" formControlName="csHInch" class="form-control" size="60">
        </label>

        <label class="ms-3">
            CS Cube Imperial:
          <input type="text" formControlName="csCubeImperial" class="form-control">
        </label>
      </div>

    <div class="col">
        <label>
            CS L Cm:
          <input type="text" formControlName="csLCm" class="form-control" size="60">
        </label>

        <label class="ms-3">
            CS W Cm:
          <input type="text" formControlName="csWCm" class="form-control">
        </label>

        <label class="ms-3">
            CS H Cm:
          <input type="text" formControlName="csHCm" class="form-control" size="60">
        </label>

        <label class="ms-3">
            CS Cube Metric:
          <input type="text" formControlName="csCubeMetric" class="form-control">
        </label>
      </div>
    <div class="col">
        <label>
            Unit Net:
          <input type="text" formControlName="unitNet" class="form-control" size="60">
        </label>

        <label class="ms-3">
            Unit Gross:
          <input type="text" formControlName="unitGross" class="form-control">
        </label>
      </div>

      <div class="col">
        <label>
            Unit L Inch:
          <input type="text" formControlName="unitLInch" class="form-control" size="60">
        </label>

        <label class="ms-3">
            Unit W Inch:
          <input type="text" formControlName="unitWInch" class="form-control">
        </label>

        <label class="ms-3">
            Unit H Inch:
          <input type="text" formControlName="unitHInch" class="form-control" size="60">
        </label>

        <label class="ms-3">
            Unit Cube Imp:
          <input type="text" formControlName="unitCubeImp" class="form-control">
        </label>
      </div>

      <div class="col">
        <label>
            Unit L Cm:
          <input type="text" formControlName="unitLCm" class="form-control" size="60">
        </label>

        <label class="ms-3">
            Unit W Cm:
          <input type="text" formControlName="unitWCm" class="form-control">
        </label>

        <label class="ms-3">
            Unit H Cm:
          <input type="text" formControlName="unitHCm" class="form-control" size="60">
        </label>

        <label class="ms-3">
            Unit Cube M:
          <input type="text" formControlName="unitCubeM" class="form-control">
        </label>
      </div>

    <button type="submit" class="btn btn-primary mt-3 w-25">Save</button>
    <br>
    <button type="button" class="btn btn-secondary mt-3 w-25" (click)="editSetup()">Edit Setup</button>
    <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="editPalletSetup()">Pallet Setup</button>
    <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="editFreightCostSetup()">Freight Cost Setup</button>
    <br>
    <button type="button" class="btn btn-secondary mt-3 w-25" (click)="editCostingBucketSetup()">Costing Bucket Setup</button>
    <!-- <button type="button" class="btn btn-secondary ms-3 mt-3 w-25" (click)="editListingSetup()">Listing Setup</button> -->
  </div>
  <ngb-alert *ngIf="successMessage" type="success" (close)="successMessage = null">{{ successMessage }}</ngb-alert>
</form>
