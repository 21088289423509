import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray
 } from '@angular/forms';
import { Subject, fromEventPattern, throwError } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Transportation } from '../../models/transportation.model';
import { TransportationService } from '../../services/transportation.service';
import { OrderItems } from '../../models/orderItems.model';
import { OrderItemsService } from '../../services/order-items.service';
import { Order, OrderPDF } from '../../models/order-model';
import { OrderService } from '../../services/order.service';
import { FreightType } from '../../models/freight-type-model';
import { FreightTypeService } from '../../services/freight-type.service';
import { OrderMasterDetails } from '../../models/order-master-details.model';
import { Customer } from '../../models/customer.model';
import { CustomerService } from '../../services/customer.service';
import { ShipToAddress } from '../../models/shipto.model';
import { ShipToAddressService } from '../../services/ship-to-address.service';
import { Vendor } from '../../models/vendor-model';
import { VendorService } from '../../services/vendor.service';
import { PickupLocation } from '../../models/pickup-location.model';
import { PickupLocationService } from '../../services/pickup-location.service';
import { Warehouse } from '../../models/warehouse.model';
import { WarehouseService } from '../../services/warehouse.service';
import { Location} from '../../models/location-model';
import { LocationService } from '../../services/location.service';
import { Broker } from '../../models/broker.model';
import { BrokerService } from '../../services/broker.service';
import { Term } from '../../models/term-model';
import { TermsService } from '../../services/terms.service';
import { Big } from 'big.js';
import * as jsPDF from 'jspdf';
import * as html2pdf from 'html2pdf.js';
import { Sorter2Service } from '../../services/sorter2.service';

@Component({
  selector: 'app-invoicing-edit',
  templateUrl: './invoicing-edit.component.html',
  styleUrls: ['./invoicing-edit.component.scss']
})
export class InvoicingEditComponent implements OnInit {
  transport: Transportation[];
  order: Order;
  itemsOriginal: OrderItems[];
  orderSelected: OrderPDF;
  orderItems: OrderItems[];
  items: OrderItems[];
  itemsArray: UntypedFormArray;
  masterDetails: OrderMasterDetails;
  orderForm: UntypedFormGroup;
  freightType: FreightType;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  alertType = "success";
  grayRow = [];
  loaded = false;
  customer: Customer;
  shipTo: ShipToAddress;
  vendor: Vendor;
  pickup: PickupLocation;
  warehouse: Warehouse;
  broker: Broker;
  term: Term;
  transport1: Transportation;
  transport2: Transportation;
  transport3: Transportation;
  transport4: Transportation;
  transport5: Transportation;
  location1: Location;
  location2: Location;
  location3: Location;
  location4: Location;
  location5: Location;
  locationEnd1: Location;
  locationEnd2: Location;
  locationEnd3: Location;
  locationEnd4: Location;
  locationEnd5: Location;

  freightTypeCompany: string;
  display='none';
  currentItemIndex = 0;
  modalTitle = 'ENTER CASE SHIPPING INFORMATION';
  rowModalShow = [true, false, false, false, false, false, false, false, false, false ];
  caseName = ['case1', 'case2', 'case3', 'case4', 'case5', 'case6', 'case7', 'case8', 'case9', 'case10'];
  masterName = ['shipmentDate', 'deliveredDateAppt', 'freightTypeId', 'appConfirmed',
                'transportCo1', 'transportCo2', 'transportCo3'];
  totalCases = 0;
  totalPallets = 0;
  totalGrossLbs = 0;
  totalGrosskg = 0;
  totalNetLbs = 0;
  totalNetkg = 0;
  totalSales = 0;
  totalCaseCost = 0;
  totalPurchased = 0;
  updateOrder = false;
  updateItems = [];
  caseCostOriginal = [];
//  alertType = "success";
  title = 'Invoicing';
  dataChanged = false;
  reportName = '';
  reportNames = [
    {
    Name: 'Sales Order', 
    Id: 'my-sales-order',
    filename: 'Sales Order',
    Orientation: 'landscape',
    TopMargin: .1,
    LRMargin: .6
    },
    {
      Name: 'Packing Slip', 
      Id: 'my-packing-slip',
      filename: 'Packing Slip',
      Orientation: 'landscape',
      TopMargin: .6,
      LRMargin: .3
    },
    {
      Name: 'Shipping', 
      Id: 'my-shipping',
      filename: 'Shipping',
      Orientation: 'landscape',
      TopMargin: .1,
      LRMargin: .6
    },
    {
      Name: 'Commercial Invoice', 
      Id: 'my-commercial-invoice',
      filename: 'Commercial Invoice',
      Orientation: 'landscape',
      TopMargin: .1,
      LRMargin: .6
    },    
    {
      Name: 'Vendor Sales Order', 
      Id: 'my-sales-order-vendor',
      filename: 'Vendor Sales Order',
      Orientation: 'landscape',
      TopMargin: .1,
      LRMargin: .6
    },
    {
      Name: 'Invoice', 
      Id: 'my-invoicing',
      filename: 'Invoice',
      Orientation: 'landscape',
      TopMargin: .1,
      LRMargin: .6
      }
  ];
  currentIndex: number;
  shipOrderBtn = false;
  invoiceOrderBtn = false;
  salesOrderPrintBtn = false;
  slipPrintBtn = false;
  shipPrintBtn = false;
  invoicePrintBtn = false;
  poPrintBtn = false;
  deleteOrderBtn = false;
  vendorPaymentTerms: string;
  vendorPurchaseCaseCost = true;
  fOBNetVendorCost = false;
  warehouseLine3 = '';
  warehouseLine4 = '';
  constructor(
    private customerService: CustomerService,
    private shipToService: ShipToAddressService,
    private vendorService: VendorService,
    private pickupService: PickupLocationService,
    private warehouseService: WarehouseService,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private brokerService: BrokerService,
    private termService: TermsService,
    private freightService: FreightTypeService,
    private locationService: LocationService,
    private transportService: TransportationService,
    private checkRef: ChangeDetectorRef,
    private orderItemService: OrderItemsService,
    private sort2Svc: Sorter2Service
  ) { }

  ngOnInit() {
    this.route.data.subscribe(order => {
      if (order.dataOrder !== undefined) {
        this.order = order.dataOrder;
        this.buttonEnabler();
        // this.itemsOriginal = order.dataOrder.items;
       // this.itemsOriginal = this.sort2Svc.sorter(order.dataOrder.items, 'category', 'orderCode');
        this.itemsOriginal = this.sort2Svc.sorter(order.dataOrder.items,'brandCategory','itemDescription');

        this.itemsOriginal.forEach(e => {
          const caseCostOnly = {id: e.id, cases: e.cases, caseCost: e.caseCost};
          this.caseCostOriginal.push(caseCostOnly);
        })
        this.items = this.itemsOriginal.filter(item => item.cases);
        let addIt = false;

        this.items.forEach( item => {
          if (addIt) {
            this.addItem(item);
          } else {
            this.orderForm = this.fb.group({
              items: this.fb.array([ this.createItem(item) ])
            });
            addIt = true;
          }
        })
        this.loadServices();
        // this.updateAllItems();
        if (this.items.length > 0) {
          this.onChanges();
          this.reCalculate();
        }
        this.loaded = true;
      } else {
        this.successMessage = 'Unable to process request';
        this.alertType = "danger";
      }
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);    
  }
  createItem(item): UntypedFormGroup {
    const shipped = item.shipped ? item.shipped : 0;
    const purchased = item.purchased ? item.purchased : shipped;
    const invoiced = item.invoiced ? item.invoiced : shipped;
    return this.fb.group({
      purchased: purchased,
      invoiced: invoiced
    });
  }
  addItem(item): void {
    this.itemsArray = this.orderForm.get('items') as UntypedFormArray;
    this.itemsArray.push(this.createItem(item));
  }
  isEmpty(str) {
    return (!str || 0 === str.length);
  }
  loadServices() {
    if (this.order.billToId) {
      const id = this.order.billToId;
      this.customerService.getOne(id).subscribe(cust => this.customer = cust);
    }
    if (this.order.brokerId) {
      const id = this.order.brokerId;
      this.brokerService.getOne(id).subscribe(broker => this.broker = broker);
    }
    if (this.order.shipToId) {
      const id = this.order.shipToId;
      this.shipToService.getOne(id).subscribe(ship => this.shipTo = ship);
    }
    if (this.order.termsId) {
      const id = this.order.termsId;
      this.termService.getOne(id).subscribe(terms => this.term = terms);
    }
    if (this.order.freightTypeId) {
      const id = this.order.freightTypeId;
      this.freightService.getOne(id).subscribe(freight => this.freightType = freight);
    }
    
    if (this.order.vendorPickupId) {
      const id = this.order.vendorPickupId;
      this.pickupService.getOne(id).subscribe(pick => this.pickup = pick);
    }

    if (this.order.shippingLane1) {
      const id = this.order.shippingLane1;
      this.locationService.getOne(id).subscribe(loc => this.location1 = loc);
    }
    if (this.order.shippingLane2) {
      const id = this.order.shippingLane2;
      this.locationService.getOne(id).subscribe(loc => this.location2 = loc);
    }
    if (this.order.shippingLane3) {
      const id = this.order.shippingLane3;
      this.locationService.getOne(id).subscribe(loc => this.location3 = loc);
    }
    if (this.order.shippingLane4) {
      const id = this.order.shippingLane4;
      this.locationService.getOne(id).subscribe(loc => this.location4 = loc);
    }
    if (this.order.shippingLane5) {
      const id = this.order.shippingLane5;
      this.locationService.getOne(id).subscribe(loc => this.location5 = loc);
    }
    if (this.order.transportCo1) {
      const id = this.order.transportCo1;
      this.transportService.getOne(id).subscribe(trans => this.transport1 = trans);
    }
    if (this.order.transportCo2) {
      const id = this.order.transportCo2;
      this.transportService.getOne(id).subscribe(trans => this.transport2 = trans);
    }
    if (this.order.transportCo3) {
      const id = this.order.transportCo3;
      this.transportService.getOne(id).subscribe(trans => this.transport3 = trans);
    }
    if (this.order.transportCo4) {
      const id = this.order.transportCo4;
      this.transportService.getOne(id).subscribe(trans => this.transport4 = trans);
    }
    if (this.order.transportCo5) {
      const id = this.order.transportCo5;
      this.transportService.getOne(id).subscribe(trans => this.transport5 = trans);
    }
    if (this.order.whsid) {
      const id = this.order.whsid;
      this.warehouseService.getOne(id).subscribe(whse => {
        this.warehouse = whse;
        const whseEst = this.isEmpty(this.warehouse.federalEstablishment) ? '' : 'Est. ' + this.warehouse.federalEstablishment;
        const whseCSZ = this.getAddress2('warehouse');
        this.warehouseLine3 = whseCSZ;
        this.warehouseLine4 = whseEst;
      });
    }

    this.vendorPaymentTerms = '';
    if (this.order.vendorId) {
      const id = this.order.vendorId;
      this.vendorService.getOne(id).subscribe(vend => {
        this.vendor = vend;
        if (this.vendor.termsId) {
          this.termService.getOne(this.vendor.termsId).subscribe(vendT => this.vendorPaymentTerms = vendT.paymentTerms);
        }
      });
    }

    if (this.order.shippingLaneEnd1) {
      const id = this.order.shippingLaneEnd1;
      this.locationService.getOne(id).subscribe(loc => this.locationEnd1 = loc);
    }
    if (this.order.shippingLaneEnd2) {
      const id = this.order.shippingLaneEnd2;
      this.locationService.getOne(id).subscribe(loc => this.locationEnd2 = loc);
    }
    if (this.order.shippingLaneEnd3) {
      const id = this.order.shippingLaneEnd3;
      this.locationService.getOne(id).subscribe(loc => this.locationEnd3 = loc);
    }
    if (this.order.shippingLaneEnd4) {
      const id = this.order.shippingLaneEnd4;
      this.locationService.getOne(id).subscribe(loc => this.locationEnd4 = loc);
    }
    if (this.order.shippingLaneEnd5) {
      const id = this.order.shippingLaneEnd5;
      this.locationService.getOne(id).subscribe(loc => this.locationEnd5 = loc);
    }

  }
  onChanges(): void {
    this.orderForm.get('items').valueChanges.subscribe(val => {
      this.updateAllItems();
      this.reCalculate();
    });
   }
   updateAllItems() {
    this.orderForm.get('items').value.forEach((element, index) => {
      const casesInvoiced = element.invoiced ? element.invoiced * 1 : 0;
      const casesPurchased = element.purchased ? element.purchased * 1 : 0;
      this.items[index].invoiced = casesInvoiced;
      this.items[index].purchased = casesPurchased;
    });
  }
  reCalculate() {
    this.totalCases = 0;
    this.totalPallets =  this.order.palletCount === null ? 0 : this.order.palletCount;
    this.totalGrossLbs = 0;
    this.totalGrosskg = 0;
    this.totalNetLbs = 0;
    this.totalNetkg = 0;
    this.totalSales = 0;
    this.totalPurchased = 0;
    this.items.forEach((element, index) => {
      let purchased = 0;
      let casesInvoiced = 0;
      if (element.invoiced) {
        casesInvoiced = element.invoiced * 1;
      }
      if (element.purchased) {
        purchased = element.purchased * 1;
      }
      let caseCost = 0;
      if (element.caseCost) {
        caseCost = element.caseCost * 1;
      }
      if (element.csPerPallet != 0) {
        const bigNum: any = Big(casesInvoiced)
        .div(element.csPerPallet)
        .round(0, 3);
        let pallets = bigNum * 1;
        if (pallets == 0 && casesInvoiced !=0 ) {
          pallets = 1;
        }
        // this.totalPallets += pallets;
      }
      this.totalCases += casesInvoiced;
      this.totalGrossLbs += element.csGrossLbs * casesInvoiced;
      this.totalGrosskg += element.csGrossKg * casesInvoiced;
      this.totalNetkg += element.csNetKg * casesInvoiced;
      this.totalNetLbs += element.csNetLbs * casesInvoiced;
      this.totalPurchased += caseCost * purchased;
      this.totalSales += caseCost * casesInvoiced;
    });
    const palletLbs = this.order.palletGrossLbs === null ? 0 : this.order.palletGrossLbs;
    const bigNum: any = Big(palletLbs)
      .times(.453592)
      .round(2);
    const palletKg = bigNum * 1;
    this.totalGrossLbs += this.totalPallets * palletLbs;
    this.totalGrosskg += this.totalPallets * palletKg;

    this.checkRef.detectChanges();
  }

  getAddress2(item) {
    let city = '';
    let state = '';
    let zip = '';
    let ret = '';
    switch (item) {
      case 'customer':
        if (this.customer) {
          city = this.customer.city;
          state = this.customer.stateProvince;
          zip = this.customer.zipPostalCode;
        }
        break;
      case 'shipto':
          if (this.shipTo) {
            city = this.shipTo.city;
            state = this.shipTo.stateProvince;
            zip = this.shipTo.zipPostalCode;
          }
          break;
      case 'pickup':
          if (this.pickup) {
            city = this.pickup.city;
            state = this.pickup.stateProvince;
            zip = this.pickup.zipPostalCode;
          }
          break;
          
      case 'warehouse':
          if (this.warehouse) {
            city = this.warehouse.city;
            state = this.warehouse.stateProvince;
            zip = this.warehouse.zipPostalCode;
          }
          break;
      case 'vendor':
          if (this.vendor) {
            city = this.vendor.city;
            state = this.vendor.stateProvince;
            zip = this.vendor.zipPostalCode;
          }
          break;
    }
    
    if (city) {
      ret = city;
      if (state) {
        ret += ', ' + state;
      }
    }

    if (zip) {
      ret += ' ' + zip;
    }

    return ret.trim();

  }
  displayDate(dt, t) {
    
    if (dt) {
      if (t === undefined) {
        t = '';
      }
      let x = new Date(dt);
      if (t == 'T') {
        return x.toLocaleDateString() + ' @ ' + x.toLocaleTimeString();
      }
      return x.toLocaleDateString();
    } 
    return dt
  }
  getVendor() {
    let ret = '';
    if (this.vendor) {
      ret += this.vendor.company.trim();
      ret += ' ' + this.vendor.address;
      ret += ' ' + this.getAddress2('vendor');
    }

    return ret;
  }
  onSubmit() {
    this.alertType = "success";
    const itemsToProcess = this.items.length;
    let itemsProcessed = 0;
    this.updateAllItems();
    this.items.forEach(item => {
      this.orderItemService.updateInvoice(item).subscribe( data => {
        itemsProcessed++;
        if (itemsProcessed >= itemsToProcess) {
          this.success.next(`Successfully updated items.`);
          this.updateOrderStatus();
        }
      })
    })
  }
  updateOrderStatus() {
    this.order.orderStatus = "Invoiced";
    this.orderService.updateInvoice(this.order).subscribe(data => {
      this.order = data;
      this.success.next(`Successfully updated items.`);
      this.buttonEnabler();
      this.reCalculate();
    })
  }

  printProcess(report) {
    if (this.order.invoiceNumber === null) {
      alert('Please click save button to get assigned an invoice number.');
      return;
    }
    const myTotalCases = this.totalCases;
    const myTotalPallets = this.totalPallets;
    const myTotalGrossLbs = this.totalGrossLbs;
    const myTotalCaseCost = this.totalCaseCost;
    const mytotalGrosskg = this.totalGrosskg;
    this.items.forEach(e => {
      const index = this.caseCostOriginal.findIndex(x => x.id == e.id);
      if (index > -1) {
        e.caseCost = this.caseCostOriginal[index].caseCost;
      }
    })
    this.updateAllItems();
    this.updateVars();

   this.orderItems = this.items.filter(item => item.cases);
    this.currentIndex = this.reportNames.findIndex(r => r.Name == report);
    if (this.reportNames[this.currentIndex].Name == 'Vendor Sales Order') {
      this.orderSelected.paymentTerms = this.vendorPaymentTerms;
      if (this.orderItems.length > 0) {
        if (this.orderSelected.orderStatus == 'Shipped' || this.orderSelected.orderStatus == 'Invoiced' || this.orderSelected.orderStatus == 'Closed') {
          this.orderItems = this.orderItems.filter(item => item.shipped);
        } 
        this.orderItems.forEach(element => {
          if (this.vendorPurchaseCaseCost) {
            element.caseCost = element.purchaseCaseCost;
          } else {
            element.caseCost = element.purchaseCaseCost - element.clearingCs - element.freightCs - element.duty - element.royalty;
          }
        });
      }
    }
    if (this.reportNames[this.currentIndex].Name == 'Invoice') {
      this.orderSelected.paymentTerms = 'Due on Receipt';
    }

    this.calculateTotals2();
    this.title = this.reportNames[this.currentIndex].Name;
    this.reportName = this.reportNames[this.currentIndex].Name;
    this.checkRef.detectChanges();
    var element = document.getElementById(this.reportNames[this.currentIndex].Id);
      var opt = {
        margin:       [this.reportNames[this.currentIndex].TopMargin, this.reportNames[this.currentIndex].LRMargin],
        filename:     this.orderSelected.salesOrderNo.trim() + ' ' + this.reportNames[this.currentIndex].filename + '.pdf',
        image:        { type: 'jpeg', quality: 0.98 },
        html2canvas:  { scale: 2 },
        jsPDF:        { unit: 'in', format: 'letter', orientation: this.reportNames[this.currentIndex].Orientation }
      };
      html2pdf().set(opt).from(element).save();
      this.totalCases = myTotalCases;
      this.totalPallets = myTotalPallets;
      this.totalGrossLbs = myTotalGrossLbs;
      this.totalCaseCost = myTotalCaseCost;
      this.totalGrosskg = mytotalGrosskg;
      this.items.forEach(e => {
        const index = this.caseCostOriginal.findIndex(x => x.id == e.id);
        if (index > -1) {
          e.caseCost = this.caseCostOriginal[index].caseCost;
          e.cases = this.caseCostOriginal[index].cases;
        }
      })
      this.reportName = '';      
  }

  calculateTotals2() {

    this.totalCases = 0;
    this.totalPallets =  this.order.palletCount === null ? 0: this.order.palletCount;
    this.totalGrossLbs = 0;
    this.totalCaseCost = 0;
    this.totalGrosskg = 0;
    this.orderItems.forEach(element => {
      let cases = element.cases;
      switch(this.reportNames[this.currentIndex].Name) {
        case 'Commercial Invoice':
          cases = element.shipped;
          element.cases = element.shipped;
          break;
        case 'Vendor Sales Order':
          if (this.orderSelected.orderStatus == 'Shipped' || this.orderSelected.orderStatus == 'Invoiced' || this.orderSelected.orderStatus == 'Closed') {
            cases = element.shipped;
            element.cases = element.shipped;
          }
          break;
        case 'Invoice':
          if (this.orderSelected.orderStatus == 'Shipped' || this.orderSelected.orderStatus == 'Invoiced' || this.orderSelected.orderStatus == 'Closed') {
            cases = element.shipped;
            element.cases = element.shipped;
          }
          break;
        case 'Shipping':
          cases = element.shipped;
          break;
      }

      if (cases === null) {
        cases = 0;
      }
      if (cases) {
          cases = cases * 1;
      }
      if (isNaN(cases)) {
        cases = 0;
      }      
      let caseCost = 0;
      let extCaseCost = 0;
      if (element.caseCost) {
        caseCost = element.caseCost * 1;
      }
      extCaseCost = cases * caseCost;
      element.extCaseCost = extCaseCost;
      this.totalCases += cases;
      if (element.csPerPallet != 0) {
        const bigNum: any = Big(cases)
        .div(element.csPerPallet)
        .round(0, 3);
        let pallets = bigNum * 1;
        if (pallets == 0 && cases !=0 ) {
          pallets = 1;
        }
        // this.totalPallets += pallets;
      }
      this.totalCaseCost += extCaseCost;
      this.totalGrossLbs += element.csGrossLbs * cases;
      this.totalGrosskg += element.csGrossKg * cases;
    });
    const palletLbs = this.order.palletGrossLbs === null ? 0 : this.order.palletGrossLbs;
    const bigNum: any = Big(palletLbs)
      .times(.453592)
      .round(2);
    const palletKg = bigNum * 1;
    this.totalGrossLbs += this.totalPallets * palletLbs;
    this.totalGrosskg += this.totalPallets * palletKg;

  }
  updateVars() {
    
    this.orderSelected = new OrderPDF();
    this.orderSelected.id = this.order.id;
    this.orderSelected.orderStatus = this.order.orderStatus;
    this.orderSelected.orderType = this.order.orderType;
    this.orderSelected.salesOrderNo = this.order.salesOrderNo;
    this.orderSelected.purchaseOrder = this.order.purchaseOrder;
    this.orderSelected.salesOrderDate = this.order.salesOrderDate;
    this.orderSelected.shipmentDate = this.order.shipmentDate;
    this.orderSelected.deliveredDateAppt = this.order.deliveredDateAppt;
    this.orderSelected.invoiceNumber = this.order.invoiceNumber;

    if (this.order.billToId) {
      this.orderSelected.billToId = this.order.billToId;
      this.orderSelected.billTo = this.customer.company;
      this.orderSelected.billAddress = this.customer.address;
      this.orderSelected.billCSZ = this.getAddress2('customer');
    }

    if (this.order.shipToId) {
      this.orderSelected.shipToId = this.order.shipToId;
      this.orderSelected.shipTo = this.shipTo.company;
      this.orderSelected.shipToAddress = this.shipTo.address;
      this.orderSelected.shipToCSZ = this.getAddress2('shipto');
    }

    if (this.order.brokerId) {
      this.orderSelected.broker = this.broker.company;
    }

    if (this.order.vendorId) {
      this.orderSelected.vendorId = this.order.vendorId;
      this.orderSelected.vendor = this.vendor.company;
      this.orderSelected.vendorInfo = this.getVendor();
    }

    this.orderSelected.appConfirmed = this.order.appConfirmed;
    this.orderSelected.notes = this.order.notes;

    if (this.order.vendorPickupId) {
      this.orderSelected.pickCompany = this.pickup.company;
      this.orderSelected.pickAddress = this.pickup.address;
      this.orderSelected.pickCSZ = this.getAddress2('pickup');
    }
    
    if (this.order.termsId) {
      this.orderSelected.paymentTerms = this.term.paymentTerms;
    }

    if (this.freightType && this.freightType.freightType) {
      this.orderSelected.freightType = this.freightType.freightType;
    }

    if (this.order.shippingLane1) {
      this.orderSelected.location1 = this.location1.location;
    }

    if (this.order.shippingLane2) {
      this.orderSelected.location2 = this.location2.location;
    }

    if (this.order.shippingLane3) {
      this.orderSelected.location3 = this.location3.location;
    }

    if (this.order.shippingLane4) {
      this.orderSelected.location4 = this.location4.location;
    }

    if (this.order.shippingLane5) {
      this.orderSelected.location5 = this.location5.location;
    }

    if (this.order.shippingLaneEnd1) {
      this.orderSelected.locationEnd1 = this.locationEnd1.location;
    }
    if (this.order.shippingLaneEnd2) {
      this.orderSelected.locationEnd2 = this.locationEnd2.location;
    }
    if (this.order.shippingLaneEnd3) {
      this.orderSelected.locationEnd3 = this.locationEnd3.location;
    }
    if (this.order.shippingLaneEnd4) {
      this.orderSelected.locationEnd4 = this.locationEnd4.location;
    }
    if (this.order.shippingLaneEnd5) {
      this.orderSelected.locationEnd5 = this.locationEnd5.location;
    }

    this.orderSelected.transCompany1 = this.transport1 ? this.transport1.company : '';
    this.orderSelected.transCompany2 = this.transport2 ? this.transport2.company : '';
    this.orderSelected.transCompany3 = this.transport3 ? this.transport3.company : '';
    this.orderSelected.transCompany4 = this.transport4 ? this.transport4.company : '';
    this.orderSelected.transCompany5 = this.transport5 ? this.transport5.company : '';

    if (this.order.whsid) {
      this.orderSelected.whseCompany = this.warehouse.company;
      this.orderSelected.whseAddress = this.warehouse.address;
      this.orderSelected.whseEst = this.isEmpty(this.warehouse.federalEstablishment) ? '' : 'Est. ' + this.warehouse.federalEstablishment;
      this.orderSelected.whseCSZ = this.getAddress2('warehouse');
    }
    this.orderSelected.shipDate1 = this.order.shipDate1;
    this.orderSelected.shipDate2 = this.order.shipDate2;
    this.orderSelected.shipDate3 = this.order.shipDate3;
    this.orderSelected.shipDate4 = this.order.shipDate4;
    this.orderSelected.shipDate5 = this.order.shipDate5;

    this.orderSelected.deliveryDate1 = this.order.deliveryDate1;
    this.orderSelected.deliveryDate2 = this.order.deliveryDate2;
    this.orderSelected.deliveryDate3 = this.order.deliveryDate3;
    this.orderSelected.deliveryDate4 = this.order.deliveryDate4;
    this.orderSelected.deliveryDate5 = this.order.deliveryDate5;    
    this.orderSelected.palletCount = this.order.palletCount;
    this.orderSelected.palletGrossLbs = this.order.palletGrossLbs;
  }
  buttonEnabler() {
    if (!this.order.orderStatus) {
      return;
    }

    this.shipOrderBtn = true;
    this.salesOrderPrintBtn = true;
    this.slipPrintBtn = true;
    this.poPrintBtn = true;
    this.deleteOrderBtn = true;

    if (this.order.orderStatus == 'Sales Order') {
      return;
    }
    this.shipPrintBtn = true;
    this.deleteOrderBtn = false;
    this.invoiceOrderBtn = true;
    if (this.order.invoiceNumber) {
      this.invoicePrintBtn = true;
    }
  }
  orderEdit() {
    if (this.order.id) {
      this.router.navigate(['/sales/order-edit', this.order.id]);
    }
  }
  editShippingItems() {
    if (this.order.id) {
      this.router.navigate(['/sales/shipping-edit', this.order.id]);
    }
  }
  onDelete() {
    if (this.order.id) {
      if (confirm('Delete this item?'))
      this.orderService.remove(this.order.id).subscribe(data => {
        this.router.navigate(['/sales/order-list']);
      });
    }
  }
  showDialog() {  
    this.reportName='Confirm';
    this.checkRef.detectChanges();
  }  
  returnDialog(result){
    this.vendorPurchaseCaseCost = result == 1 ? true : false;
    this.fOBNetVendorCost = result == 2 ? true : false;
    this.printProcess("Vendor Sales Order"); 
  }

}
