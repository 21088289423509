import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule} from '@angular/forms';
import { PortalRoutingModule } from './portal-routing.module';
import { 
  BucketComponent,
  PortalComponent, 
  DifferenceComponent,
  CreditComponent,
  ExchangeComponent,
  PDFExchangeComponent,
  MarketingComponent,
  MarketingPdfComponent,
  CommissionComponent,
  CommissionPdfComponent,
  VendorComponent,
  VendorPdfComponent,
  RoyaltyComponent,
  StorageComponent,
  SalesComponent,
  SalesOverviewComponent,
  DataLinkListResolver
} from './index';
import { StoragePdfComponent } from './pdf/storage-pdf/storage-pdf.component';
import { RoyaltyPdfComponent } from './pdf/royalty-pdf/royalty-pdf.component';
import { SalesPdfComponent } from './pdf/sales-pdf/sales-pdf.component';
import { SalesOverviewPdfComponent } from './pdf/sales-overview-pdf/sales-overview-pdf.component';
import { SalesDetailComponent } from './sales-detail/sales-detail.component';
import { SalesDetailPdfComponent } from './pdf/sales-detail-pdf/sales-detail-pdf.component';
import { LinkComponent } from './sharepoint/link/link.component';
import { ConnectComponent } from './sharepoint/connect/connect.component';
import {NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConnectBudgetComponent } from './sharepoint/connect-budget/connect-budget.component';
import { PeriodReportComponent } from './period-report/period-report.component';
import { VendorDataComponent } from './azure/vendor-data/vendor-data.component';
import { AzureLinkComponent } from './azure/azure-link/azure-link.component';
import { MarketingLinkComponent } from './azure-marketing/marketing-link/marketing-link.component';
import { MarketingDataComponent } from './azure-marketing/marketing-data/marketing-data.component';
import { CommissionDataComponent } from './azure-commission/commission-data/commission-data.component';
import { CommissionLinkComponent } from './azure-commission/commission-link/commission-link.component';
import { KalcorLinkComponent } from './azure-kalcor/kalcor-link/kalcor-link.component';
import { KalcorDataComponent } from './azure-kalcor/kalcor-data/kalcor-data.component';
import { ExchangeLinkComponent } from './azure-exchange/exchange-link/exchange-link.component';
import { ExchangeDataComponent } from './azure-exchange/exchange-data/exchange-data.component';
import { FreightCndLinkComponent } from './azure-freight-cnd/freight-cnd-link/freight-cnd-link.component';
import { FreightCndDataComponent } from './azure-freight-cnd/freight-cnd-data/freight-cnd-data.component';
import { VendorReportDataComponent } from './azure-vendor-report/vendor-report-data/vendor-report-data.component';
import { VendorReportLinkComponent } from './azure-vendor-report/vendor-report-link/vendor-report-link.component';
import { AllLinkComponent } from './azure-all/all-link/all-link.component';
import { AllDataComponent } from './azure-all/all-data/all-data.component';
import { ExhibitAComponent } from './exhibit-a/exhibit-a.component';

@NgModule({
  declarations: [
    BucketComponent, 
    PortalComponent, 
    DifferenceComponent, 
    CreditComponent, 
    ExchangeComponent,
    PDFExchangeComponent,
    MarketingComponent,
    MarketingPdfComponent,
    CommissionComponent,
    CommissionPdfComponent,
    VendorComponent,
    VendorPdfComponent,
    RoyaltyComponent,
    StorageComponent,
    StoragePdfComponent,
    RoyaltyPdfComponent,
    SalesComponent,
    SalesPdfComponent,
    SalesOverviewComponent,
    SalesOverviewPdfComponent,
    SalesDetailComponent,
    SalesDetailPdfComponent,
    LinkComponent,
    ConnectComponent,
    ConnectBudgetComponent,
    PeriodReportComponent,
    VendorDataComponent,
    AzureLinkComponent,
    MarketingLinkComponent,
    MarketingDataComponent,
    CommissionDataComponent,
    CommissionLinkComponent,
    KalcorLinkComponent,
    KalcorDataComponent,
    ExchangeLinkComponent,
    ExchangeDataComponent,
    FreightCndLinkComponent,
    FreightCndDataComponent,
    VendorReportDataComponent,
    VendorReportLinkComponent,
    AllLinkComponent,
    AllDataComponent,
    ExhibitAComponent],
  imports: [
    CommonModule,
    PortalRoutingModule,
    FormsModule,
    NgbModule
  ],
  providers: [
    DataLinkListResolver,
    NgbActiveModal
  ]
})
export class PortalModule { }
