import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule } from '@angular/forms';
import { Subject, fromEventPattern, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Order, OrderPDF } from '../../models/order-model';
import { OrderService } from '../../services/order.service';
import { Customer } from '../../models/customer.model';
import { CustomerService } from '../../services/customer.service';
import { ShipToAddress } from '../../models/shipto.model';
import { ShipToAddressService } from '../../services/ship-to-address.service';
import { Vendor } from '../../models/vendor-model';
import { VendorService } from '../../services/vendor.service';
import { PickupLocation } from '../../models/pickup-location.model';
import { PickupLocationService } from '../../services/pickup-location.service';
import { Warehouse } from '../../models/warehouse.model';
import { WarehouseService } from '../../services/warehouse.service';
import { FreightType } from '../../models/freight-type-model';
import { FreightTypeService } from '../../services/freight-type.service';
import { Location} from '../../models/location-model';
import { LocationService } from '../../services/location.service';
import { Transportation } from '../../models/transportation.model';
import { TransportationService } from '../../services/transportation.service';
import { Product } from '../../models/product.model';
import { ProductService } from '../../services/product.service';
import { Brand } from '../../models/brand.model';
import { BrandService } from '../../services/brand.service';
import { OrderItems } from '../../models/orderItems.model';
import { OrderItemsService } from '../../services/order-items.service';
import { Category } from 'src/app/models/category.model';
import { Broker } from '../../models/broker.model';
import { BrokerService } from '../../services/broker.service';
import { Big } from 'big.js';
import * as jsPDF from 'jspdf';
import * as html2pdf from 'html2pdf.js';
import { TermsService } from '../../services/terms.service';
import { Term } from '../../models/term-model';
import { SorterService } from '../../services/sorter.service';
import { Sorter2Service } from '../../services/sorter2.service';
import { Sorter3Service } from '../../services/sorter3.service';
import { ShipToBrokerService } from '../../services/ship-to-broker.service';
import { ShipToBroker } from '../../models/shipto-broker';
import { SalesManager } from 'src/app/models/sales-manager.model';
import { BillingAgent } from 'src/app/models/billing-agent.model';
import { BillingAgentService } from 'src/app/services/billing-agent.service';

@Component({
  selector: 'app-order-edit',
  templateUrl: './order-edit.component.html',
  styleUrls: ['./order-edit.component.scss']
})
export class OrderEditComponent implements OnInit {
  order: Order;
  orderSelected: OrderPDF;
  listing: Product[];
  selectedListing: Product[];
  customer: Customer[];
  customerOriginal: Customer[];
  salesmanager: SalesManager[];
  billingAgent: BillingAgent[];
  shipTo: ShipToAddress[];
  shipToOriginal: ShipToAddress[];
  vendor: Vendor[];
  vendorOriginal: Vendor[];
  // pickup: PickupLocation[];
  pickup = [];
  pickupOriginal: PickupLocation[];
  warehouse: Warehouse[];
  wh: any;
  brand: Brand[];
  title = 'SALES ORDER INFORMATION';
  newOne = true;
  orderForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  freightType: FreightType[];
  location: Location[];
  transport: Transportation[];
  alertType = "success";
  items: OrderItems;
  itemsEntered = [];
  itemsPosted: number;
  preserveValue = false;
  broker: Broker[];
  grayRow = [];
  totalCases: number;
  totalPallets: number;
  totalGrossLbs: number;
  totalGrosskg: number;
  totalNetLbs: number;
  totalNetkg: number;
  totalCaseCost: number;
  totalPalletsConfirmed: number;
  orderItems: any;
  orderItems2: any;
  shipOrderBtn = false;
  invoiceOrderBtn = false;
  salesOrderPrintBtn = false;
  slipPrintBtn = false;
  shipPrintBtn = false;
  invoicePrintBtn = false;
  poPrintBtn = false;
  deleteOrderBtn = false;
  itemsLoaded = false;
  billToSub: Subscription;
  vendorSub: Subscription;
  terms: Term[];
  reportName = '';
  reportNames = [
    {
    Name: 'Sales Order', 
    Id: 'my-sales-order',
    filename: 'Sales Order',
    Orientation: 'landscape',
    TopMargin: .1,
    LRMargin: .6
    },
    {
      Name: 'Packing Slip', 
      Id: 'my-packing-slip',
      filename: 'Packing Slip',
      Orientation: 'landscape',
      TopMargin: .6,
      LRMargin: .3
    },
    {
      Name: 'Shipping', 
      Id: 'my-shipping',
      filename: 'Shipping',
      Orientation: 'landscape',
      TopMargin: .1,
      LRMargin: .6
    },
    {
      Name: 'Commercial Invoice', 
      Id: 'my-commercial-invoice',
      filename: 'Commercial Invoice',
      Orientation: 'landscape',
      TopMargin: .1,
      LRMargin: .6
    },    
    {
      Name: 'Vendor Sales Order', 
      Id: 'my-sales-order-vendor',
      filename: 'Vendor Sales Order',
      Orientation: 'landscape',
      TopMargin: .1,
      LRMargin: .6
    },
    {
      Name: 'Invoice', 
      Id: 'my-invoicing',
      filename: 'Invoice',
      Orientation: 'landscape',
      TopMargin: .1,
      LRMargin: .6
    }  
  ];
  currentIndex: number;
  brandId: number;
  vendorPurchaseCaseCost = true;
  fOBNetVendorCost = false;
  listingId: number;
  commission1: number;
  commission2: number;
constructor(
    private orderService: OrderService,
    private customerService: CustomerService,
    private shipToService: ShipToAddressService,
    private vendorService: VendorService,
    private pickupService: PickupLocationService,
    private warehouseService: WarehouseService,
    private productService: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private freightService: FreightTypeService,
    private locationService: LocationService,
    private brandService: BrandService,
    private transportService: TransportationService,
    private itemService: OrderItemsService,
    private brokerService: BrokerService,
    private checkRef: ChangeDetectorRef,
    private termSvc: TermsService,
    private sort3Svc: Sorter3Service,
    private sort2Svc: Sorter2Service,
    private sortSvc: SorterService,
    private shipToBrokerSvc: ShipToBrokerService,
    private billingAgentSvc: BillingAgentService
  ) { }

  ngOnInit() {
    this.orderForm = this.fb.group({
      orderStatus: [{ value: '', disabled: true }],
      salesOrderNo: [{ value: '', disabled: true }],
      shipmentDate: [''],
      brokerId: [''],
      broker2Id: [''],
      vendorPickupId: [''],
      billingAgentId: [{ value: '', disabled: true }],
      billToId: ['', Validators.required],
      shipToId: [''],
      deliveredDate: [''],
      deliveredTime: [''],
      freightTypeId: [''],
      appConfirmed: [''],
      notes: [''],
      orderType: [''],
      purchaseOrder: [''],
      salesOrderDate: [''],
      transportCo1: [''],
      transportCo2: [''],
      transportCo3: [''],
      transportCo4: [''],
      transportCo5: [''],
      vendorId: ['', Validators.required],
      whsid: [''],
      shippingLane1: [''],
      shippingLane2: [''],
      shippingLane3: [''],
      shippingLane4: [''],
      shippingLane5: [''],
      freightType1: [''],
      freightType2: [''],
      freightType3: [''],
      freightType4: [''],
      freightType5: [''],
      freightCost1: [''],
      freightCost2: [''],
      freightCost3: [''],
      freightCost4: [''],
      freightCost5: [''],
      freightCostSales1: [''],
      freightCostSales2: [''],
      freightCostSales3: [''],
      freightCostSales4: [''],
      freightCostSales5: [''],
      freightTotalCost1: [{ value: '', disabled: true }],
      freightTotalCost2: [{ value: '', disabled: true }],
      freightTotalCost3: [{ value: '', disabled: true }],
      freightTotalCost4: [{ value: '', disabled: true }],
      freightTotalCost5: [{ value: '', disabled: true }],
      shipDate1: [''],
      shipDate2: [''],
      shipDate3: [''],
      shipDate4: [''],
      shipDate5: [''],
      deliveryDate1: [''],
      deliveryDate2: [''],
      deliveryDate3: [''],
      deliveryDate4: [''],
      deliveryDate5: [''],
      salesMgrId: [''],
      shippingLaneEnd1: [''],
      shippingLaneEnd2: [''],
      shippingLaneEnd3: [''],
      shippingLaneEnd4: [''],
      shippingLaneEnd5: [''],
      palletGrossLbs: [''],
      palletCount: [{ value: '', disabled: true }],
    });
    this.listingId = 0;


    this.termSvc.getAll().subscribe(term => this.terms = term);
    this.brokerService.getAll().subscribe(broker => {
      this.broker = broker;
      this.billingAgentSvc.getAll().subscribe(bill => {
        this.billingAgent = bill;
      this.brandService.getAll().subscribe(brand => {
        this.brand = brand;
        this.productService.getListingCustomerOrder().subscribe(listing => {
          this.listing = this.sort2Svc.sorter(listing, 'brandCategory', 'itemDescription');
          this.transportService.getAll().subscribe(transport => {
            this.transport = transport;
            this.locationService.getAll().subscribe(location => {
              this.location = location;
              this.freightService.getAll().subscribe(freight => {
                this.freightType = freight;
                this.customerService.getAll().subscribe(customer => {
                  this.customerOriginal = customer;
                  this.shipToService.getAll().subscribe(ship => {
                    this.shipTo = [];
                    this.shipToOriginal = ship;
                    this.vendorService.getAll().subscribe(vendor => {
                      this.vendorOriginal = vendor;
                      this.pickupService.getAll().subscribe(pickup => {
                        this.pickup = [];
                        this.pickupOriginal = pickup;
                        this.warehouseService.getAll().subscribe(warehouse => {
                          this.warehouse = warehouse.filter(wh => wh.activeItem == 'Active');
                          this.wh = [];
                          this.warehouse.forEach(e => {
                            let company = e.company === null ? '' : e.company;
                            company += e.federalEstablishment === null ? '' : ' (Est. ' + e.federalEstablishment + ')';
                            const whouse = {id: e.id, company: company};
                            this.wh.push(whouse);
                          })
                          this.wh = this.sortSvc.sorter(this.wh, 'company');
                          this.route.data.subscribe(order => {
                            if (order.dataOrder !== undefined) {
                              this.title = 'SALES ORDER INFORMATION';
                              this.newOne = false;
                              this.orderForm.controls.vendorId.disable({emitEvent:false});
                              this.orderForm.controls.billToId.disable({emitEvent:false});
                              this.order = order.dataOrder;
                              this.brandId = this.order.brandId;
                              this.fillFormData();
                              this.vendor = this.vendorOriginal;
                              this.customer = this.customerOriginal;
                              this.filterPickUp();
                              this.filterShip();
                              this.itemService.getBySalesNumber(this.order.salesOrderNo).subscribe(data => {
                                this.orderItems = this.sort2Svc.sorter(data, 'brandCategory', 'itemDescription');
                                this.buttonEnabler();
                                this.createItems();
                                this.calcExtension();
                                this.onChanges2();
                                this.itemsLoaded = true;
                              },error => {
                                this.successMessage = 'Unable to process request';
                                this.alertType = "danger";
                              })
                            } else {
                              this.title = 'SALES ORDER INFORMATION';
                              this.newOne = true;
                              this.order = new Order();
                              this.initOrderInit();
                              this.brandId = 0;
                              this.orderForm.reset({emitEvent:false});
                              this.orderForm.controls.vendorId.enable({emitEvent:false});
                              this.orderForm.controls.billToId.enable({emitEvent:false});
                              this.fillFormData();
                              this.loadSelects();
                              this.onChanges();
                              this.onChanges2();
                              this.calcExtension();
                              this.itemsLoaded = true;
                            }
                          })
                        })
                      })
                    })
                  })
                })
              })
            })
          })
        })
      })
    })
    })
  setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);    

  }

  loadSelects() {
    this.customer = [];
    this.pickup = [];
    this.shipTo = [];
    this.itemsEntered = [];
    this.vendor = [];
    this.selectedListing = [];
    this.listing.forEach( list => {
      if (list.customerId) {
        const index = this.customer.findIndex( cust => cust.id == list.customerId);
        if (index == -1) {
          const custom = this.customerOriginal.find( c => c.id == list.customerId);
          if (custom) {
            this.customer.push(custom);
          }
        }
      }
    });
    this.customer = this.sortSvc.sorter(this.customer, 'company');
    this.shipTo = this.sortSvc.sorter(this.shipTo, 'company');
    this.vendor = this.sortSvc.sorter(this.vendor, 'company');
    // this.selectedListing = this.sort2Svc.sorter(this.selectedListing, 'brandCategory','itemDescription');
    if (!this.newOne) {
      this.preserveValue = true;
    }
    this.preserveValue = false;

  }

  fillFormData() {
    const orderStat = !this.order.orderStatus ? 'Sales Order' : this.order.orderStatus;
    let deliveredDate = null;
    let deliveredTime = null;
    if (this.order.deliveredDateAppt !== null) {
      deliveredDate = this.order.deliveredDateAppt.toString().substr(0,10)
      const indexPos = this.order.deliveredDateAppt.toString().indexOf('T');
      if (indexPos > -1) {
        deliveredTime = this.order.deliveredDateAppt.toString().substr(indexPos+1);
      }
    }
    this.orderForm.setValue({
      orderStatus: orderStat,
      brokerId: this.order.brokerId,
      broker2Id: this.order.broker2Id,
      salesOrderNo: this.order.salesOrderNo,
      shipmentDate: this.order.shipmentDate === null ? this.order.shipmentDate : this.order.shipmentDate.toString().substr(0,10),
      vendorPickupId: this.order.vendorPickupId,
      billingAgentId: this.order.billingAgentId,
      billToId: this.order.billToId,
      shipToId: this.order.shipToId,
      deliveredDate: deliveredDate,
      deliveredTime: deliveredTime,
      freightTypeId: this.order.freightTypeId,
      appConfirmed: this.order.appConfirmed,
      notes: this.order.notes,
      orderType: this.order.orderType,
      purchaseOrder: this.order.purchaseOrder,
      salesMgrId: this.order.salesMgrId,
      salesOrderDate: this.order.salesOrderDate === null ? this.order.salesOrderDate : this.order.salesOrderDate.toString().substr(0,10),
      transportCo1: this.order.transportCo1,
      transportCo2: this.order.transportCo2,
      transportCo3: this.order.transportCo3,
      transportCo4: this.order.transportCo4,
      transportCo5: this.order.transportCo5,
      vendorId: this.order.vendorId,
      whsid: this.order.whsid,
      shippingLane1: this.order.shippingLane1,
      shippingLane2: this.order.shippingLane2,
      shippingLane3: this.order.shippingLane3,
      shippingLane4: this.order.shippingLane4,
      shippingLane5: this.order.shippingLane5,
      freightType1: this.order.freightType1,
      freightType2: this.order.freightType2,
      freightType3: this.order.freightType3,
      freightType4: this.order.freightType4,
      freightType5: this.order.freightType5,
      freightCost1: this.order.freightCost1,
      freightCost2: this.order.freightCost2,
      freightCost3: this.order.freightCost3,
      freightCost4: this.order.freightCost4,
      freightCost5: this.order.freightCost5,
      freightCostSales1: this.order.freightCostSales1,
      freightCostSales2: this.order.freightCostSales2,
      freightCostSales3: this.order.freightCostSales3,
      freightCostSales4: this.order.freightCostSales4,
      freightCostSales5: this.order.freightCostSales5,
      freightTotalCost1: this.order.freightTotalCost1,
      freightTotalCost2: this.order.freightTotalCost2,
      freightTotalCost3: this.order.freightTotalCost3,
      freightTotalCost4: this.order.freightTotalCost4,
      freightTotalCost5: this.order.freightTotalCost5,
      shipDate1: this.order.shipDate1 === null ? this.order.shipDate1 : this.order.shipDate1.toString().substr(0,10),
      shipDate2: this.order.shipDate2 === null ? this.order.shipDate2 : this.order.shipDate2.toString().substr(0,10),
      shipDate3: this.order.shipDate3 === null ? this.order.shipDate3 : this.order.shipDate3.toString().substr(0,10),
      shipDate4: this.order.shipDate4 === null ? this.order.shipDate4 : this.order.shipDate4.toString().substr(0,10),
      shipDate5: this.order.shipDate5 === null ? this.order.shipDate5 : this.order.shipDate5.toString().substr(0,10),
      deliveryDate1: this.order.deliveryDate1 === null ? this.order.deliveryDate1 : this.order.deliveryDate1.toString().substr(0,10),
      deliveryDate2: this.order.deliveryDate2 === null ? this.order.deliveryDate2 : this.order.deliveryDate2.toString().substr(0,10),
      deliveryDate3:  this.order.deliveryDate3 === null ? this.order.deliveryDate3 : this.order.deliveryDate3.toString().substr(0,10),
      deliveryDate4: this.order.deliveryDate4 === null ? this.order.deliveryDate4 : this.order.deliveryDate4.toString().substr(0,10),
      deliveryDate5:  this.order.deliveryDate5 === null ? this.order.deliveryDate5 : this.order.deliveryDate5.toString().substr(0,10),
      shippingLaneEnd1: this.order.shippingLaneEnd1,
      shippingLaneEnd2: this.order.shippingLaneEnd2,
      shippingLaneEnd3: this.order.shippingLaneEnd3,
      shippingLaneEnd4: this.order.shippingLaneEnd4,
      shippingLaneEnd5: this.order.shippingLaneEnd5,
      palletGrossLbs: this.order.palletGrossLbs,
      palletCount: this.order.palletCount
    }, {emitEvent:false});
  }
  onChanges(): void {

    this.billToSub = this.orderForm.get('billToId').valueChanges.subscribe(val => {
      this.filterVendor(val);
    });
    this.vendorSub = this.orderForm.get('vendorId').valueChanges.subscribe(val => {
      this.filterPick(val);
    });
  }
  onChanges2(): void {
    this.orderForm.get('freightCost1').valueChanges.subscribe(val => {
      this.calculateFreightCost(1);
    })
    this.orderForm.get('freightCost2').valueChanges.subscribe(val => {
      this.calculateFreightCost(2);
    })
    this.orderForm.get('freightCost3').valueChanges.subscribe(val => {
      this.calculateFreightCost(3);
    })
    this.orderForm.get('freightCost4').valueChanges.subscribe(val => {
      this.calculateFreightCost(4);
    })
    this.orderForm.get('freightCost5').valueChanges.subscribe(val => {
      this.calculateFreightCost(5);
    })
    this.orderForm.get('freightCostSales1').valueChanges.subscribe(val => {
      this.calculateFreightCost(1);
    })
    this.orderForm.get('freightCostSales2').valueChanges.subscribe(val => {
      this.calculateFreightCost(2);
    })
    this.orderForm.get('freightCostSales3').valueChanges.subscribe(val => {
      this.calculateFreightCost(3);
    })
    this.orderForm.get('freightCostSales4').valueChanges.subscribe(val => {
      this.calculateFreightCost(4);
    })
    this.orderForm.get('freightCostSales5').valueChanges.subscribe(val => {
      this.calculateFreightCost(5);
    })
    this.orderForm.get('transportCo1').valueChanges.subscribe(val => {
      this.calculateFreightCost(1);
    })
    this.orderForm.get('transportCo2').valueChanges.subscribe(val => {
      this.calculateFreightCost(2);
    })
    this.orderForm.get('transportCo3').valueChanges.subscribe(val => {
      this.calculateFreightCost(3);
    })
    this.orderForm.get('transportCo4').valueChanges.subscribe(val => {
      this.calculateFreightCost(4);
    })
    this.orderForm.get('transportCo5').valueChanges.subscribe(val => {
      this.calculateFreightCost(5);
    })
    this.orderForm.get('shippingLane1').valueChanges.subscribe(val => {
      this.calculateFreightCost(1);
    })
    this.orderForm.get('shippingLane2').valueChanges.subscribe(val => {
      this.calculateFreightCost(2);
    })
    this.orderForm.get('shippingLane3').valueChanges.subscribe(val => {
      this.calculateFreightCost(3);
    })
    this.orderForm.get('shippingLane4').valueChanges.subscribe(val => {
      this.calculateFreightCost(4);
    })
    this.orderForm.get('shippingLane5').valueChanges.subscribe(val => {
      this.calculateFreightCost(5);
    })
    this.orderForm.get('palletCount').valueChanges.subscribe(val => {
      this.calcExtension();
    })
    this.orderForm.get('palletGrossLbs').valueChanges.subscribe(val => {
      this.calcExtension();
    })
}
  calculateFreightCost(index) {
    const shipLaneVar = 'shippingLane' + index;
    const transportVar = 'transportCo' + index;
    const costVar = 'freightCost' + index;
    const fcsPerCentVar = 'freightCostSales' + index;
    const totalCostVar = 'freightTotalCost' + index;
    const shiplane = this.orderForm.get(shipLaneVar).value;
    const transport = this.orderForm.get(transportVar).value;
    let cost = +this.orderForm.get(costVar).value;
    let fcs = +this.orderForm.get(fcsPerCentVar).value;
    if (shiplane || transport) {
    } else {
      if (cost != 0) {
        this.orderForm.controls[costVar].setValue(0);
        cost = 0;
      }
      if (fcs != 0) {
        this.orderForm.controls[fcsPerCentVar].setValue(0);
        fcs = 0;
      }
    }
    let totalCost = 0;
    // total cost [Cost + (Cost x FCS%) = Total Cost] 
    const bigNum: any = Big(fcs)
      .times(.01)
      .times(cost)
      .plus(cost)
      .round(2)
      totalCost = bigNum * 1;
     this.orderForm.controls[totalCostVar].setValue(totalCost);
  }
  filterVendor(id) {
    this.pickup = [];
    this.vendor = [];
    this.shipTo = [];
    let vendorId = null;
    if (id) {
      id = id * 1;
      this.listing.forEach( list => {
        if (list.customerId === id) {
          const brand = this.brand.find(b => b.id === list.brandId);
          this.brandId = list.brandId;
          // determine what brand (assosicated with vendor) are available per customer selected
          if (brand && brand.vendorId) {
            const vend = this.vendorOriginal.find(v => v.id === brand.vendorId);
            if (vend) {
              // add to vendor to select (prevent dups)
              const ven = this.vendor.find(v => v.id === vend.id);
              if (!ven) {
                this.vendor.push(vend);
              }
            }
          }
        }
      })
      this.vendor = this.sortSvc.sorter(this.vendor, 'company');

      this.shipToOriginal.forEach(s => {
        if (s.customerId === id) {
          const ship = this.shipTo.find(ship => ship.id === s.id);
          if (!ship) {
            this.shipTo.push(s);
          }
        }
      })

      if (!this.preserveValue) {
        if (this.shipTo.length === 1) {
          this.orderForm.controls.shipToId.setValue(this.shipTo[0].id);
        } else {
          this.orderForm.controls.shipToId.reset(null);
        }
      }

      if (this.vendor.length == 1) {
        this.orderForm.controls.vendorId.setValue(this.vendor[0].id);
        vendorId = this.vendor[0].id;
      } else {
        if (!this.preserveValue) {
          this.orderForm.controls.vendorId.reset(null);
        }
      }

    }
  }

  filterPickUp() {
    this.pickup = [];

    this.pickupOriginal.forEach(pick => {
      if (pick.vendorId === this.order.vendorId) {
          this.pickup.push({id: pick.id, company: pick.company});
      }
    });
  }

  filterShip() {
    this.shipTo = [];
    this.listing.forEach( list => {
      if (list.customerId === this.order.billToId) {
        const brand = this.brand.find(b => b.id === list.brandId);
        this.brandId = list.brandId;
        // determine what brand (assosicated with vendor) are available per customer selected
        if (brand && brand.vendorId == this.order.vendorId) {
          if (list.shipToIds) {
              this.listingId = list.id;
              const ret = list.shipToIds.split(',');
              ret.forEach( ships => {
                const shipId = parseInt(ships, 10);
                const shipSelectIndex = this.shipTo.findIndex(s => s.id === shipId);
                if (shipSelectIndex == -1) {
                  const shipOrg = this.shipToOriginal.find(ship => ship.id === shipId);
                  if (shipOrg) {
                    this.shipTo.push(shipOrg);
                  }
                }
              })
           } 
        }
      }
    })
  }

  filterPick(id) {
    this.pickup = [];
    if (id) {
      id = id * 1;
      this.pickupOriginal.forEach(pick => {
        if (pick.vendorId === id) {
          this.pickup.push(pick); 
        }
      });

      if (this.pickup.length === 1) {
        this.orderForm.controls.vendorPickupId.setValue(this.pickup[0].id);
      } else {
        if (!this.preserveValue) {
          this.orderForm.controls.vendorPickupId.reset(null);
        }
      }


      const customerId = this.orderForm.controls.billToId.value * 1;

      // ship to
      this.shipTo = [];

      let orderType = '';
      let transportCo1 = null;
      let transportCo2 = null;
      let transportCo3 = null;
      let transportCo4 = null;
      let transportCo5 = null;
      let shippingLane1 = null;
      let shippingLane2 = null;
      let shippingLane3 = null;
      let shippingLane4 = null;
      let shippingLane5 = null;
      let broker1 = null;
      let broker2 = null;
      let shippingLaneEnd1 = null;
      let shippingLaneEnd2 = null;
      let shippingLaneEnd3 = null;
      let shippingLaneEnd4 = null;
      let shippingLaneEnd5 = null;
      let freightType1 = null;
      let freightType2 = null;
      let freightType3 = null;
      let freightType4 = null;
      let freightType5 = null;
      let freightCost1 = null;
      let freightCost2 = null;
      let freightCost3 = null;
      let freightCost4 = null;
      let freightCost5 = null;
      let freightCostSales1 = null;
      let freightCostSales2 = null;
      let freightCostSales3 = null;
      let freightCostSales4 = null;
      let freightCostSales5 = null;
      let salesMgrId = null;
      let billingAgentId = null;

      let firstTime = true;
      this.selectedListing = [];
      this.listing.forEach( list => {
        if (list.customerId === customerId) {
          const brand = this.brand.find(b => b.id === list.brandId);
          this.brandId = list.brandId;
          // determine what brand (assosicated with vendor) are available per customer selected
          if (brand && brand.vendorId == id) {
            this.selectedListing.push(list);
            this.listingId = list.id;
            if (list.shipToIds) {
                const ret = list.shipToIds.split(',');
                ret.forEach( ships => {
                  const shipId = parseInt(ships, 10);
                  const shipSelect = this.shipTo.find(s => s.id === shipId);
                  if (!shipSelect) {
                    const shipOrg = this.shipToOriginal.find(ship => ship.id === shipId);
                    if (shipOrg) {
                      this.shipTo.push(shipOrg);
                    }
                  }
                })
             } 

             if (firstTime) {
               firstTime = false;
               orderType = list.orderType;
               shippingLane1 = list.location1Id;
               shippingLane2 = list.location2Id;
               shippingLane3 = list.location3Id;
               shippingLane4 = list.location4Id;
               shippingLane5 = list.location5Id;
               transportCo1 = list.transportCo1;
               transportCo2 = list.transportCo2;
               transportCo3 = list.transportCo3;
               transportCo4 = list.transportCo4;
               transportCo5 = list.transportCo5;
               broker1 = list.brokerId;
               broker2 = list.broker2Id;
               shippingLaneEnd1 = list.shippingLaneEnd1;
               shippingLaneEnd2 = list.shippingLaneEnd2;
               shippingLaneEnd3 = list.shippingLaneEnd3;
               shippingLaneEnd4 = list.shippingLaneEnd4;
               shippingLaneEnd5 = list.shippingLaneEnd5;
               freightType1 = list.freightType1;
               freightType2 = list.freightType2;
               freightType3 = list.freightType3;
               freightType4 = list.freightType4;
               freightType5 = list.freightType5;
               freightCost1 = list.freightCost1;
               freightCost2 = list.freightCost2;
               freightCost3 = list.freightCost3;
               freightCost4 = list.freightCost4;
               freightCost5 = list.freightCost5;
               freightCostSales1 = list.freightCostSales1;
               freightCostSales2 = list.freightCostSales2;
               freightCostSales3 = list.freightCostSales3;
               freightCostSales4 = list.freightCostSales4;
               freightCostSales5 = list.freightCostSales5;
               salesMgrId = list.salesMgrId;
               billingAgentId = list.billingAgentId;
             }
          }
        }
      })

      if (this.selectedListing.length > 0) {
        if (this.selectedListing[0]['brandCategory'] === undefined) {
          this.selectedListing.sort((a,b) => (a['brand'].localeCompare(b['brand']) || a['category'].localeCompare(b['category']) || a.itemDescription.localeCompare(b.itemDescription)));
        } else {
          this.selectedListing = this.sort2Svc.sorter(this.selectedListing, 'brandCategory', 'itemDescription');
        }
      }

      this.grayRow = [];
      this.selectedListing.forEach(list => {
        this.itemsEntered.push({cases: 0, caseCost: list.whsSelling, extCaseCost: 0});
        const gray = this.grayRow.length % 2 == 0 ? 'S' : '';
        this.grayRow.push(gray);
      })

      if (this.shipTo.length === 1) {
        this.orderForm.controls.shipToId.setValue(this.shipTo[0].id);
      } else {
        if (!this.preserveValue) {
          this.orderForm.controls.shipToId.reset(null);
        }
      }
      this.orderForm.controls.orderType.setValue(orderType);
      this.orderForm.controls.shippingLane1.setValue(shippingLane1);
      this.orderForm.controls.shippingLane2.setValue(shippingLane2);
      this.orderForm.controls.shippingLane3.setValue(shippingLane3);
      this.orderForm.controls.shippingLane4.setValue(shippingLane4);
      this.orderForm.controls.shippingLane5.setValue(shippingLane5);
      this.orderForm.controls.transportCo1.setValue(transportCo1);
      this.orderForm.controls.transportCo2.setValue(transportCo2);
      this.orderForm.controls.transportCo3.setValue(transportCo3);
      this.orderForm.controls.transportCo4.setValue(transportCo4);
      this.orderForm.controls.transportCo5.setValue(transportCo5);
      this.orderForm.controls.brokerId.setValue(broker1);
      this.orderForm.controls.broker2Id.setValue(broker2);

      this.orderForm.controls.freightType1.setValue(freightType1);
      this.orderForm.controls.freightType2.setValue(freightType2);
      this.orderForm.controls.freightType3.setValue(freightType3);
      this.orderForm.controls.freightType4.setValue(freightType4);
      this.orderForm.controls.freightType5.setValue(freightType5);
      this.orderForm.controls.freightCost1.setValue(freightCost1);
      this.orderForm.controls.freightCost2.setValue(freightCost2);
      this.orderForm.controls.freightCost3.setValue(freightCost3);
      this.orderForm.controls.freightCost4.setValue(freightCost4);
      this.orderForm.controls.freightCost5.setValue(freightCost5);
      this.orderForm.controls.freightCostSales1.setValue(freightCostSales1);
      this.orderForm.controls.freightCostSales2.setValue(freightCostSales2);
      this.orderForm.controls.freightCostSales3.setValue(freightCostSales3);
      this.orderForm.controls.freightCostSales4.setValue(freightCostSales4);
      this.orderForm.controls.freightCostSales5.setValue(freightCostSales5);
      this.orderForm.controls.shippingLaneEnd1.setValue(shippingLaneEnd1);
      this.orderForm.controls.shippingLaneEnd2.setValue(shippingLaneEnd2);
      this.orderForm.controls.shippingLaneEnd3.setValue(shippingLaneEnd3);
      this.orderForm.controls.shippingLaneEnd4.setValue(shippingLaneEnd4);
      this.orderForm.controls.shippingLaneEnd5.setValue(shippingLaneEnd5);
      this.orderForm.controls.salesMgrId.setValue(salesMgrId);
      this.orderForm.controls.billingAgentId.setValue(billingAgentId);
      const vend = this.vendorOriginal.find(v => v.id === id);
      
      //lock down vendor and customer after vendor is selected
      this.orderForm.controls.vendorId.disable({emitEvent:false});
      this.orderForm.controls.billToId.disable({emitEvent:false});
    }

  }
  createItems() {
    this.selectedListing = [];
    this.itemsEntered = [];
    this.grayRow = [];
    this.orderItems = this.sort2Svc.sorter(this.orderItems, 'brandCategory', 'itemDesciption');
    this.orderItems.forEach(element => {
      this.selectedListing.push(element);
      let cases = element.cases;
      if (this.order.orderStatus == 'Shipped' || this.order.orderStatus == 'Invoiced' || this.order.orderStatus == 'Closed') {
        cases = element.shipped ? element.shipped : cases;
      } 
      this.itemsEntered.push({cases: cases, caseCost: element.caseCost, extCaseCost: element.extCaseCost});
      const gray = this.grayRow.length % 2 == 0 ? '' : 'S';
      this.grayRow.push(gray);
    });
    this.selectedListing = this.sort2Svc.sorter(this.selectedListing, 'brandCategory', 'itemDescription');
  }
  onSubmit() {
    if (this.orderForm.invalid) {
      this.success.next(`Please enter required fields: Bill To, Vendor, Confirm Pallet Count.`);
      this.alertType = "danger";
      return;
    }
    if (this.orderForm.controls.appConfirmed.value === 'Yes') {
      if (!this.orderForm.controls.deliveredDate.value || !this.orderForm.controls.deliveredTime.value) {
        this.success.next(`Please enter date and time for delivery.`);
        this.alertType = "danger";
        return;
      } else {
          this.order.deliveredDateAppt  = new Date(this.orderForm.controls.deliveredDate.value + 'T' + this.orderForm.controls.deliveredTime.value + 'Z');
      }
    } else {
      if (!this.orderForm.controls.deliveredDate.value) {
        this.success.next(`Please enter date for delivery.`);
        this.alertType = "danger";
        return;
      }
      const ret = this.orderForm.controls.deliveredDate.value.split('-');
      this.order.deliveredDateAppt  = new Date(ret[0], ret[1]-1, ret[2]);
    }

    let salesMessage = '';
    if (this.order.orderStatus == 'Shipped') {
      salesMessage = 'Reminder: You must re-process in Ship Order. Continue?';
    }

    if (this.order.orderStatus == 'Invoiced') {
      salesMessage = 'Reminder: You must re-process in Ship and Invoice Order. Continue?';
    }

    if (this.order.orderStatus == 'Closed') {
      salesMessage = 'Reminder: You must re-process in Ship, Invoice Order, and P/L. Continue?';
    }

    if (salesMessage) {
      const ans = confirm(salesMessage);
      if (!ans) {
        return;
      }
      this.orderForm.controls['orderStatus'].setValue('Sales Order');
    }

    this.alertType = "success";
    this.order.brokerId = 0;
    this.order.broker2Id = 0; 
    this.updateOrder();
    this.buttonEnabler();
    this.commission1 = 0;
    this.commission2 = 0;
    if (this.listingId > 0) {
      const shipToId = this.orderForm.controls['shipToId'].value;
      if (shipToId) {
        this.shipToBrokerSvc.getList(this.listingId).subscribe(ship => {
          const shipIndex = ship.findIndex(x => x.shipToId == shipToId);
          if (shipIndex > -1) {
             this.order.brokerId = ship[shipIndex].brokerId;
             this.order.broker2Id = ship[shipIndex].broker2Id; 
             this.commission1 = ship[shipIndex].commission1 ? ship[shipIndex].commission1 : this.commission1;
             this.commission2 = ship[shipIndex].commission2 ? ship[shipIndex].commission2 : this.commission2;
          }
          this.submitContinue();
        }, error => {
          this.submitContinue();
        })
      } else {
        this.submitContinue();
      }
    } else {
      this.submitContinue();
    }
  }

  submitContinue() {
    if (this.newOne) {
      this.order.id = 0;
      this.orderService.create(this.order).subscribe(data => {
        this.createOrderItems(data);
      }, error => {
        alert("Unable to Save Order.");
      });
    } else {
      this.orderService.update(this.order).subscribe(data => {
        this.updateOrderItems();
      }, error => {
        alert("Unable to Update Order.");
      });
    }
  }
  updateOrder() {
    for (const item in this.orderForm.controls) {
      if (this.orderForm.controls.hasOwnProperty(item)) {
          this.order[item] = this.orderForm.controls[item].value;
      }
    }
    const custIndex = this.customerOriginal.findIndex(cust => this.order.billToId == cust.id);
    this.order.termsId = custIndex ? this.customerOriginal[custIndex].termsId : null;
    this.order.brandId = this.brandId ? this.brandId : this.order.brandId;
  }
  getBrand() {
    let ret = 0;
    return ret;
  }
  updateOrderItems() {
    let totalItems = 0;
    this.itemsPosted = 0;
    this.orderItems.forEach((item, index) => {
      totalItems++;
      item.cases = this.itemsEntered[index].cases;
      item.extCaseCost = this.itemsEntered[index].extCaseCost;
      item.commission1 = this.commission1;
      item.commission2 = this.commission2;
      this.itemService.updateCase(item).subscribe(dataRet => {
        this.itemsPosted++;
        if (this.itemsPosted >= totalItems) {
          this.success.next(`Successfully added.`);
        }
      });
    });
  }

  createOrderItems(data) {
    let totalItems = 0;
    this.itemsPosted = 0;
    this.selectedListing.forEach((list, index) => {
      if (list.customerId === data.billToId) {
        const brand = this.brand.find(b => b.id === list.brandId);
        if (brand && brand.vendorId == data.vendorId) {
          totalItems++;
          this.loadItems(list, data, index);
          this.itemService.create(this.items).subscribe(dataRet => {
            this.itemsPosted++;
            if (this.itemsPosted >= totalItems) {
              this.success.next(`Successfully added.`);
              this.cleanUp();
              this.router.navigate(['/sales/order-edit', data.id]);
            }
          })
        }
      }
    });
  }
  
  loadItems(list, data: Order, index) {
    this.items = new OrderItems();
    this.items.salesOrderNo = data.salesOrderNo;
    this.items.orderCode = list.orderCode;
    this.items.brand = list.brand;
    this.items.category = list.category;
    this.items.brandCategory = list.brand + ' / ' + list.category;
    this.items.itemDescription = list.itemDescription;
    this.items.size = list.size;
    this.items.cases = this.itemsEntered[index].cases;
    this.items.caseCost = list.whsSelling;
    this.items.extCaseCost = this.itemsEntered[index].extCaseCost;
    this.items.csGrossLbs = list.csGrossLbs;
    this.items.productType = list.productType;
    this.items.ti = list.ti;
    this.items.hi = list.hi;
    this.items.tiHi = list.ti + " x " + list.hi;
    this.items.csPerPallet = list.csPerPallet;
    this.items.purchaseCaseCost = list.fobNetVendorCost + list.clearingCs + list.freightCs + list.duty + list.royalty;
    this.items.billing = list.billing;
    this.items.billing1 = list.billing1;
    this.items.terms = list.terms;
    this.items.terms1 = list.terms1;
    this.items.commission1 = this.commission1;
    this.items.commission2  = this.commission2;
    this.items.kalcor = list.kalcor;
    this.items.kalcor1 = list.kalcor1;
    this.items.royalty = list.royalty;
    this.items.royalty1 = list.royalty1;
    this.items.marketing = list.marketing;
    this.items.marketing1 = list.marketing1;
    this.items.cndFrtCostPallet = list.cndFrtCostPallet;
    this.items.cndFrtCostCase = list.cndFrtCostCase;
    this.items.storageCostPallet = list.storageCostPallet;
    this.items.storageCostCase = list.storageCostCase;
    this.items.duty = list.duty;
    this.items.clearingCs = list.clearingCs;
    this.items.clearingCost = list.clearingCost;
    this.items.palletPerTruck = list.palletPerTruck;
    this.items.freightCs = list.freightCs;
    this.items.csGrossKg = list.csGrossKg;
    this.items.exchangeBudget = list.exchangeBudget;
    this.items.currencyCode = list.currencyCode;
    this.items.csNetLbs = list.csNetLbs;
    this.items.csNetKg = list.csNetKg;
    this.items.cndFrtCostLb = list.cndFrtCostLb;
    this.items.fobDifference = list.fobDifference;
    this.items.palletType = list.palletType1;
  }
  editShippingItems() {
    if (this.order.id) {
      this.router.navigate(['/sales/shipping-edit', this.order.id]);
    }
  }
  editInvoice() {
    if (this.order.id) {
      this.router.navigate(['/sales/invoicing-edit', this.order.id]);
    }
  }
  onDelete() {
    if (this.order.id) {
      if (confirm('Delete this item?'))
      this.orderService.remove(this.order.id).subscribe(data => {
        this.router.navigate(['/sales/order-list']);
      });
    }
  }
  caseChange(event: any, index: number) {
    this.itemsEntered[index].cases = event.target.value * 1;
    this.itemsEntered[index].extCaseCost = this.itemsEntered[index].cases * this.itemsEntered[index].caseCost;
    this.calcExtension();
  }

  calcExtension() {
    this.totalCases = 0;
    this.totalPallets = 0;
    this.totalPalletsConfirmed = 0;
    this.totalGrossLbs = 0;
    this.totalCaseCost = 0;
    this.totalGrosskg = 0;
    this.totalNetLbs = 0;
    this.totalNetkg = 0;

    this.itemsEntered.forEach((element, index) => {
      let cases = 0;
      if (element.cases) {
        cases = element.cases * 1;
      }

      let caseCost = 0;
      let extCaseCost = 0;
      if (this.itemsEntered[index].caseCost) {
        caseCost = this.itemsEntered[index].caseCost * 1;
      }
      extCaseCost = cases * caseCost;
      this.totalCases += cases;
      if (this.selectedListing[index].csPerPallet != 0) {
        const bigNum: any = Big(cases)
        .div(this.selectedListing[index].csPerPallet)
        .round(0, 3);
        let pallets = bigNum * 1;
        if (pallets == 0 && cases !=0 ) {
          pallets = 1;
        }
        this.totalPallets += pallets;
      }
      this.totalPalletsConfirmed = this.order.orderStatus === 'Sales Order' || this.order.orderStatus === null ? this.totalPallets : this.order.palletCount;
      this.orderForm.controls["palletCount"].setValue(this.totalPalletsConfirmed);
      this.totalCaseCost += extCaseCost;
      this.totalGrossLbs += this.selectedListing[index].csGrossLbs * cases;
      this.totalGrosskg += this.selectedListing[index].csGrossKg * cases;
      this.totalNetLbs += this.selectedListing[index].csNetLbs * cases;
      this.totalNetkg += this.selectedListing[index].csNetKg * cases;
    });
    const palletLbs = isNaN(this.orderForm.controls["palletGrossLbs"].value) ? 0 : this.orderForm.controls["palletGrossLbs"].value * 1;
    const bigNum: any = Big(palletLbs)
      .times(.453592)
      .round(2);
    const palletKg = bigNum * 1;
    this.totalGrossLbs += this.totalPalletsConfirmed * palletLbs;
    this.totalGrosskg += this.totalPalletsConfirmed * palletKg;
  }
  buttonEnabler() {
    if (!this.order.orderStatus) {
      return;
    }

    this.shipOrderBtn = true;
    this.salesOrderPrintBtn = true;
    this.slipPrintBtn = true;
    this.poPrintBtn = true;
    this.deleteOrderBtn = true;
    this.invoiceOrderBtn = false;

    if (this.order.orderStatus == 'Sales Order') {
      return;
    }
    if (this.order.invoiceNumber) {
      this.invoicePrintBtn = true;
    }
    this.shipPrintBtn = true;
    this.deleteOrderBtn = false;
    if (this.order.orderStatus == 'Shipped' || 
        this.order.orderStatus == 'Invoiced' || 
        this.order.orderStatus == 'Closed') {
      this.invoiceOrderBtn = true;
    }
  }
  updateOrderSelected() {
    this.orderSelected = new OrderPDF();
    this.orderSelected.id = this.order.id;
    this.orderSelected.orderStatus = this.order.orderStatus;
    this.orderSelected.orderType = this.order.orderType;
    this.orderSelected.salesOrderNo = this.order.salesOrderNo;
    this.orderSelected.purchaseOrder = this.order.purchaseOrder;
    this.orderSelected.salesOrderDate = this.order.salesOrderDate;
    this.orderSelected.shipmentDate = this.order.shipmentDate;
    this.orderSelected.deliveredDateAppt = this.order.deliveredDateAppt;
    this.orderSelected.invoiceNumber = this.order.invoiceNumber;
    
    this.orderSelected.billToId = this.order.billToId;
    this.orderSelected.paymentTerms = '';
    if (this.orderSelected.billToId) {
      const cust = this.customerOriginal.find(c => c.id == this.orderSelected.billToId);
      this.orderSelected.billTo = cust.company;
      this.orderSelected.billAddress = cust.address;
      this.orderSelected.billCSZ = cust.city.trim() + ', ' + cust.stateProvince.trim() + " " + cust.zipPostalCode;
      if (cust.termsId) {
        const termIndex = this.terms.findIndex(term => term.id == cust.termsId);
        if (termIndex) {
          this.orderSelected.paymentTerms = this.terms[termIndex].paymentTerms;
        }
      }
  }

  this.orderSelected.shipToId = this.order.shipToId;
    if (this.orderSelected.shipToId) {
      const ship = this.shipToOriginal.find(s => s.id == this.orderSelected.shipToId);
      this.orderSelected.shipTo = ship.company;
      this.orderSelected.shipToAddress = ship.address;
      this.orderSelected.shipToCSZ = ship.city.trim() + ', ' + ship.stateProvince.trim() + " " + ship.zipPostalCode;
    }

    this.orderSelected.vendorId = this.order.vendorId;
    if (this.orderSelected.vendorId) {
      const vend = this.vendorOriginal.find(v => v.id == this.orderSelected.vendorId);
      this.orderSelected.vendor = vend.company;
      this.orderSelected.vendorInfo = vend.company.trim() + ' ' + vend.address + ' ' + vend.city.trim() + ', ' + vend.stateProvince.trim() + " " + vend.zipPostalCode;
      // if (vend.termsId) {
      //   const trm = this.terms.find(t => t.id == vend.termsId);
      //   this.orderSelected.paymentTerms = trm.paymentTerms;
      // }
    }

    if (this.order.brokerId) {
      const broke = this.broker.find(b => b.id == this.order.brokerId);
      this.orderSelected.broker = broke.company;
    }

    this.orderSelected.appConfirmed = this.order.appConfirmed;
    this.orderSelected.notes = this.order.notes;

    if (this.order.vendorPickupId) {
      const pick = this.pickupOriginal.find(p => p.id == this.order.vendorPickupId);
      this.orderSelected.pickCompany = pick.company;
      this.orderSelected.pickAddress = pick.address;
      this.orderSelected.pickCSZ = pick.city.trim() + ', ' + pick.stateProvince.trim() + " " + pick.zipPostalCode;
    }



    if (this.order.freightTypeId) {
      const frt = this.freightType.find(f => f.id == this.order.freightTypeId);
      this.orderSelected.freightType = frt.freightType;
    }

    if (this.order.shippingLane1) {
      const loc = this.location.find(l => l.id == this.order.shippingLane1);
      this.orderSelected.location1 = loc.location;
    }

    if (this.order.shippingLane2) {
      const loc = this.location.find(l => l.id == this.order.shippingLane2);
      this.orderSelected.location2 = loc.location;
    }

    if (this.order.shippingLane3) {
      const loc = this.location.find(l => l.id == this.order.shippingLane3);
      this.orderSelected.location3 = loc.location;
    }

    if (this.order.shippingLane4) {
      const loc = this.location.find(l => l.id == this.order.shippingLane4);
      this.orderSelected.location4 = loc.location;
    }

    if (this.order.shippingLane5) {
      const loc = this.location.find(l => l.id == this.order.shippingLane5);
      this.orderSelected.location5 = loc.location;
    }

    if (this.order.shippingLaneEnd1) {
      const loc = this.location.find(l => l.id == this.order.shippingLaneEnd1);
      this.orderSelected.locationEnd1 = loc.location;
    }

    if (this.order.shippingLaneEnd2) {
      const loc = this.location.find(l => l.id == this.order.shippingLaneEnd2);
      this.orderSelected.locationEnd2 = loc.location;
    }

    if (this.order.shippingLaneEnd3) {
      const loc = this.location.find(l => l.id == this.order.shippingLaneEnd3);
      this.orderSelected.locationEnd3 = loc.location;
    }

    if (this.order.shippingLaneEnd4) {
      const loc = this.location.find(l => l.id == this.order.shippingLaneEnd4);
      this.orderSelected.locationEnd4 = loc.location;
    }

    if (this.order.shippingLaneEnd5) {
      const loc = this.location.find(l => l.id == this.order.shippingLaneEnd5);
      this.orderSelected.locationEnd5 = loc.location;
    }

    this.orderSelected.shipDate1 = this.order.shipDate1;
    this.orderSelected.shipDate2 = this.order.shipDate2;
    this.orderSelected.shipDate3 = this.order.shipDate3;
    this.orderSelected.shipDate4 = this.order.shipDate4;
    this.orderSelected.shipDate5 = this.order.shipDate5;

    this.orderSelected.deliveryDate1 = this.order.deliveryDate1;
    this.orderSelected.deliveryDate2 = this.order.deliveryDate2;
    this.orderSelected.deliveryDate3 = this.order.deliveryDate3;
    this.orderSelected.deliveryDate4 = this.order.deliveryDate4;
    this.orderSelected.deliveryDate5 = this.order.deliveryDate5;


    if (this.order.transportCo1) {
      const trans = this.transport.find(t => t.id == this.order.transportCo1);
      this.orderSelected.transCompany1 = trans.company;
    }

    if (this.order.transportCo2) {
      const trans = this.transport.find(t => t.id == this.order.transportCo2);
      this.orderSelected.transCompany2 = trans.company;
    }

    if (this.order.transportCo3) {
      const trans = this.transport.find(t => t.id == this.order.transportCo3);
      this.orderSelected.transCompany3 = trans.company;
    }

    if (this.order.transportCo4) {
      const trans = this.transport.find(t => t.id == this.order.transportCo4);
      this.orderSelected.transCompany4 = trans.company;
    }

    if (this.order.transportCo5) {
      const trans = this.transport.find(t => t.id == this.order.transportCo5);
      this.orderSelected.transCompany5 = trans.company;
    }

    if (this.order.whsid) {
      const wh = this.warehouse.find(w => w.id == this.order.whsid);
      const fedEst = wh.federalEstablishment === null ? '' : ' (EST. ' + wh.federalEstablishment.trim() + ')';
      this.orderSelected.whseCompany = wh.company === null ? '' : wh.company.trim();
      this.orderSelected.whseAddress = wh.address;
      this.orderSelected.whseCSZ = wh.city.trim() + ', ' + wh.stateProvince.trim() + " " + wh.zipPostalCode;
      this.orderSelected.whseEst = this.isEmpty(wh.federalEstablishment) ? '' : 'Est. ' + wh.federalEstablishment;
    }
    this.orderSelected.palletCount = this.order.palletCount;
    this.orderSelected.palletGrossLbs = this.order.palletGrossLbs;
  }
  isEmpty(str) {
    return (!str || 0 === str.length);
  }

  printProcess(report) {
    this.currentIndex = this.reportNames.findIndex(r => r.Name == report);
    this.updateOrder();
    this.updateOrderSelected();
    this.orderItems.forEach((item, index) => {
      item.cases = this.itemsEntered[index].cases;
      item.caseCost = this.itemsEntered[index].caseCost;
      item.extCaseCost = this.itemsEntered[index].extCaseCost;
    });

    this.orderItems2 = this.orderItems.filter(item => item.cases);
    if (this.reportNames[this.currentIndex].Name == 'Vendor Sales Order') {
      if (this.orderSelected.vendorId) {
        const vend = this.vendorOriginal.find(v => v.id == this.orderSelected.vendorId);
        if (vend.termsId) {
          const trm = this.terms.find(t => t.id == vend.termsId);
          this.orderSelected.paymentTerms = trm.paymentTerms;
        }
      }

      
      if (this.orderItems.length > 0) {
        if (this.orderSelected.orderStatus == 'Shipped') {
          this.orderItems2 = this.orderItems.filter(item => item.shipped);
        } 
        this.orderItems2.forEach(element => {
          if (this.vendorPurchaseCaseCost) {
            element.caseCost = element.purchaseCaseCost;
          } else {
            element.caseCost = element.purchaseCaseCost - element.clearingCs - element.freightCs - element.duty - element.royalty;
          }
        });
      }
    }

    if (this.reportNames[this.currentIndex].Name == 'Invoice') {
      this.orderSelected.paymentTerms = 'Due on Receipt';
    }
    const myTotalCases = this.totalCases;
    const myTotalPallets = this.totalPallets;
    const myTotalPalletsConfirmed = this.totalPalletsConfirmed;
    const myTotalGrossLbs = this.totalGrossLbs;
    const myTotalCaseCost = this.totalCaseCost;
    const mytotalGrosskg = this.totalGrosskg;
    const myTotalNetLbs = this.totalNetLbs;
    const myTotalNetKg = this.totalNetkg;
    this.calculateTotals2();
    this.totalPallets = this.totalPalletsConfirmed;
    this.title = this.reportNames[this.currentIndex].Name;
    this.reportName = this.reportNames[this.currentIndex].Name;
    this.checkRef.detectChanges();
    var element = document.getElementById(this.reportNames[this.currentIndex].Id);
      var opt = {
        margin:       [this.reportNames[this.currentIndex].TopMargin, this.reportNames[this.currentIndex].LRMargin],
        filename:     this.orderSelected.salesOrderNo.trim() + ' ' + this.reportNames[this.currentIndex].filename + '.pdf',
        image:        { type: 'jpeg', quality: 0.98 },
        html2canvas:  { scale: 2 },
        jsPDF:        { unit: 'in', format: 'letter', orientation: this.reportNames[this.currentIndex].Orientation }
      };
      html2pdf().set(opt).from(element).save();
      this.reportName = '';
      this.totalCases = myTotalCases;
      this.totalPallets = myTotalPallets;
      this.totalGrossLbs = myTotalGrossLbs;
      this.totalCaseCost = myTotalCaseCost;
      this.totalGrosskg = mytotalGrosskg;   
      this.totalNetLbs = myTotalNetLbs;
      this.totalNetkg = myTotalNetKg;
      this.totalPalletsConfirmed = myTotalPalletsConfirmed;
  }
  calculateTotals2() {
    this.totalCases = 0;
    this.totalPallets = 0;
    this.totalPalletsConfirmed = isNaN(this.orderForm.controls["palletCount"].value) ? 0: this.orderForm.controls["palletCount"].value * 1;
    this.totalGrossLbs = 0;
    this.totalCaseCost = 0;
    this.totalNetkg = 0;
    this.totalNetLbs = 0;
    this.totalGrosskg = 0;
    this.orderItems2.forEach(element => {

      let cases = element.cases;
      switch(this.reportNames[this.currentIndex].Name) {
        case 'Commercial Invoice':
          cases = element.shipped;
          element.cases = element.shipped;
          break;
        case 'Vendor Sales Order':
          if (this.orderSelected.orderStatus == 'Shipped' || this.orderSelected.orderStatus == 'Invoiced' || this.orderSelected.orderStatus == 'Closed') {
            cases = element.shipped;
            element.cases = element.shipped;
          }
          break;
        case 'Invoice':
          if (this.orderSelected.orderStatus == 'Shipped' || this.orderSelected.orderStatus == 'Invoiced' || this.orderSelected.orderStatus == 'Closed') {
            cases = element.shipped;
            element.cases = element.shipped;
          }
          break;
        case 'Shipping':
          cases = element.shipped;
          break;
      }

      if (cases === null) {
        cases = 0;
      }
      if (cases) {
          cases = cases * 1;
      }
      if (isNaN(cases)) {
        cases = 0;
      }  
      let caseCost = 0;
      let extCaseCost = 0;
      if (element.caseCost) {
        caseCost = element.caseCost * 1;
      }
      extCaseCost = cases * caseCost;
      element.extCaseCost = extCaseCost;
      this.totalCases += cases;
      if (element.csPerPallet != 0) {
        const bigNum: any = Big(cases)
        .div(element.csPerPallet)
        .round(0, 3);
        let pallets = bigNum * 1;
        if (pallets == 0 && cases !=0 ) {
          pallets = 1;
        }
        this.totalPallets += pallets;
      }
      this.totalCaseCost += extCaseCost;
      this.totalGrossLbs += element.csGrossLbs * cases;
      this.totalGrosskg += element.csGrossKg * cases;
      this.totalNetLbs += element.csNetLbs * cases;
      this.totalNetkg += element.csNetKg * cases;
    });
    const palletLbs = isNaN(this.orderForm.controls["palletGrossLbs"].value) ? 0 : this.orderForm.controls["palletGrossLbs"].value * 1;
    const bigNum: any = Big(palletLbs)
      .times(.453592)
      .round(2);
    const palletKg = bigNum * 1;
    this.totalGrossLbs += this.totalPalletsConfirmed * palletLbs;
    this.totalGrosskg += this.totalPalletsConfirmed * palletKg;
  }

  showDialog() {  
    this.reportName='Confirm';
    this.checkRef.detectChanges();
  }  
  returnDialog(result){
    this.vendorPurchaseCaseCost = result == 1 ? true : false;
    this.fOBNetVendorCost = result == 2 ? true : false;
    this.printProcess("Vendor Sales Order"); 
  }
  cleanUp() {
   this.billToSub.unsubscribe;
   this.vendorSub.unsubscribe;
  }
  ngOnDestroy(): void {
  }

  initOrderInit(): void {
    this.order.id = 0;
    this.order.orderStatus = null;
    this.order.salesOrderNo = null;
    this.order.shipmentDate = null;
    this.order.vendorPickupId = null;
    this.order.billToId = null;
    this.order.shipToId = null;
    this.order.brandId = null;
    this.order.brokerId = null;
    this.order.broker2Id = null;
    this.order.cndBorderId = null;
    this.order.clearingCompanyId = null;
    this.order.deliveredDateAppt = null;
    this.order.freightTypeId = null;
    this.order.appConfirmed = null;
    this.order.driverName = null;
    this.order.driverCell = null;
    this.order.etaAtBorder = null;
    this.order.exchangeActual = null;
    this.order.exchangeBudget = null;
    this.order.invoiceNumber = null;
    this.order.lockedDate = null;
    this.order.lockedYesNo = null;
    this.order.yearMonthId = null;
    this.order.notes = null;
    this.order.orderType = null;
    this.order.termsId = null;
    this.order.terms = null;
    this.order.purchaseOrder = null;
    this.order.salesOrderDate = null;
    this.order.transportCo1 = null;
    this.order.transportCo2 = null;
    this.order.transportCo3 = null;
    this.order.transportCo4 = null;
    this.order.transportCo5 = null;
    this.order.vendorId = null;
    this.order.whsid = null;
    this.order.shippingLane1 = null;
    this.order.shippingLane2 = null;
    this.order.shippingLane3 = null;
    this.order.shippingLane4 = null;
    this.order.shippingLane5 = null;
    this.order.freightType1 = null;
    this.order.freightType2 = null;
    this.order.freightType3 = null;
    this.order.freightType4 = null;
    this.order.freightType5 = null;
    this.order.freightCost1 =  null;
    this.order.freightCost2 =  null;
    this.order.freightCost3 =  null;
    this.order.freightCost4 =  null;
    this.order.freightCost5 =  null;
    this.order.freightCostSales1 =  null;
    this.order.freightCostSales2 =  null;
    this.order.freightCostSales3 =  null;
    this.order.freightCostSales4 =  null;
    this.order.freightCostSales5 =  null;
    this.order.freightTotalCost1 =  null;
    this.order.freightTotalCost2 =  null;
    this.order.freightTotalCost3 =  null;
    this.order.freightTotalCost4 =  null;
    this.order.freightTotalCost5 =  null;
    this.order.shipDate1 =  null;
    this.order.shipDate2 =  null;
    this.order.shipDate3 =  null;
    this.order.shipDate4 =  null;
    this.order.shipDate5 =  null;
    this.order.deliveryDate1 =  null;
    this.order.deliveryDate2 =  null;
    this.order.deliveryDate3 =  null;
    this.order.deliveryDate4 =  null;
    this.order.deliveryDate5 =  null;
    this.order.salesMgrId =  null;
    this.order.billingAgentId = null;
    this.order.shippingLaneEnd1 =  null;
    this.order.shippingLaneEnd2 =  null;
    this.order.shippingLaneEnd3 =  null;
    this.order.shippingLaneEnd4 =  null;
    this.order.shippingLaneEnd5 =  null; 
    this.order.palletCount = null;
    this.order.palletGrossLbs = null;
  }
}
