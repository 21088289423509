<h5>Listing Setup List </h5>
<div>Filter</div>
<div class="col-lg-44">
  <label class="ms-3">
    Type of Costing:
    <select [(ngModel)]="activeListingsSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of activeListingsOptions" [value]="o">{{ o }}</option>
    </select>
    Cost Type:
    <select [(ngModel)]="costTypeSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of costTypeOptions" [value]="o">{{ o }}</option>
    </select>
    Customer:
    <select [(ngModel)]="customerSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of customerOptions" [value]="o.customerId">{{ o.customer }}</option>
    </select>
  </label>
</div>

<div class="col-lg-44">
  <label class="ms-3">
    Ship To:
    <select [(ngModel)]="shipToSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of shipToOptions" [value]="o.id">{{ o.company }}</option>
    </select>
    <!-- Vendor
    <select [(ngModel)]="vendorSelected" (change)="resetFilters()">
      <option [value]="0"></option>
      <option *ngFor="let o of vendors" [value]="o.vendorId">{{ o.vendor }}</option>
    </select>  -->
    Broker:
    <select [(ngModel)]="brokerSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of brokerOptions" [value]="o.id">{{ o.company }}</option>
    </select>
    Brand:
    <select [(ngModel)]="brandSelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of brandOptions" [value]="o.brandId">{{ o.brand }}</option>
    </select>
    Category:
    <select [(ngModel)]="categorySelected" (change)="resetFilters()">
      <option [value]=""></option>
      <option *ngFor="let o of categoryOptions" [value]="o.categoryId">{{ o.category }}</option>
    </select>
  </label>
  <button type="button" class="btn btn-primary mt-0 ms-3" (click)="getDefaultDataScreen()">Reset</button>
</div>
<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">Type of Costing</th>
    <th scope="col">Cost Type</th>
    <th scope="col">Product Order Code</th> 
    <th scope="col">Product Type</th> 
    <th scope="col">Order Type</th> 
    <th scope="col">Brand</th>
    <th scope="col">Category</th>
    <th scope="col">Item Description</th>
    <th scope="col">Size</th>
    <th scope="col">Customer</th>
    <th scope="col">Ship To</th>
    <th scope="col">Comm 1 %</th>
    <th scope="col">Comm 2 %</th>
    <th scope="col">Comm %</th>
    <th scope="col">Mrkt %</th>
    <th scope="col">Sales Manager</th>
    <th scope="col">Broker</th>
    
    </tr>
  </thead>
  <tbody>
  <tr *ngFor="let p of products; index as i">
    <td>{{ p.activeListings }}</td>
    <td>{{ p.costType }}</td>
    <td>
      <a class="pointer" (click)="onRowClicked(p)">
      <u class="text-primary">{{ p.orderCode }}</u>
      </a>
    </td>
    <td>{{ p.productType }}</td>
    <td>{{ p.orderType }}</td>
    <td>{{ p.brand }}</td>
    <td>{{ p.category }}</td>
    <td>{{ p.itemDescription }}</td>
    <td>{{ p.size }}</td>
    <td>{{ p.customer }}</td>
    <td>{{ getShipTo(p.shipToIds) }}</td>
    <td>{{ getCommission1(i) }}</td>
    <td>{{ getCommission2(i) }}</td>
    <td>{{ p.commission1 }}%</td>
    <td>{{ p.marketing1 }}%</td>
    <td>{{ p.salesManager }}</td>
    <td>{{ p.broker }}</td>
  </tr>
  </tbody>
</table>

<div *ngIf="products!==undefined">
  <ngb-pagination
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="collectionSize"
  [maxSize]="5"
  (pageChange)="loadPage($event)">
 </ngb-pagination>
</div>

<div class="btn-group" role="group" aria-label="Basic radio toggle button group">
      
  <input class="btn-check" type="radio" name="radio1" id="choice1" [(ngModel)]="pageSize"  (change)="loadPage($event)" autocomplete="off" checked [value]="25">
  <label class="btn btn-outline-primary" for="choice1">
    25
  </label>

  <input class="btn-check" type="radio" name="radio1" id="choice2" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="50" autocomplete="off">
  <label class="btn btn-outline-primary" for="choice2">
    50
  </label>

  <input class="btn-check" type="radio" name="radio1" id="choice3" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="100" autocomplete="off">
  <label class="btn btn-outline-primary" for="choice3">
    100
  </label>

</div>

<!-- <div class="btn-group btn-group-toggle" data-toggle="buttons" ngbRadioGroup name="radioBasic" [(ngModel)]="pageSize"  (change)="loadPage($event)">
  <label class="pt-2 pr-2" >Page Size</label>
  <label class="btn-outline-primary" ngbButtonLabel>
    <input type="radio" ngbButton [value]="25"> 25
  </label>
  <label class="btn-outline-primary" ngbButtonLabel>
    <input type="radio" ngbButton [value]="50"> 50
  </label>
  <label class="btn-outline-primary" ngbButtonLabel>
    <input type="radio" ngbButton [value]="100"> 100
  </label>
</div> -->