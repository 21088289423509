import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CreditService } from '../../../services/credit.service';
import { Credit, CreditShipToBroker, CreditBatch } from '../../../models/credit-model';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule } from '@angular/forms';
import { Subject, fromEventPattern, Subscription, throwError } from 'rxjs';
import { debounceTime, min } from 'rxjs/operators';
import { CustomerService } from '../../../services/customer.service';
import { VendorService } from '../../../services/vendor.service';
import { BrokerService } from '../../../services/broker.service';
import { BrandService } from '../../../services/brand.service';
import { CategoryService } from '../../../services/category.service';
import { Bucket } from '../../../models/bucket.model';
import { BucketService } from '../../../services/bucket.service';
import { OrderService } from '../../../services/order.service';
import { CreditShiptoService } from '../../../services/credit-shipto.service';
import { ShipToBrokerService } from '../../../services/ship-to-broker.service';
import { ShipToBroker } from '../../../models/shipto-broker';
import { Product } from '../../../models/product.model';
import { ProductService } from '../../../services/product.service';
import { ShipToAddressService } from '../../../services/ship-to-address.service';
import { ShipToAddress } from '../../../models/shipto.model';
import { SorterService } from '../../../services/sorter.service';
import { CreditBatchService } from '../../../services/credit-batch.service';
import { Big } from 'big.js';
import { CreditReasonsService } from 'src/app/services/credit-reasons.service';

@Component({
  selector: 'app-credit-edit',
  templateUrl: './credit-edit.component.html',
  styleUrls: ['./credit-edit.component.scss']
})
export class CreditEditComponent implements OnInit {
  credit: Credit;
  successMessage: string;
  private success = new Subject<string>();
  alertType = "success";
  staticAlertClosed = false;
  newOne = true;
  newId = 0;
  creditForm: UntypedFormGroup;
  customers: any;
  vendors: any;
  brokers: any;
  category: any;
  categoryMaster = [];
  brand: any;
  bucket: any;
  creditShipToBroker: CreditShipToBroker[];
  creditShipTo = [];
  order = [];
  po = [];
  shipTo: ShipToAddress[];
  totalTransactions: number;
  counter: number;
  toBeDeleted = [];
  transaction = [];
  listings = [];
  restore = false;
  indexProcess: number;
  listed = [];
  batchNoSelected: string;
  batchTypeSelected: string;
  batchStatusSelected: string;
  batchNumbers = [];
  batchTypes = [
    'Dot Deduction',
    'Vendor Payment',
    'Direct Payment',
    'Other Payment'
  ]
  creditBatch: CreditBatch;
  lastBatchNo: number;
  batch = [];
  creditReason: any;
  constructor(
    private router: Router,
    private creditService: CreditService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private custService: CustomerService,
    private vendService: VendorService,
    private brokerService: BrokerService,
    private brandService: BrandService,
    private categoryService: CategoryService,
    private bucketService: BucketService,
    private orderService: OrderService,
    private creditShipToSvc: CreditShiptoService,
    private shipToBrokerSvc: ShipToBrokerService,
    private productSvc: ProductService,
    private shipToService: ShipToAddressService,
    private checkRef: ChangeDetectorRef,
    private sortSvc: SorterService,
    private creditBatchSvc: CreditBatchService,
    private creditReasonsSvc: CreditReasonsService
  ) { }

  ngOnInit() {
    this.credit = new Credit();
    this.creditShipTo = [];
    this.creditShipToBroker = [];
    this.lastBatchNo = 999;

    this.creditForm = this.fb.group({
      creditDate: ['', Validators.required],
      creditNo: [{ value: '', disabled: true }],
      purchaseOrder: [''],
      poIndex: [''],
      billToId: ['', Validators.required],
      vendorId: ['', Validators.required],
      customerCreditNo: ['', Validators.required],
      creditStatus: [{ value: '', disabled: true }],
      creditType: ['', Validators.required],
      creditPromotionExplaination: [''],
      promoStartDate: [''],
      promoEndDate: [''],
      brandId: [''],
      categoryId: [''],
      cases: [''],
      billBackPerCase: [''],
      creditAmount: ['', Validators.required],
      bucket: ['', Validators.required],
      promotionalNumber: [''],
      creditReasonID: ['']
    }); 
    this.shipToService.getAll().subscribe(ship => {
      this.shipTo = ship;
    })
    this.creditReasonsSvc.getAll().subscribe(reason => {
      this.creditReason = reason;
    })

    this.creditBatchSvc.getOpen().subscribe(batch => {
      this.batch = batch;
      if (batch.length == 0) {
        this.creditBatchSvc.getLast().subscribe(batch => {
          if (batch && batch.batchNo) {
            this.lastBatchNo = batch.batchNo;
          }
          this.batchIt();
        })
      } else {
        this.creditBatchSvc.getLast().subscribe(batch => {
          this.lastBatchNo = batch.batchNo;
          this.batchIt();
        })
      }

    })   

    this.orderService.getPurchaseOrderOnly().subscribe(data => {
      this.po = data;
      this.custService.getCompany().subscribe(data => {
        this.customers = data;
        this.vendService.getCompany().subscribe(data => {
          this.vendors = data;
          this.brokerService.getCompany().subscribe(data => {
            this.brokers = data.filter(data => data.activeItem == 'Active');
            this.brandService.getCompany().subscribe(data => {
              this.brand = data;
              this.categoryService.getCompany().subscribe(data => {
                this.category = data;
                data.forEach(element => {
                  const temp = {
                    id: element.id,
                    category: element.category,
                    brandId: element.brandId
                  }
                  this.categoryMaster.push(temp);  
                });
                
                this.bucketService.getAll().subscribe(data => {
                  this.bucket = data;
                  this.route.data.subscribe( credit => {
                    if (credit.dataCredit !== undefined) {
                      this.credit = credit.dataCredit[0];
                      this.credit.broker1Id = 0;
                      this.credit.broker2Id = 0;
                      this.credit.commission1 = 0;
                      this.credit.commission2 = 0;
                      // this.credit.creditReasonID = this.credit.creditReasonID;
                      if (this.credit.brandId) {
                        this.category = this.categoryMaster.filter(c => c.brandId == this.credit.brandId);
                        this.creditForm.controls.categoryId.setValue(null);      
                      }
                      this.newOne = false;
                      this.fillFormData();
                      this.onChanges();
                      this.restore = true;
                      this.getListing(this.credit.vendorId, this.credit.billToId);
                      this.batchInsert();
//                      this.creditForm.controls.categoryId.setValue(this.credit.categoryId);      
                    } else {
                      this.newOne = true;
                      this.credit = new Credit();
                      this.initCredit();
                      this.creditForm.reset();
                      this.fillFormData();
                      this.onChanges();
                    }                
                  },error => {
                    this.successMessage = 'Unable to process request';
                    this.alertType = "danger";
                  })
                });                  
              });
            });
          });
      });
    });
  });

  setTimeout(() => this.staticAlertClosed = true, 20000);
  this.success.subscribe((message) => this.successMessage = message);
  this.success.pipe(
    debounceTime(5000)
    ).subscribe(() => this.successMessage = null);    
  }

  batchIt() {
    this.batch.forEach(element => {
      this.lastBatchNo = element.batchNo;
      const temp = {
        creditBatchId: element.creditBatchId,
        batchNo: element.batchNo,
        batchStatus: element.batchStatus
        }
      this.batchNumbers.push(temp);
    });
    this.fillBatch();
  }

  fillBatch() {
    //3.14.2023 changed length from 10 to 20
    //8/14/2023 changed length from 20 to 40
    //if (this.batchNumbers.length < 15) {
      if (this.batchNumbers.length < 40) {
      this.lastBatchNo++;
      this.creditBatchSvc.getOneBatch(this.lastBatchNo).subscribe(batch => {
        if (batch && batch.batchNo && batch.batchStatus === "Closed") {
        } else {
          this.batchAdd();
        }
        this.fillBatch();
      },error => {
        this.batchAdd();
        this.fillBatch();
      })
    }
  }

  batchAdd() {
    const temp = {
      creditBatchId: null,
      batchNo: this.lastBatchNo,
      batchStatus: ''
      }
    this.batchNumbers.push(temp);

  }
  batchInsert() {
    if (this.credit.batchNo) {
      const temp = {
        creditBatchId: null,
        batchNo: this.credit.batchNo,
        batchStatus: this.credit.batchStatus
        }
      this.batchNumbers.push(temp);    
      this.batchNumbers = this.sortSvc.sorter(this.batchNumbers, 'batchNo');

    }
  }

  getListing(vendorId, billToId) {
    if (vendorId > 0 && billToId > 0) {
      const brandListing = this.brand.filter(data => data.vendorId == vendorId);
      this.creditShipTo = [];
      this.productSvc.getFilteredListing('', '', billToId, 0, 0, 0, 0, 1, 999).subscribe(prod => {
        this.order = prod.item.filter(data => data.vendorId == vendorId);
        this.listings = [];
        this.order.forEach(data => {
          const shipToIds = this.splitNumbers(data.shipToIds);
          shipToIds.forEach(ship => {
            const index = this.creditShipTo.findIndex(x => x.shipToId == ship);
            if (index == -1) {
              const shipIndex = this.shipTo.findIndex(x => x.id == ship);
              let shipToCompany = '';

              if (shipIndex > -1) {
                shipToCompany = this.shipTo[shipIndex].company
              }

              let temp = {
                creditShipToBrokerId: 0,
                creditInfoId: this.credit.id,
                shipToId: ship,
                brokerId: 0,
                brokerId2: 0,
                commission1: 0,
                commission2: 0,
                shipToCompany: shipToCompany,
                creditPercent: 0,
                listingId: data.id,
                commissionDollars1: 0, 
                commissionDollars2: 0
              }

              this.creditShipTo.push(temp);
            }
          })
        })
        this.getShipToBroker();
      })
    }
  }

  getShipToBroker() {
    this.creditShipTo = this.sortSvc.sorter(this.creditShipTo, 'shipToCompany');
    this.indexProcess = -1;
    this.listed = [];
    this.loadUp();
  }
loadUp() {
  this.indexProcess++;
  if (this.indexProcess >= this.creditShipTo.length) {
    if (!this.newOne)  {
      this.creditShipToSvc.getBroker(this.credit.id).subscribe(data => {
        this.creditShipToBroker = data;
        this.restoreData();
      })
    }
    return;
  }
  
  const listingId = this.creditShipTo[this.indexProcess].listingId;
  if (this.listed.indexOf(listingId) > -1) {
     this.loadUp();
  } else {
    this.listed.push(listingId);
    this.shipToBrokerSvc.getCommission(listingId).subscribe(data => {
      data.forEach(e => {
        this.creditShipTo.forEach(x => {
          if (e.listingId == x.listingId && e.shipToId == x.shipToId) {
            x.brokerId = e.brokerId;
            x.brokerId2 = e.broker2Id;
            x.commission1 = e.commission1;
            x.commission2 = e.commission2;
          }
        })
      });
      this.loadUp();
    }, error => {
      this.loadUp();
    })
  }

}
  commUpdate(index) {

  }

  restoreData() {
    let insertData=[];
    this.creditShipToBroker.forEach((ship, index) => {
      let foundIt = false;
      this.creditShipTo.forEach((data, dataIndex) => {
        if (ship.shipToId == data.shipToId && data.creditPercent == 0) {
            data.creditShipToBrokerId = ship.creditShipToBrokerId;
            data.creditInfoId = ship.creditInfoId,
            data.brokerId = ship.brokerId;
            data.brokerId2 = ship.brokerId2;
            data.commission1 = ship.commission1;
            data.commission2 = ship.commission2;
            data.creditPercent = ship.creditPercent;
            data.commissionDollars1 = ship.commissionDollars1;
            data.commissionDollars2 = ship.commissionDollars2;
            foundIt = true;
          }
      })
      if (!foundIt) {
        insertData.push(index);
      }
    })

    if (insertData.length > 0) {
      insertData.forEach(index => {
        let shipToCompany = '';
        const shipIndex = this.shipTo.findIndex(x => x.id == this.creditShipToBroker[index].shipToId);
        if (shipIndex > -1) {
          shipToCompany = this.shipTo[shipIndex].company
        }
  
        let temp = {
          creditShipToBrokerId: this.creditShipToBroker[index].creditShipToBrokerId,
          creditInfoId: this.credit.id,
          shipToId: this.creditShipToBroker[index].shipToId,
          brokerId: this.creditShipToBroker[index].brokerId,
          brokerId2: this.creditShipToBroker[index].brokerId2,
          commission1: this.creditShipToBroker[index].commission1,
          commission2: this.creditShipToBroker[index].commission2,
          shipToCompany: shipToCompany,
          creditPercent: this.creditShipToBroker[index].creditPercent,
          commissionDollars1: this.creditShipToBroker[index].commissionDollars1, 
          commissionDollars2: this.creditShipToBroker[index].commissionDollars2
        }
        this.creditShipTo.push(temp);
      })
      this.creditShipTo = this.sortSvc.sorter(this.creditShipTo, 'shipToCompany');
    }
  }


  splitNumbers(ids) {
    let ret = [];
    if (ids) {
      let retCount = 0;
      const tempSplit = ids.split(',');
      if (tempSplit.length > 0) {
        tempSplit.forEach( id => {
          if (id) {
            ret.push(id * 1);
          }
        })
      }
    }

    return ret;
  }
  fillFormData() {
    const poIndex = this.po.findIndex(data => data.purchaseOrder == this.credit.purchaseOrder);
    this.creditForm.setValue({
      creditDate: this.credit.creditDate === null ? this.credit.creditDate : this.credit.creditDate.toString().substr(0,10),
      creditNo: this.credit.creditNo,
      purchaseOrder: this.credit.purchaseOrder,
      poIndex: poIndex == -1 ? '' : poIndex,
      billToId: this.credit.billToId,
      vendorId: this.credit.vendorId,
      customerCreditNo: this.credit.customerCreditNo,
      creditStatus: this.credit.creditStatus,
      creditType: this.credit.creditType,
      creditPromotionExplaination: this.credit.creditPromotionExplaination,
      promoStartDate: this.credit.promoStartDate === null ? this.credit.promoStartDate : this.credit.promoStartDate.toString().substr(0,10),
      promoEndDate: this.credit.promoEndDate === null ? this.credit.promoEndDate : this.credit.promoEndDate.toString().substr(0,10), 
      brandId: this.credit.brandId,
      categoryId: this.credit.categoryId,
      cases: this.credit.cases,
      billBackPerCase: this.credit.billBackPerCase,
      creditAmount: this.credit.creditAmount,
      bucket: this.credit.bucket,
      promotionalNumber: this.credit.promotionalNumber,
      creditReasonID: this.credit.creditReasonID
    });
  }
  onChanges(): void {
    this.creditForm.get('vendorId').valueChanges.subscribe(val => {
      this.getListing(val, this.creditForm.get('billToId').value );
    })
    this.creditForm.get('billToId').valueChanges.subscribe(val => {
      this.getListing(this.creditForm.get('vendorId').value, val);
    })
    this.creditForm.get('poIndex').valueChanges.subscribe(val => {
      this.selectCustVendor(val);
    })
    this.creditForm.get('brandId').valueChanges.subscribe(val => {
      this.category = this.categoryMaster.filter(c => c.brandId == val);
    })
  }
  selectCustVendor(e) {
    this.creditForm.controls.billToId.setValue(null);
    this.creditForm.controls.vendorId.setValue(null);      
    this.creditForm.controls.brandId.setValue(null);
    this.creditForm.controls.categoryId.setValue(null);      
    this.creditForm.controls.purchaseOrder.setValue(null);
    if (e) {
      const poIndex = +e;
      if (poIndex > -1) {
         const id = this.po[poIndex].id;
         this.creditForm.controls.purchaseOrder.setValue(this.po[poIndex].purchaseOrder);
         this.orderService.getPurchaseInfo(id).subscribe(data => {
           if (data.length) {
            this.creditForm.controls.billToId.setValue(data[0].billToId);
            this.creditForm.controls.vendorId.setValue(data[0].vendorId);
            this.creditForm.controls.brandId.setValue(data[0].brandId);
            const catIndex = this.category.findIndex(cat => cat.category == data[0].category);
            const categoryId = catIndex > -1 ? this.category[catIndex].id : null;
            this.creditForm.controls.categoryId.setValue(categoryId);      
           }
          })
      }
    }
}
  initCredit(): void {
    this.credit.billBackPerCase = null;
    this.credit.billToId = null;
    this.credit.brandId = null;
    this.credit.broker1Id = null;
    this.credit.broker2Id = null;
    this.credit.bucket = null;
    this.credit.cases = null;
    this.credit.categoryId = null;
    this.credit.commission1 = null;
    this.credit.commission2 = null;
    this.credit.creditAmount = null;
    this.credit.creditDate = null;
    this.credit.creditNo = null;
    this.credit.creditPromotionExplaination = null;
    this.credit.creditStatus = null;
    this.credit.creditType = null;
    this.credit.customerCreditNo = null;
    this.credit.id = 0;
    this.credit.promoEndDate = null;
    this.credit.promoStartDate = null;
    this.credit.purchaseOrder = null;
    this.credit.vendorId = null;
    this.credit.batchNo = null;
    this.credit.batchStatus = null;
    this.credit.batchType = null;
    this.credit.promotionalNumber = null;
    this.credit.creditReasonID = null;
}

  onSubmit(status) {
    if (this.creditForm.invalid) {
      this.success.next(`Please enter required fields.`);
      this.alertType = "danger";
      return;
    }
    let creditPct = 0;
    let brokerNotSelect = false;
   
    let commDol = [];
    let commDolIndex = 0;
    this.credit.creditAmount = this.creditForm.controls["creditAmount"].value;
    this.credit.creditAmount = this.credit.creditAmount === null ? 0 : this.credit.creditAmount * 1;
    this.creditShipTo.forEach((data, index) => {
      const perCent = data.creditPercent * 1;
      if (perCent != 0) {
        let commDolRow = {
          perCent: 0,
          commPercent1: 0,
          commPercent2: 0,
          commDol1: 0,
          commDol2: 0,
          commDolRow: 0,
          commIndex: 0
        }
        commDol.push(commDolRow);
        commDolIndex = commDol.length - 1;
        commDol[commDolIndex].commIndex = index;
        const bigNum: any = Big(perCent)
        .times(this.credit.creditAmount)
        .times(.01)
        .round(2);
        commDol[commDolIndex].commDolRow = bigNum * 1;
        if (data.commission1 != 0) {
          commDol[commDolIndex].commPercent1 = data.commission1;
          if (data.brokerId == 0) {
            brokerNotSelect = true;   
          }
        }

        if (data.commission2 != 0) {
          commDol[commDolIndex].commPercent2 = data.commission2;
          if (data.brokerId2 == 0) {
            brokerNotSelect = true;   
          }
        }
      
        if (data.brokerId == 0 && data.brokerId2 == 0) {
          brokerNotSelect = true;   
        }
      }
      creditPct += perCent;
    })
    if (creditPct != 100) {
      this.success.next(`Total % of Credit must be 100`);
      this.alertType = "danger";
      return;
    }

    
    if (brokerNotSelect) {
      this.success.next(`Select broker`);
      this.alertType = "danger";
      return;
    }

    let batch = '';
    if (this.credit.batchNo || this.credit.batchType || this.credit.batchStatus)  {
      if (!this.credit.batchNo) {
        this.success.next('Select Batch No.');
        this.alertType = "danger";
        return;
      }
      if (!this.credit.batchType) {
        this.success.next('Select Type of Batch');
        this.alertType = "danger";
        return;
      }
      if (!this.credit.batchStatus) {
        this.success.next('Select Batch Status');
        this.alertType = "danger";
        return;
      }
      batch = 'Batch No. ' + this.credit.batchNo + '/' + this.credit.batchType +'/';
      // Batch No. 1003/Vendor Payment/DE-4855071 
        this.creditBatchSvc.getOneBatch(this.credit.batchNo).subscribe( data => {
          if (data.batchStatus != this.credit.batchStatus) {
            data.batchStatus = this.credit.batchStatus;
            this.creditBatchSvc.update(data).subscribe(data => {
              // this.creditBatch = data;
            })
          }
      },error => {
        this.creditBatch = new CreditBatch();
        this.creditBatch.batchNo = this.credit.batchNo;
        this.creditBatch.batchStatus = this.credit.batchStatus;
        this.creditBatch.creditBatchId = 0;
        
        this.creditBatchSvc.create(this.creditBatch).subscribe(data => {
          this.creditBatch = data;
        })
      })

    }
    let customerCreditNo = this.creditForm.controls["customerCreditNo"].value;
       
    let nPosStart = -1;
    this.batchTypes.forEach(data => {
      let nPos = customerCreditNo.indexOf('/' + data + '/');
      if (nPos > -1) {
        nPosStart = nPos + data.length + 2;
      }
    })

    if (nPosStart > -1) {
      customerCreditNo = customerCreditNo.substr(nPosStart).trim();
    }
    customerCreditNo = batch + customerCreditNo;
    this.creditForm.controls["customerCreditNo"].setValue(customerCreditNo);


// Adjust if not add up to creditAmount
    commDolIndex = -1;
    let totalDollars = this.credit.creditAmount;
    let highestAmount = 0;
    commDol.forEach((dol, index) => {
      totalDollars -= dol.commDolRow;
      if (dol.commDolRow >= highestAmount) {
        highestAmount = dol.commDolRow;
        commDolIndex = index;
      }
    })
    if (commDolIndex > -1) {
      commDol[commDolIndex].commDolRow += totalDollars;
    }
// 
// compute commission dollars for each row
    commDol.forEach((dol, index) => {
      let multiplyer = 0;
      const totalPercentRow = dol.commPercent1 + dol.commPercent2;

      let bigNum: any;
      if (totalPercentRow != 0) {
        bigNum = Big(dol.commDolRow)
        .div(totalPercentRow);
        multiplyer = bigNum * 1;
      }

      bigNum = Big(dol.commPercent1)
      .times(multiplyer)
      .round(2);
      dol.commDol1 = bigNum * 1;
      
      bigNum = Big(dol.commPercent2)
      .times(multiplyer)
      .round(2);
      dol.commDol2 = bigNum * 1;

      const remainder = dol.commDolRow - dol.commDol1 - dol.commDol2;
      if (dol.commDol1 > dol.commDol2) {
        dol.commDol1 += remainder;
      }

      this.creditShipTo[dol.commIndex].commissionDollars1 = dol.commDol1;
      this.creditShipTo[dol.commIndex].commissionDollars2 = dol.commDol2;

    })

    this.alertType = "success";
    this.creditForm.controls.creditStatus.setValue(status);
    this.updateOrder();
    if (this.newOne) {
      this.creditService.getFilter('', '', '', this.credit.customerCreditNo, this.credit.billToId, this.credit.vendorId, 0, '',  1, 100).subscribe(data=> {
        if (data.count > 0) {
          const index = data.item.findIndex( e => e.creditAmount * 1 == this.credit.creditAmount);
          if (index > -1) {
            alert('Duplicate found. This will not be saved. See ' + data.item[index].creditNo.trim() + '.');
            return;
          }
        } else {
          this.continueUpdate();
        }
      });
    } else {
      this.continueUpdate();
    }
  }
  continueUpdate() {
    this.newId = this.credit.id;
    if (this.newOne) {
      this.credit.id = 0;
      this.newId = 0;
      this.creditService.create(this.credit).subscribe(data => {
        this.newId = data.id;
        this.updateCommission();
      }, error => {
        this.success.next(`Unable to update credit informtion`);
        this.alertType = "danger";
        return;
      })
    } else {
      this.creditService.update(this.credit).subscribe(data => {
        this.updateCommission();
      }, error => {
        this.success.next(`Unable to update credit informtion`);
        this.alertType = "danger";
        return;
      })
    }

  }

  updateOrder() {
    for (const item in this.creditForm.controls) {
      if (this.creditForm.controls.hasOwnProperty(item)) {
          this.credit[item] = this.creditForm.controls[item].value;
      }
    }
  }

  updateCommission() {
    this.totalTransactions = 0;
    this.counter = 0;
    this.toBeDeleted = [];
    this.transaction = [];
    let creditPct = 0;
    let creditShipToBrokerId = 0;
    let creditAmount = this.credit.creditAmount;
    this.creditShipTo.forEach((data, index) => {
      creditPct = data.creditPercent === null ? 0 : data.creditPercent * 1;
      creditShipToBrokerId = data.creditShipToBrokerId === null ? 0 : data.creditShipToBrokerId * 1;
      this.transaction.push("");
      if (creditPct == 0) {
        if (creditShipToBrokerId !=0 ) {
          this.toBeDeleted.push(data.creditShipToBrokerId);
          this.totalTransactions++;
          this.transaction[index] = "D";
        }
      } else {
        this.totalTransactions++;
        
        if (creditShipToBrokerId == 0) {
          this.transaction[index] = "C";
          if (this.newOne && this.newId) {
            data.creditInfoId = this.newId;
          }
        } else { 
          this.transaction[index] = "U";
        }
      }
    })
    
    if (this.totalTransactions == 0) {
      this.alertType = "success";
      this.monitorTransaction("success");
      return;
    }
    
    this.creditShipTo.forEach((data, dataIndex) => {
      if (this.transaction[dataIndex] == "D") {
        this.creditShipToSvc.remove(data.creditShipToBrokerId).subscribe(data => {
          this.monitorTransaction("success");
        }, error => {
          this.monitorTransaction("danger");
        });
      }
      if (this.transaction[dataIndex] == "C") {
        this.creditShipToSvc.create(data).subscribe(dataRet => {
          this.monitorTransaction("success");
        }, error => {
          this.monitorTransaction("danger");
        })
      }

      if (this.transaction[dataIndex] == "U") {
        this.creditShipToSvc.update(data).subscribe(dataRet => {
          this.monitorTransaction("success");
        }, error => {
          this.monitorTransaction("danger");
        })
      }
    })
  }
monitorTransaction(msg) {
  this.alertType = this.alertType == "danger" ? this.alertType : msg;
  this.counter++;
  if (this.counter >= this.totalTransactions) {
    if (this.alertType == "danger") {
      this.success.next(`Unable to update credit informtion`);
    } else {
      if (this.newOne) {
        this.router.navigate(['/accounts/credit-edit',  this.newId]);
      } else {
          this.getListing(this.credit.vendorId, this.credit.billToId);
          this.success.next(`Successfully created.`);
      }
    }
  }
}

blanker() {

  // let refreshScreen = false
  // this.toBeDeleted.forEach(index => {
  //   this.creditShipTo[index].creditShipToBrokerId = 0;
  //   this.creditShipTo[index].brokerId = 0;
  //   this.creditShipTo[index].brokerId2 = 0;
  //   this.creditShipTo[index].commission1 = 0;
  //   this.creditShipTo[index].commission2 = 0;
  //   refreshScreen = true;
  // })
  // console.log(this.creditShipTo);

  // if (refreshScreen) {
  //   this.checkRef.detectChanges();
  // }

}

  onDelete() {
    if (this.credit.id) {
      if (confirm('Delete this item?'))
      this.creditService.remove(this.credit.id).subscribe(data => {
        this.router.navigate(['/accounts/credit-list']);
      });
    }
  }
}
