import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProductService } from '../../../../services/product.service';
import { UpcGtnService } from '../../../../services/upc-gtn.service';
import { UpcGtn } from '../../../../models/upc-gtn.model';
import { FormsModule } from '@angular/forms';
import { Product, ActiveListings, ProductInit } from 'src/app/models/product.model';
import { BrandService } from '../../../../services/brand.service';
import { Brand } from '../../../../models/brand.model';
import { CategoryService } from '../../../../services/category.service';
import { Category } from '../../../../models/category.model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UtilityService } from '../../../../services/utility.service';
import { PalletTypeService } from '../../../../services/pallet-type.service';
import { PalletType } from '../../../../models/pallet-type.model';

@Component({
  selector: 'app-pallet-setup-edit',
  templateUrl: './pallet-setup-edit.component.html',
  styleUrls: ['./pallet-setup-edit.component.scss']
})
export class PalletSetupEditComponent implements OnInit {
  title = 'Pallet Setup New';
  newOne = true;
  brands: Brand[];
  categories: Category[];
  product: Product;
  gtn: UpcGtn[];
  productForm: UntypedFormGroup;
  successMessage: string;
  private success = new Subject<string>();
  staticAlertClosed = false;
  activeList = [];
  palletType: PalletType[];

  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private gtnService: UpcGtnService,
    private brandService: BrandService,
    private categoryService: CategoryService,
    private productService: ProductService,
    private utiityService: UtilityService,
    private palletTypeSvc: PalletTypeService) { }

  ngOnInit() {
    this.activeList = ActiveListings;
    this.productForm = this.fb.group({
      activeListings: [{ value: '', disabled: true }],
      productOrderCode: [{ value: '', disabled: true }],
      brand: [{ value: '', disabled: true }],
      category: [{ value: '', disabled: true }],
      itemDescription: [{ value: '', disabled: true }],
      size: [{ value: '', disabled: true }],
      ti: [''],
      hi: [''],
      csPerPallet: [{ value: '', disabled: true }],
      palletPerTruck: [''],
      csPerTruck: [{ value: '', disabled: true }],
      palletTypeId: ['']
    });

    this.brandService.getAll().subscribe(brand => {
      this.brands = brand;
      this.categoryService.getAll().subscribe(category => {
        this.categories = category;
        this.gtnService.getAll().subscribe(gtns => {
          this.gtn = gtns;
          this.palletTypeSvc.getAll().subscribe(pallet => {
            this.palletType = pallet;
            this.route.data.subscribe(data => {
              if (data.dataProductSetup !== undefined) {
                this.title = 'Pallet Setup Edit';
                this.newOne = false;
                this.product = data.dataProductSetup;
                this.product.activeListings = data.dataProductSetup.activeListings.replace("–","-");
                this.fillFormData();
                this.reCalculate('ALL');
                this.onChanges();
              } else {
                this.title = 'Pallet Setup New';
                this.newOne = true;
                this.product = ProductInit;
                this.productForm.reset();
                this.onChanges();
              }
            });
          })
        });
      });
    });
    setTimeout(() => this.staticAlertClosed = true, 20000);
    this.success.subscribe((message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  onChanges(): void {
    const item = ['ti', 'hi', 'palletPerTruck'];
    item.forEach(name => {
      this.productForm.get(name).valueChanges.subscribe(val => {
        this.reCalculate(name);
      });
    });
  }
  fillFormData() {
    const productOrderCodeValue = this.getValue(this.product.productOrderCodeId, 'productOrderCodeId');
    const brandValue = this.getValue(this.product.brandId, 'brandId');
    const categoryValue = this.getValue(this.product.categoryId, 'categoryId');
    this.productForm.setValue({
      activeListings: this.product.activeListings,
      productOrderCode: productOrderCodeValue,
      brand: brandValue,
      category: categoryValue,
      itemDescription: this.product.itemDescription,
      size: this.product.size,
      ti: this.product.ti,
      hi: this.product.hi,
      csPerPallet: this.product.csPerPallet,
      palletPerTruck: this.product.palletPerTruck,
      csPerTruck: this.product.csPerTruck,
      palletTypeId: this.product.palletTypeId
    });
  }
  getValue(id: number, name: string) {
    let ret = '';
    if (id) {
      let index = -1;
      switch (name) {
        case 'productOrderCodeId':
          index = this.gtn.findIndex(item => item.id === id);
          if (index > -1) {
            ret = this.gtn[index].vendorOrderCode;
          }
          break;
          case 'brandId':
            index = this.brands.findIndex(item => item.id === id);
            if (index > -1) {
              ret = this.brands[index].brand;
            }
            break;
        case 'categoryId':
          index = this.categories.findIndex(item => item.id === id);
          if (index > -1) {
            ret = this.categories[index].category;
          }
          break;
        }
    }
    return ret;
  }
  reCalculate(key) {
    this.updateProduct();
    const changedItems = this.utiityService.productCalculations(key, this.product);
    for (const item in changedItems) {
      if (changedItems.hasOwnProperty(item)) {
        if (this.productForm.controls.hasOwnProperty(item)) {
          this.productForm.controls[item].setValue(changedItems[item]);
        }
        this.product[item] = changedItems[item];
      }
    }
  }

  onSubmit() {
    this.updateProduct();
    if (this.newOne) {
      this.productService.create(this.product).subscribe(data => {
        this.product = data;
        this.newOne = false;
        this.title = 'Pallet Setup Edit';
        this.success.next(`Successfully added.`);
      }, error => {
      });
    } else {
      this.productService.update(this.product).subscribe(data => {
        this.success.next(`Successfully changed.`);
      }, error => {
      });
    }
  }
  
  updateProduct() {
    for (const item in this.productForm.controls) {
      if (this.productForm.controls.hasOwnProperty(item)) {
       if (this.product[item] !== undefined) {
          this.product[item] = this.productForm.controls[item].value;
       }
      }
    }
  }

  editSetup() {
    this.router.navigate(['/products/setup-edit',  this.product.id]);
  }
  
  editCaseSetup() {
    this.router.navigate(['/products/cases-setup-edit',  this.product.id]);
  }

  editFreightCostSetup() {
    this.router.navigate(['/products/freight-cost-setup-edit',  this.product.id]);
  }
  editCostingBucketSetup() {
    this.router.navigate(['/products/costing-bucket-setup-edit',  this.product.id]);
  }
  editListingSetup() {  
    this.router.navigate(['/productListings/listing-setup-edit',  this.product.id]);
  }
}
