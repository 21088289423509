import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../../../services/product.service';
import { Router } from '@angular/router';
import { Product } from 'src/app/models/product.model';

@Component({
  selector: 'app-freight-cost-setup-list',
  templateUrl: './freight-cost-setup-list.component.html',
  styleUrls: ['./freight-cost-setup-list.component.scss']
})
export class FreightCostSetupListComponent implements OnInit {
  products: any;
  page = 1;
  pageSize = 100;
  productsOriginal: any;
  brandFilter = [];
  categoryFilter = [];
  costingFilter = [];
  costTypeFilter = [];
  brand = '';
  category = '';
  costing = '';
  costType = '';

  constructor(private router: Router,
    private productService: ProductService) { }

  ngOnInit() {
    this.productService.getItemOrder().subscribe(prod => {
      this.products = prod;
      this.productsOriginal = prod;
      this.resetFilters();
    });
  }
  resetFilters() {
    let pointsNeeded = 0;
    
    pointsNeeded += this.brand ? 1 : 0;
    pointsNeeded += this.category ? 1 : 0;
    pointsNeeded += this.costing ? 1 : 0;
    pointsNeeded += this.costType ? 1 : 0;

    this.products = [];
    let firstTime = true;
    this.productsOriginal.forEach( prod => {
      let pointsCounted = 0;
      if (this.brand === prod.brand) {
        pointsCounted++;
      }
      if (this.category === prod.category) {
        pointsCounted++;
      }

      if (this.costing === prod.activeListings) {
        pointsCounted++;
      }

      if (this.costType === prod.costType) {
        pointsCounted++;
      }

      if (pointsCounted >= pointsNeeded) {
        this.products.push(prod);
        if (this.brand == '') {
          this.brandFilter = firstTime ? [] : this.brandFilter;
          if (prod.brand && this.brandFilter.indexOf(prod.brand) == -1) {
            this.brandFilter.push(prod.brand);
          }
        }
  
        if (this.category == '') {
          this.categoryFilter = firstTime ? [] : this.categoryFilter;
          if (prod.category && this.categoryFilter.indexOf(prod.category) == -1) {
            this.categoryFilter.push(prod.category);
          }
        }
  
        if (this.costing == '') {
          this.costingFilter = firstTime ? [] : this.costingFilter;
          if (prod.activeListings && this.costingFilter.indexOf(prod.activeListings) == -1) {
            this.costingFilter.push(prod.activeListings);
          }
        }

        if (this.costType == '') {
          this.costTypeFilter = firstTime ? [] : this.costTypeFilter;
          if (prod.costType && this.costTypeFilter.indexOf(prod.costType) == -1) {
            this.costTypeFilter.push(prod.costType);
          }
        }
        firstTime = false;      
      }
    })
  }

  onRowClicked(product) {
    this.router.navigate(['/products/freight-cost-setup-edit',  product.id]);
  }
}
