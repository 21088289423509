import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Product } from '../../models/product.model';
import { ProductService } from '../../services/product.service';

@Injectable()
export class ProductResolver implements Resolve<Product> {
  constructor(private productService: ProductService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Product> {
    if (route.url[0].path === "listing-setup-list") {
      return this.productService.getFilteredListing('', '', 0, 0, 0, 0, 0, 1, 30);
    }
    if (route.url[0].path === "listing-setup-new") {
      return this.productService.getPriceList(0, '', 0, 0, 1, 50);
    }
    const idField = 'id';
    const id =  parseInt(route.params[idField], 10);

    if (id === 0) {
      return;
    }
    return this.productService.getOne(id);
  }
}
