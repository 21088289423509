<div id="my-sales-order" *ngIf="reportName=='Sales Order'">
  <app-sales-order
    [title] = "title"
    [order] = "orderSelected"
    [orderItems] = "orderItems"
    [totalCases] = "totalCases"
    [totalPallets] = "totalPallets"
    [totalGrossLbs] = "totalGrossLbs"
    [totalCaseCost] = "totalCaseCost"
    [totalGrosskg] = "totalGrosskg">
  </app-sales-order>
</div> 
<div id="my-packing-slip" *ngIf="reportName=='Packing Slip'">
  <app-packing-slip
  [title] = "title"
  [order] = "orderSelected"
  [orderItems] = "orderItems">
</app-packing-slip>
</div> 
<div id="my-shipping" *ngIf="reportName=='Shipping'">
  <app-shipping
    [title] = "title"
    [order] = "orderSelected"
    [items] = "orderItems"
    [totalCases] = "totalCases"
    [totalPallets] = "totalPallets"
    [totalGrosskg] = "totalGrosskg"
    [totalGrossLbs] = "totalGrossLbs">
  </app-shipping>
</div> 
<div id="my-commercial-invoice" *ngIf="reportName=='Commercial Invoice'">
  <app-sales-order
    [title] = "title"
    [order] = "orderSelected"
    [orderItems] = "orderItems"
    [totalCases] = "totalCases"
    [totalPallets] = "totalPallets"
    [totalGrossLbs] = "totalGrossLbs"
    [totalCaseCost] = "totalCaseCost"
    [totalGrosskg] = "totalGrosskg">
  </app-sales-order>
</div> 
<div id="my-sales-order-vendor" *ngIf="reportName=='Vendor Sales Order'">
  <app-vendor-sales-order
    [title] = "title"
    [order] = "orderSelected"
    [orderItems] = "orderItems"
    [totalCases] = "totalCases"
    [totalPallets] = "totalPallets"
    [totalGrossLbs] = "totalGrossLbs"
    [totalCaseCost] = "totalCaseCost"
    [totalGrosskg] = "totalGrosskg"    
    [vendorPurchaseCaseCost] = "vendorPurchaseCaseCost"
    [fOBNetVendorCost] = "fOBNetVendorCost">
  </app-vendor-sales-order>
</div> 
<div id="my-invoicing" *ngIf="reportName=='Invoice'">
  <app-invoicing
  [title] = "title"
  [order] = "orderSelected"
  [orderItems] = "orderItems"
  [totalCases] = "totalCases"
  [totalPallets] = "totalPallets"
  [totalGrossLbs] = "totalGrossLbs"
  [totalCaseCost] = "totalCaseCost"
  [totalGrosskg] = "totalGrosskg">
  </app-invoicing>
</div> 
<div *ngIf="reportName==''">
  <h4>Order List</h4>
  <h5>Filter</h5>
  <div class="col-lg-44">
    <label class="ms-3">
      Order Status:
      <select [(ngModel)]="orderStatusSelected" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let o of orderStatusOptions" [value]="o">{{ o }}</option>
      </select>
    </label>

    <label class="ms-3">
      Order Type:
      <select [(ngModel)]="orderTypeSelected" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let o of orderTypeOptions" [value]="o">{{ o }}</option>
      </select>
    </label>

    <label class="ms-3">
      Sales Order No:
      <select [(ngModel)]="orderNumberSelected" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let o of orderNumberOptions" [value]="o.id">{{ o.salesOrderNo }}</option>
      </select>
    </label>

    <label class="ms-3">
      Purchase Order:
      <select [(ngModel)]="purchaseOrderSelected" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let p of purchaseOrderOptions" [value]="p.id">{{ p.purchaseOrder }}</option>
      </select>
    </label>

    <label class="ms-3">
      Bill to:
      <select [(ngModel)]="billToSelected" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let b of billToOptions" [value]="b.billToId">{{ b.company }}</option>
      </select>
    </label>

    <label class="ms-3">
      Ship to:
      <select [(ngModel)]="shipToSelected" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let s of shipToOptions" [value]="s.shipToId">{{ s.company }}</option>
      </select>
    </label>

    <label class="ms-3">
      Vendor:
      <select [(ngModel)]="vendorSelected" (change)="resetFilters()">
        <option [value]=""></option>
        <option *ngFor="let v of vendorOptions" [value]="v.vendorId">{{ v.company }}</option>
      </select>
    </label>
    <button type="button" class="btn btn-primary mt-0 ms-3" (click)="getDefaultDataScreen('')">Reset</button>

  </div>
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Order Status</th>
        <th scope="col">Order Type</th>
        <th scope="col">Sales Order No</th>
        <th scope="col">Purchase Order</th>
        <th scope="col">Sales Order Date</th>
        <th scope="col">Shipment Date</th>
        <th scope="col">Delivered Date/Appt</th>
        <th scope="col">Bill To</th>
        <th scope="col">Ship To</th>
        <th scope="col">Broker</th>
        <th scope="col">Vendor</th>
        <th scope="col">{{ totalSalesAmount | currency }} Sales Amount</th>
        <th scope="col">Sales Order</th>
        <th scope="col">Packing Ship</th>
        <th scope="col">Shipping</th>
        <th scope="col">Commercial Invoice</th>
        <th scope="col">Purchase Order</th>
        <th scope="col">Invoice</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let o of orders; index as i">
        <td>{{ o.orderStatus }}</td>
        <td>{{ o.orderType }}</td>
        <td class="text-primary pointer" (click)="onRowClicked(o)">{{ o.salesOrderNo }}</td>
        <td>{{ o.purchaseOrder }}</td>
        <td>{{ displayDate(o.salesOrderDate, '') }}</td>
        <td>{{ displayDate(o.shipmentDate, '') }}</td>
        <td>{{ displayDate(o.deliveredDateAppt, 'T') }}</td>
        <td>{{ o.billTo }}</td>
        <td>{{ o.shipTo }}</td>
        <td>{{ o.broker }}</td>
        <td>{{ o.vendor }}</td>
        <td>{{ o.salesAmount | currency  }}</td>
        <td>
          <a (click)="printProcess('Sales Order', o)" [ngClass]="{'pointer': salesOrderPrintBtn[i], 'not-active': !salesOrderPrintBtn[i]}">
            <u *ngIf="salesOrderPrintBtn[i]" class="text-primary">Print</u>
            <span *ngIf="!salesOrderPrintBtn[i]" class="text-muted">Print</span>
          </a>
        </td>
        <td>
          <a (click)="printProcess('Packing Slip', o)" class="text-primary" [ngClass]="{'pointer': slipPrintBtn[i], 'not-active': !slipPrintBtn[i]}">
            <u *ngIf="slipPrintBtn[i]" class="text-primary">Print</u>
            <span *ngIf="!slipPrintBtn[i]" class="text-muted">Print</span>
          </a>
        </td>
        <td>
          <a (click)="printProcess('Shipping', o)" class="text-primary" [ngClass]="{'pointer': shipPrintBtn[i], 'not-active': !shipPrintBtn[i]}">
            <u *ngIf="shipPrintBtn[i]" class="text-primary">Print</u>
            <span *ngIf="!shipPrintBtn[i]" class="text-muted">Print</span>
          </a>
        </td>
        <td>
          <a (click)="printProcess('Commercial Invoice', o)" class="text-primary" [ngClass]="{'pointer': invoicePrintBtn[i], 'not-active': !invoicePrintBtn[i]}">
            <u *ngIf="invoicePrintBtn[i]" class="text-primary">Print</u>
            <span *ngIf="!invoicePrintBtn[i]" class="text-muted">Print</span>
          </a>
        </td>
        <td>
          <a (click)="showDialog(o)" class="text-primary"  [ngClass]="{'pointer': poPrintBtn[i], 'not-active': !poPrintBtn[i]}">
            <u *ngIf="poPrintBtn[i]" class="text-primary">Print</u>
            <span *ngIf="!poPrintBtn[i]" class="text-muted">Print</span>
          </a>
        </td>
        <td>
          <a (click)="printProcess('Invoice', o)" [ngClass]="{'pointer': invoicePrintBtn[i], 'not-active': !invoicePrintBtn[i]}">
            <u *ngIf="invoicePrintBtn[i]" class="text-primary">Print</u>
            <span *ngIf="!invoicePrintBtn[i]" class="text-muted">Print</span>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="orders!==undefined">
    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="collectionSize" [maxSize]="5"
      (pageChange)="loadPage($event)"></ngb-pagination>
    </div>

    
    
    
    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
      
      <input class="btn-check" type="radio" name="radio1" id="choice1" [(ngModel)]="pageSize"  (change)="loadPage($event)" autocomplete="off" checked [value]="25">
      <label class="btn btn-outline-primary" for="choice1">
        25
      </label>

      <input class="btn-check" type="radio" name="radio1" id="choice2" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="50" autocomplete="off">
      <label class="btn btn-outline-primary" for="choice2">
        50
      </label>

      <input class="btn-check" type="radio" name="radio1" id="choice3" [(ngModel)]="pageSize"  (change)="loadPage($event)" [value]="100" autocomplete="off">
      <label class="btn btn-outline-primary" for="choice3">
        100
      </label>

    </div>

</div>
<div id="my-confirm-page" *ngIf="reportName=='Confirm'">
  <app-confirm-dialog 
    (returnDialog) = "returnDialog($event)">
  </app-confirm-dialog>
</div> 
<ngb-alert *ngIf="successMessage" [type]="alertType" (close)="successMessage = null">{{ successMessage }}</ngb-alert>