import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/index';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RegisterComponent } from './register/register.component';
import { HomeComponent } from './home/home.component';
import { CustomerListComponent, CustomerResolver, CustomerDetailsComponent, CustomerCreateComponent } from './accounting/customers/customer/index';
import {
  ShipToAddressListComponent,
  ShipToAddressEditComponent,
  ShipToAddressResolver,
  ShipToCreateComponent
} from './accounting/customers/ship-to-address/index';
import {
  CustomerEndListComponent,
  CustomerEndEditComponent,
  CustomerEndResolver
} from './accounting/customers/customer-end/index';
import {
  CustomerRetailListComponent,
  CustomerRetailEditComponent,
  CustomerRetailResolver
} from './accounting/customers/customer-retail/index';

import {
  VendorListComponent,
  VendorDetailComponent,
  VendorResolver
} from './accounting/vendors/vendor/index';
import {
  PickupLocationListComponent,
  PickupLocationDetailComponent,
  PickupLocationResolver
} from './accounting/vendors/pickup-location/index';

import {
  BrandListComponent,
  BrandDetailComponent,
  BrandResolver
} from './components/brand/index';
import {
  CategoryListComponent,
  CategoryEditComponent,
  CategoryResolver
} from './components/category/index';
import {
  UpcGtnListComponent,
  UpcGtnEditComponent,
  UpcGtnResolver
} from './components/upc-gtn/index';
import {
  ProductListComponent,
  ProductEditComponent,
  CaseSetupListComponent,
  CaseSetupEditComponent,
  PalletSetupListComponent,
  PalletSetupEditComponent,
  FreightCostSetupListComponent,
  FreightCostSetupEditComponent,
  CostingBucketSetupListComponent,
  CostingBucketSetupEditComponent,
  ListingSetupEditComponent,
  ListingSetupListComponent,
  ListingSetupNewComponent,
  ProductResolver
} from './components/product/index';
import { AuthGuard } from './guard/auth.guard';
import {
  SubCategoryListComponent,
  SubCategoryEditComponent,
  SubCategoryResolver
} from './components/sub-category/index';
import { DashboardShowComponent } from './components/dashboard/dashboard-show/dashboard-show.component';
import {
  BrokerPayListComponent,
  BrokerPayEditComponent,
  BrokerPayResolver,
  CreditEditComponent,
  CreditListComponent,
  CreditResolver,
  TransferEditComponent,
  TransferListComponent,
  TransferResolver
} from './accounting/credit/index';
import {
  ProfitListComponent,
  ProfitEditComponent,
  ProfitLossResolver
} from './accounting/profit-loss/index';
import {
  ProjectionsNewComponent,
  ProjectionsEditComponent,
  ProjectionsListComponent,
  ReviewWeekComponent,
  ProjectionsResolver
} from './projections/index';
import { from } from 'rxjs';
const routes: Routes = [
  { path: '', component: DashboardShowComponent },
  { path: 'login', component: LoginComponent, outlet: "login" },
  { 
    path: 'dashboard', component: DashboardShowComponent,
    canActivate: [AuthGuard],
    data: { section: 'dashboard' }
  },
  { path: 'register', component: RegisterComponent, canActivate: [AuthGuard] },
  {
    path: 'products',
    canActivate: [AuthGuard],
    data: { section: 'products' },
    children: [
      {
        path: 'setup-list', component: ProductListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'setup-edit/:id', component: ProductEditComponent,
        canActivate: [AuthGuard],
        resolve: { dataProductSetup: ProductResolver }
      },
      {
        path: 'cases-setup-list', component: CaseSetupListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'cases-setup-edit/:id', component: CaseSetupEditComponent,
        canActivate: [AuthGuard],
        resolve: { dataProductSetup: ProductResolver },
      },
      {
        path: 'pallet-setup-list', component: PalletSetupListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'pallet-setup-edit/:id', component: PalletSetupEditComponent,
        canActivate: [AuthGuard],
        resolve: { dataProductSetup: ProductResolver }
      },
      {
        path: 'freight-cost-setup-list', component: FreightCostSetupListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'freight-cost-setup-edit/:id', component: FreightCostSetupEditComponent,
        canActivate: [AuthGuard],
        resolve: { dataProductSetup: ProductResolver }
      },
      {
        path: 'costing-bucket-setup-list', component: CostingBucketSetupListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'costing-bucket-setup-edit/:id', component: CostingBucketSetupEditComponent,
        canActivate: [AuthGuard],
        resolve: { dataProductSetup: ProductResolver }
      }
    ]
  },
  {
    path: 'productListings',
    canActivate: [AuthGuard],
    data: { section: 'productListings' },
    children: [
      {
        path: 'listing-setup-list', component: ListingSetupListComponent,
        canActivate: [AuthGuard],
        resolve: { dataProductSetup: ProductResolver }
      },
      {
        path: 'listing-setup-new', component: ListingSetupNewComponent,
        canActivate: [AuthGuard],
        resolve: { dataProductSetup: ProductResolver }
      },
      {
        path: 'listing-setup-edit/:id', component: ListingSetupEditComponent,
        canActivate: [AuthGuard],
        resolve: { dataProductSetup: ProductResolver }
      }
    ]
  },  
  {
    path: 'items',
    canActivate: [AuthGuard],
    data: { section: 'items' },
    children: [
      {
        path: 'brand-list', component: BrandListComponent,
        canActivate: [AuthGuard],
        resolve: { dataBrand: BrandResolver }
      },
      {
        path: 'brand-details/:id', component: BrandDetailComponent,
        canActivate: [AuthGuard],
        resolve: { dataBrand: BrandResolver }
      },
      {
        path: 'category-list', component: CategoryListComponent,
        canActivate: [AuthGuard],
        resolve: { dataCategory: CategoryResolver }
      },
      {
        path: 'category-edit/:id', component: CategoryEditComponent,
        canActivate: [AuthGuard],
        resolve: { dataCategory: CategoryResolver }
      },
      {
        path: 'sub-category-list', component: SubCategoryListComponent,
        canActivate: [AuthGuard],
        resolve: { dataCategory: SubCategoryResolver }
      },
      {
        path: 'sub-category-edit/:id', component: SubCategoryEditComponent,
        canActivate: [AuthGuard],
        resolve: { dataCategory: SubCategoryResolver }
      },
      {
        path: 'upc-gtn-list', component: UpcGtnListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'upc-gtn-edit/:id', component: UpcGtnEditComponent,
        canActivate: [AuthGuard],
        resolve: { dataUpcGtn: UpcGtnResolver }
      },
    ]
  },
  {
    path: 'accounts',
    data: { section: 'accounts' },
    children: [
      {
        path: 'credit-list', component: CreditListComponent,
        canActivate: [AuthGuard],
        resolve: { dataCredit: CreditResolver }
      },
      {
        path: 'credit-edit', component: CreditEditComponent,
        canActivate: [AuthGuard],
        resolve: { dataCredit: CreditResolver }
      },
      {
        path: 'credit-edit/:id', component: CreditEditComponent,
        canActivate: [AuthGuard],
        resolve: { dataCredit: CreditResolver }
      },
      {
        path: 'transfer-list', component: TransferListComponent,
        canActivate: [AuthGuard],
        resolve: { dataTransfer: TransferResolver }
      },
      {
        path: 'transfer-edit/:id', component: TransferEditComponent,
        canActivate: [AuthGuard],
        resolve: { dataTransfer: TransferResolver }
      },
      {
        path: 'profit-lost-list', component: ProfitListComponent,
        canActivate: [AuthGuard],
        resolve: { dataProfitLoss: ProfitLossResolver }
      },
      {
        path: 'profit-lost-edit/:id', component: ProfitEditComponent,
        canActivate: [AuthGuard],
        resolve: { dataProfitLoss: ProfitLossResolver }
      },
      {
        path: 'broker-pay-list', component: BrokerPayListComponent,
        canActivate: [AuthGuard],
        resolve: { dataBrokerPay: BrokerPayResolver }
      },
      {
        path: 'broker-pay-edit/:id', component: BrokerPayEditComponent,
        canActivate: [AuthGuard],
        resolve: { dataBrokerPay: BrokerPayResolver }
      }

    ]
  },
  {
    path: 'customers',
    data: { section: 'customers' },
    children: [
      {
        path: '', component: CustomerListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'customer-details/:id', component: CustomerDetailsComponent,
        canActivate: [AuthGuard],
        resolve: { dataCustomer: CustomerResolver }
      },
      {
        path: 'customer-create', component: CustomerCreateComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'ship-to-address-list', component: ShipToAddressListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'ship-to-address-edit/:id', component: ShipToAddressEditComponent,
        canActivate: [AuthGuard],
        resolve: { dataShipTo: ShipToAddressResolver }
      },
      {
        path: 'ship-to-address-create', component: ShipToCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'customer-end-list', component: CustomerEndListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'customer-end-edit/:id', component: CustomerEndEditComponent,
        canActivate: [AuthGuard],
        resolve: { dataCustomerEnd: CustomerEndResolver  }
      },
      {
        path: 'retail-list', component: CustomerRetailListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'retail-edit/:id', component: CustomerRetailEditComponent,
        canActivate: [AuthGuard],
        resolve: { dataRetail: CustomerRetailResolver  }
      }

    ]
  },
  {
    path: 'vendors',
    data: { section: 'vendors' },
    children: [
      {
        path: 'vendor-list', component: VendorListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'vendor-edit/:id', component: VendorDetailComponent,
        canActivate: [AuthGuard],
        resolve: { dataVendor: VendorResolver }
      },
      {
        path: 'pickup-location-list', component: PickupLocationListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'pickup-location-edit/:id', component: PickupLocationDetailComponent,
        canActivate: [AuthGuard],
        resolve: { dataPickupLocation: PickupLocationResolver }
      }
    ]
  },
  {
    path: 'customers',
    data: { section: 'customers' },
    children: [
      {
        path: '', component: CustomerListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'customer-details/:id', component: CustomerDetailsComponent,
        canActivate: [AuthGuard],
        resolve: { dataCustomer: CustomerResolver }
      },
      {
        path: 'customer-create', component: CustomerCreateComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'ship-to-address-list', component: ShipToAddressListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'ship-to-address-edit/:id', component: ShipToAddressEditComponent,
        canActivate: [AuthGuard],
        resolve: { dataShipTo: ShipToAddressResolver }
      },
      {
        path: 'ship-to-address-create', component: ShipToCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'customer-end-list', component: CustomerEndListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'customer-end-edit/:id', component: CustomerEndEditComponent,
        canActivate: [AuthGuard],
        resolve: { dataCustomerEnd: CustomerEndResolver  }
      },
      {
        path: 'retail-list', component: CustomerRetailListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'retail-edit/:id', component: CustomerRetailEditComponent,
        canActivate: [AuthGuard],
        resolve: { dataRetail: CustomerRetailResolver  }
      }

    ]
  },
  {
    path: 'projections',
    data: { section: 'projections' },
    children: [
      {
        path: 'projections-list', component: ProjectionsListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'projections-new', component: ProjectionsNewComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'projections-edit/:id', component: ProjectionsEditComponent,
        canActivate: [AuthGuard]
        // resolve: { dataProjection: ProjectionsResolver }
      },
      {
        path: 'review-week', component: ReviewWeekComponent,
        canActivate: [AuthGuard]
      }

    ]
  },

  { path: '**', component: PageNotFoundComponent }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    enableTracing: false,
    initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled' // Don't perform initial navigation in iframes
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
